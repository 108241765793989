import immatTheme from "apps/front-office/client/themes/immatTheme";
import passeportTheme from "apps/front-office/client/themes/passeportTheme";
import permisTheme from "apps/front-office/client/themes/permisTheme";
import { styleKeys } from "components/templatesComponents/card/cardStyles";

export const cardImmatContextValue = (theme) => ({
  desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
  mobileStyleKey: styleKeys.HORIZONTAL_BIG,
  styleOverride: {
    root: {
      backgroundColor: theme.palette.primary.tones[2],
      minHeight: 72,
      "a&:hover": {
        backgroundColor: theme.palette.primary.tones[2],
      },
    },
    arrow: {
      backgroundColor: immatTheme.palette.secondary.main,
      fontSize: "2rem",

      color: theme.palette.componentColors[70],
    },
  },
});
export const cardPermisContextValue = (theme) => ({
  desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
  mobileStyleKey: styleKeys.HORIZONTAL_BIG,
  styleOverride: {
    root: {
      backgroundColor: theme.palette.primary.tones[0],
      minHeight: 72,
      "a&:hover": {
        backgroundColor: theme.palette.primary.tones[0],
      },
    },
    arrow: {
      backgroundColor: permisTheme.palette.secondary.main,
      fontSize: "2rem",

      color: theme.palette.componentColors[70],
    },
  },
  desktopStyleOverride: {
    icon: {
      height: "2rem",
    },
  },
});
export const cardPasseportContextValue = (theme) => ({
  desktopStyleKey: styleKeys.VERTICAL_BIG_PROCESS_SITES,
  mobileStyleKey: styleKeys.HORIZONTAL_BIG,
  styleOverride: {
    root: {
      backgroundColor: theme.palette.primary.tones[1],
      minHeight: 72,
      "a&:hover": {
        backgroundColor: theme.palette.primary.tones[1],
      },
    },
    arrow: {
      backgroundColor: passeportTheme.palette.secondary.main,
      fontSize: "2rem",

      color: theme.palette.componentColors[70],
    },
  },
  desktopStyleOverride: {
    icon: {
      fontSize: "2rem",
    },
  },
});
