import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import LanguageContext from "components/LanguageContext";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import CountUp from "react-countup";
import t from "utils/locales/translation.json";

const CountCard = (props) => {
  const { information, ...others } = props;
  const { number, name } = information;
  const { language } = useContext(LanguageContext);

  return (
    <Grid item xs={12} sm={6} {...others}>
      <Stack
        sx={{
          pt: 3,
          px: 3,
          height: "100%",
          bgcolor: "var(--background-contrast-grey)",
          border: "1px solid var(--border-default-grey)",
          "& .fr-text--sm": { color: "var(--text-mention-grey)", mt: -1.75 },
        }}
      >
        <Grid container alignItems="center" sx={{ mb: 1.25, "& .fr-h4": { m: 0 } }}>
          <Icon iconDSFR="line-chart-fill" sx={{ mr: 1.25, color: "var(--blue-cumulus-main-526)" }} />
          <CountUp className="fr-h4" duration={5} end={number} separator=" " preserveValue />
        </Grid>
        <p className="fr-text--md" role="presentation">
          {t[language].counter[name]}
        </p>
        {name === "connexion_fc_xp" && (
          <p className="fr-text--sm" role="presentation">
            {t[language].counter.connexion_fc_xp_legend}
          </p>
        )}
      </Stack>
    </Grid>
  );
};

CountCard.propTypes = {
  information: PropTypes.shape({
    number: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CountCard;
