import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import CardContext from "components/templatesComponents/CardContext";
import PageTitle from "components/templatesComponents/PageTitle";
import RectangleBackground from "components/templatesComponents/RectangleBackground";
import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";

const PageLinks = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { pageLinks } = contents;

  const cardContextValue = useMemo(
    () => ({
      Wrapper: Grid,
      wrapperProps: {
        item: true,
        xs: 12,
        md: 4,
        container: true,
        sx: (theme) => ({ minHeight: { xs: "242px", lg: "312px" }, ...theme?.templates?.PageLink?.cardWrapper }),
      },
    }),
    []
  );

  return (
    <>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
          {shortDescription}
        </Block>
      </Wrapper>
      <Box sx={{ zIndex: 1, position: "relative" }}>
        <RectangleBackground top="106px" bottom="106px" position="left" width={0.875}>
          <Wrapper>
            <Block>
              <Grid container spacing={2}>
                {/* Si besoin d'afficher toutes les cards possibles */}
                {/* {Object.keys(styleKeys).map(key => <div style={{"margin": "8px"}}><Card {...cards[0]} title={key} desktopStyleKey={key} mobileStyleKey={key} icon /></div>)} */}
                <CardContext.Provider value={cardContextValue}>{pageLinks}</CardContext.Provider>
              </Grid>
            </Block>
          </Wrapper>
        </RectangleBackground>
      </Box>
    </>
  );
};

PageLinks.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default PageLinks;
