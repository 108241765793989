import Box from "@mui/material/Box";
import { pageVersionImageKey } from "components/adminServices/AdminPageVersionsService";
import Accordion from "components/templatesComponents/Accordion";
import Attachment from "components/templatesComponents/Attachment";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import Card from "components/templatesComponents/Card";
import CardHighlighted from "components/templatesComponents/CardHighlighted";
import CardList from "components/templatesComponents/CardList";
import DSFRCard from "components/templatesComponents/DSFRCard";
import DSFRList from "components/templatesComponents/DSFRList";
import DSFRSpecificList from "components/templatesComponents/DSFRSpecificList";
import DSFRSubpage from "components/templatesComponents/DSFRSubpage";
import DSFRTile from "components/templatesComponents/DSFRTile";
import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import ExpansionText from "components/templatesComponents/ExpansionText";
import Glossary from "components/templatesComponents/Glossary";
import Grid from "components/templatesComponents/Grid";
import Image from "components/templatesComponents/Image";
import ImageBlock from "components/templatesComponents/ImageBlock";
import InfoBlock from "components/templatesComponents/InfoBlock";
import Link from "components/templatesComponents/Link";
import Mailto from "components/templatesComponents/Mailto";
import Numeric from "components/templatesComponents/Numeric";
import Quote from "components/templatesComponents/Quote";
import Result from "components/templatesComponents/Result";
import ResultList from "components/templatesComponents/ResultList";
import Stepper, { Step } from "components/templatesComponents/Stepper";
import SubPage from "components/templatesComponents/SubPage";
import Text from "components/templatesComponents/Text";
import TileCard from "components/templatesComponents/TileCard";
import Timeline, { TimelineStep } from "components/templatesComponents/Timeline";
import Video from "components/templatesComponents/Video";
import React from "react";
import elementsTypes from "utils/elementsTypes";

const sortFn = (item1, item2) => {
  return parseInt(item1.order, 10) - parseInt(item2.order, 10);
};
export const sortContentsProps = (contents) => {
  const newContents = contents.sort(sortFn);
  if (contents.children) {
    newContents.children = sortContentsProps(contents.children);
  }
  return newContents;
};

export const getDynamicComponent = (type, value, id, index) => {
  if (type && value !== undefined) {
    switch (type) {
      case elementsTypes.TEXT:
        return <Text key={id}>{value}</Text>;
      case elementsTypes.HIGHLIGHTED_TEXT:
        return (
          <Text key={id} highlight>
            {value}
          </Text>
        );
      case elementsTypes.CHECKBOX:
      case elementsTypes.ALONE_CHECKBOX:
        return value === "true";
      case elementsTypes.NUMERIC:
        return <Numeric number={value} />;
      case elementsTypes.BUTTON:
        if (typeof value === "object" && !Array.isArray(value)) {
          const { text, link, ...others } = value;
          const { url, page } = link || {};
          return (
            <Button {...others} key={id} component={Link} {...link} button linkComponent disabled={!url && !page}>
              {text}
            </Button>
          );
        }
        break;
      case elementsTypes.ICON_LINK:
        if (typeof value === "object" && !Array.isArray(value)) {
          const { text, link, ...others } = value;
          const { url, page } = link || {};
          return (
            <Link {...others} key={id} {...link} linkComponent disabled={!url && !page}>
              {text}
            </Link>
          );
        }
        break;
      case elementsTypes.FILE:
      case elementsTypes.FILE_IMAGE:
        // eslint-disable-next-line no-case-declarations
        let file;
        try {
          file = JSON.parse(value);
        } catch (e) {
          file = typeof value === "object" ? value : {};
        }
        return {
          ...file,
          url: file.url && file.url.replace(`${process.env.ged_public_url}`, "/files"),
        };
      case elementsTypes.CARD:
        return <Card {...value} key={id} index={index} />;
      case elementsTypes.DSFR_CARD:
        return <DSFRCard {...value} key={id} index={index} />;
      case elementsTypes.CARD_NEWS:
        return <CardHighlighted {...value} key={id} index={index} />;
      case elementsTypes.OLD_CARD_NEWS:
        return <TileCard {...value} key={id} index={index} />;
      case elementsTypes.TILE_CARD:
        return <DSFRTile {...value} key={id} index={index} />;
      case elementsTypes.PAGE:
        if (typeof value === "object") {
          return {
            ...value,
            // eslint-disable-next-line no-use-before-define
            image: formatPageImage(value),
          };
        }
        break;
      case elementsTypes.MENU_ITEM:
      case elementsTypes.MENU_GROUP:
      case elementsTypes.ACCORDION_PANEL:
      case elementsTypes.LINK:
      case elementsTypes.ICON:
      case elementsTypes.EDITABLE_INPUT:
      case elementsTypes.INPUT:
      case elementsTypes.URL:
      case elementsTypes.SELECT:
      case elementsTypes.SHORT_TEXT:
      case elementsTypes.GLOSSARY_ITEM:
      case elementsTypes.TABS:
      case elementsTypes.TAB:
      case elementsTypes.TABS_CARDS:
      case elementsTypes.TABS_TILE_CARDS:
      case elementsTypes.TAB_CARDS:
      case elementsTypes.TAB_TILE_CARDS:
      case elementsTypes.CONTENTS_GROUP:
      case elementsTypes.CONTENTS_GROUPS_LIST:
      case elementsTypes.WARNING_BANNER:
      case elementsTypes.SOCIAL_NETWORK:
      case elementsTypes.SITE_SETTINGS:
      case elementsTypes.SITE_ONBOARDING:
      case elementsTypes.GEOLOC_SOURCETYPE:
      case elementsTypes.CONTACT_FORM_PROFILE_LIST:
      case elementsTypes.CONTACT_FORM_PROFILE:
      case elementsTypes.CONTACT_FORM_SUBJECT_LIST:
      case elementsTypes.CONTACT_FORM_SUBJECT:
      case elementsTypes.SITE_NAME:
      case elementsTypes.SLIDER:
      case elementsTypes.SLIDER_INLINE:
        return value;
      case elementsTypes.DYNAMIC:
        return (
          value &&
          React.Children.map(value.dynamicElements, (child) => child && <Block key={child.props.id}>{child}</Block>)
        );
      case elementsTypes.DSFR_TILE_CARDS_LIST:
        return (
          value &&
          React.Children.map(value.dsfrElements, (child) => child && <Block key={child.props.id}>{child}</Block>)
        );
      case elementsTypes.MULTI_SUBPAGES_LIST:
        return (
          value && React.Children.map(value.pages, (child) => child && <Block key={child.props.id}>{child}</Block>)
        );
      case elementsTypes.DYNAMIC_LIST:
        return (
          value &&
          React.Children.map(value.dynamicElements, (child) => child && <Box key={child.props.id}>{child}</Box>)
        );
      case elementsTypes.IMAGE: {
        return value?.file?.url && <Image {...value} />;
      }
      case elementsTypes.IMAGE_BLOCK: {
        return <ImageBlock {...value} />;
      }
      case elementsTypes.TIMELINE:
        return <Timeline {...value} />;
      case elementsTypes.STEP_V2:
        return <TimelineStep key={id} {...value} />;
      case elementsTypes.STEP_V2_CONTENT:
        return (
          value &&
          React.Children.map(
            value.detailsElements,
            (child) =>
              child && (
                <Box mb={1} key={child.props.id}>
                  {child}
                </Box>
              )
          )
        );
      case elementsTypes.EXPANSION_PANEL:
        return <ExpansionPanel key={id} {...value} />;
      case elementsTypes.ATTACHMENT:
        return <Attachment key={id} {...value} />;
      case elementsTypes.ATTACHMENT_LINK:
        return <Attachment key={id} link {...value} />;
      case elementsTypes.ACCORDION:
        return <Accordion {...value} />;
      case elementsTypes.RESULT_LIST:
        return <ResultList {...value} />;
      case elementsTypes.RESULT:
        return <Result key={id} {...value} />;
      case elementsTypes.CARDS_LIST:
      case elementsTypes.CARDS_LIST_NEWS:
      case elementsTypes.OLD_CARDS_LIST_NEWS:
        return <CardList {...value} />;
      case elementsTypes.DSFR_CARDS_SPECIFIC_LIST:
      case elementsTypes.TILE_CARDS_SPECIFIC_LIST:
        return <DSFRSpecificList {...value} />;
      case elementsTypes.DSFR_CARDS_LIST:
      case elementsTypes.TILE_CARDS_LIST:
        return <DSFRList {...value} />;
      case elementsTypes.LINKS_LIST:
        return (
          value && (
            <div className="fr-links-group">
              <ul>
                {React.Children.map(
                  value.dynamicAttachments,
                  (child) => child && <li key={child.props.id}>{child}</li>
                )}
              </ul>
            </div>
          )
        );
      case elementsTypes.EXPANSION_PANEL_DETAILS:
        return (
          value &&
          React.Children.map(
            value.detailsElements,
            (child) =>
              child && (
                <Box py={1} key={child.props.id} id={child.props.id}>
                  {child}
                </Box>
              )
          )
        );
      case elementsTypes.QUOTE:
        return <Quote {...value} />;
      case elementsTypes.STEPPER_DETAIL:
        return value && value.detailsElements;
      case elementsTypes.STEPPER:
        return <Stepper {...value} />;
      case elementsTypes.STEPPER_STEP:
        return <Step {...value} index={index + 1} />;
      case elementsTypes.SUBPAGE:
        // eslint-disable-next-line no-use-before-define
        return <SubPage key={id} page={formatPageData(value)} />;
      case elementsTypes.DSFR_SUBPAGE:
        return <DSFRSubpage key={id} {...value} />;
      case elementsTypes.SUBPAGES_LIST:
      case elementsTypes.DSFR_SUBPAGES_LIST: {
        return value && value.pages;
      }
      case elementsTypes.VIDEO:
        return <Video {...value} />;
      case elementsTypes.VIDEOS_LIST: {
        return value && value.videos;
      }
      case elementsTypes.GRID:
        return <Grid key={id} {...value} />;
      case elementsTypes.GLOSSARY:
        return <Glossary {...value} />;
      case elementsTypes.INFO_BLOCK:
        return <InfoBlock {...value} />;
      case elementsTypes.INFO_BLOCK_LIST:
        return value && value.infos;
      case elementsTypes.MAILTO: {
        return <Mailto {...value} contentId={id} key={id} />;
      }
      case elementsTypes.EXPANSION_TEXT:
        return <ExpansionText key={id} {...value} />;
      case elementsTypes.ATTACHMENTS:
        return (
          value && (
            <div className="fr-downloads-group">
              <ul>
                {React.Children.map(
                  value.dynamicAttachments,
                  (child) => child && <li key={child.props.id}>{child}</li>
                )}
              </ul>
            </div>
          )
        );
      default:
        return null;
    }
  }
  return value;
};

const generateTemplatePropsFromContents = (contents) => {
  const props = {};
  if (Array.isArray(contents)) {
    contents.forEach((content) => {
      let { value } = content;
      const { key, children, type, id } = content;

      if (children && children.length) {
        value = generateTemplatePropsFromContents(children);
        value.id = id;
      }
      value = getDynamicComponent(type, value, id, contents.filter((c) => c.key === key).indexOf(content));

      // if key already exists, then it will be an array
      if (typeof props[key] !== "undefined") {
        if (!Array.isArray(props[key])) {
          props[key] = [props[key]];
        }
        props[key].push(value);
      } else {
        props[key] = value;
      }
    });
  }
  return props;
};

export const formatPageImage = (page) => {
  const { image } = page;
  let fileImage = (Array.isArray(page.contents) && page.contents.find((c) => c.key === pageVersionImageKey)) || image;
  if (fileImage) {
    try {
      fileImage = JSON.parse(fileImage.value);
    } catch (e) {
      fileImage = image;
    }
  }
  return (
    image &&
    getDynamicComponent(elementsTypes.IMAGE, {
      file: fileImage,
      alt: image.alt,
      title: image.title,
    })
  );
};

export const formatPageData = (page = {}) => {
  return {
    ...page,
    image: formatPageImage(page),
    shortDescription: page.shortDescription && getDynamicComponent(elementsTypes.TEXT, page.shortDescription),
    contents: generateTemplatePropsFromContents(page.contents),
  };
};

export default generateTemplatePropsFromContents;
