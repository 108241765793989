import { createTheme } from "@mui/material/styles";
import antsTheme from "./antsTheme";

export const theme = {
  header: {
    background: {
      color: antsTheme.palette.primary[10],
    },
    current: {
      background: {
        color: antsTheme.palette.primary[40],
      },
    },
    border: {
      color: antsTheme.palette.primary[20],
    },
  },
  navigation: {
    background: {
      color: antsTheme.palette.primary[40],
    },
    border: {
      color: antsTheme.palette.componentColors[10],
    },
    current: {
      color: antsTheme.palette.primary[20],
      hover: antsTheme.palette.primary[20],
    },
  },

  components: {
    Button: {
      login: {
        backgroundColor: antsTheme.palette.primary[20],
        hover: antsTheme.palette.primary[20],
      },
    },
    searchWrapper: {
      background: antsTheme.palette.primary[10],
    },
  },
  logoContainer: {
    margin: {
      marginLeft: "inherit",
    },
  },
  footer: {
    background: {
      backgroundColor: antsTheme.palette.primary[50],
    },
  },
};

export default createTheme(theme);
