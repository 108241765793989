import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Wrapper from "components/LayoutFront/Wrapper";
import HomeLastNews from "components/templates/home/HomeLastNews";
import HomeMissions from "components/templates/home/HomeMissions";
import HomeResults from "components/templates/home/HomeResults";
import HomeWho from "components/templates/home/HomeWho";
import missions from "components/templates/missions/missions";
import lastNews from "components/templates/news/lastNews";
import standard from "components/templates/standard/standard";
import who from "components/templates/who/who";
import Block from "components/templatesComponents/Block";
import CardContext from "components/templatesComponents/CardContext";
import {
  cardImmatContextValue,
  cardPasseportContextValue,
  cardPermisContextValue,
} from "components/templatesComponents/CardContextValue";
import { ImageContainer } from "components/templatesComponents/Image";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const displayDesktopStyled = { display: { xs: "none", lg: "block" }, width: { lg: "100%" } };

const siteLinksContainerStyled = {
  flexDirection: { xs: "column", lg: "row" },
  width: { xs: "100%", lg: "calc(100% + 16px)" },
  m: { xs: 0, lg: -1 },
  mb: { xs: 0, lg: 1 },
  "& > div": {
    px: { lg: 1 },
    py: { xs: 0.5, lg: 1 },
  },
};

const getSubTemplate = (k) => {
  switch (k) {
    case who.key:
      return HomeWho;
    case missions.key:
      return HomeMissions;
    case standard.key:
      return HomeResults;
    case lastNews.key:
      return HomeLastNews;
    default:
      return null;
  }
};

const Home = (props) => {
  const { page = {} } = props;

  const { image, contents } = page;

  const { contentTitle, subtitle, cardImmat, cardPermis, cardPasseport, subpages } = contents;

  const theme = useTheme();

  const subPageContextValue = useMemo(() => ({ getSubTemplate }), []);

  return (
    <>
      <Wrapper>
        <Grid container justifyContent="space-between" sx={{ flexDirection: { xs: "column", lg: "row-reverse" } }}>
          {image && (
            <Grid item lg={5} container alignItems="center">
              <Grid item sx={displayDesktopStyled}>
                <ImageContainer ratio={89}>{image}</ImageContainer>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            lg={image ? 6 : 12}
            container
            sx={{ flexDirection: { lg: "column" }, justifyContent: { lg: "center" } }}
          >
            <Grid item sx={displayDesktopStyled}>
              <Block>
                <h1>{contentTitle}</h1>
              </Block>
            </Grid>
            <Grid item xs={12} sx={{ display: { lg: "none" } }}>
              <Block>
                <Box component="h1" sx={{ my: { xs: 3, lg: 0 } }}>
                  {contentTitle}
                </Box>
              </Block>
            </Grid>
            <Grid item sx={{ mx: { xs: 2, lg: 0 } }}>
              <h2>{subtitle}</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={siteLinksContainerStyled}>
          <Grid item md>
            <CardContext.Provider value={cardImmatContextValue(theme)}>{cardImmat}</CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider value={cardPermisContextValue(theme)}>{cardPermis}</CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider value={cardPasseportContextValue(theme)}>{cardPasseport}</CardContext.Provider>
          </Grid>
        </Grid>
      </Wrapper>

      <SubPageContext.Provider value={subPageContextValue}>{subpages}</SubPageContext.Provider>
    </>
  );
};

Home.propTypes = {
  page: PropTypes.shape(),
};

export default Home;
