import { createTheme } from "@mui/material/styles";
import merge from "lodash.merge";
import antsTheme from "./antsTheme";

const colors = {
  10: "#FCF3FA",
  20: "var(--background-contrast-purple-glycine)",
  30: "#FFD5EC",
  40: "#FEAAD6",
  50: "#F582C1",
  60: "#E061A9",
};

export const theme = merge({}, antsTheme, {
  palette: {
    secondary: {
      light: colors[20],
      main: colors[50],
      dark: colors[60],
      hover: colors[60],
      ...colors,
    },
  },
  templates: {
    Article: {
      icon: {
        color: colors[50],
      },
    },
  },
  navigation: {
    current: {
      color: colors[50],
      hover: colors[20],
    },
    iconMenuMobile: {
      color: colors[40],
    },
    topNav: {
      background: antsTheme.palette.componentColors[20],
      backgroundLogo: "transparent",
    },
    titleMobile: {
      fontSize: "1.25rem",
    },
  },
  footer: {
    background: {
      backgroundColor: colors[40],
      backgroundColorTitleSocialMobile: colors[50],
    },
  },
  components: {
    Button: {
      login: {
        backgroundColor: colors[50],
        hover: colors[60],
      },
    },
    ExpansionPanel: {
      ExpandIcon: {
        backgroundColor: colors[50],
        expanded: colors[50],
      },
    },
  },
});

export default createTheme(theme);
