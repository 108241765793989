import Box from "@mui/material/Box";
import LanguageContext from "components/LanguageContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import t from "utils/locales/translation.json";

const scrollerStyled = {
  color: "primary.40",
  position: "fixed",
  bottom: "400px",
  mx: "auto",
  pointerEvents: "none",
  maxWidth: "1400px",
  width: "100%",
  left: 0,
  fontSize: "28px",
  right: 0,
  pr: 10,
  display: { xs: "none", lg: "flex" },
  justifyContent: "flex-end",
  zIndex: "250",
  boxSizing: "border-box",
  "& button": {
    fontSize: "24px",
    pointerEvents: "auto",
    display: "none",
  },
  "@media print": {
    display: "none",
  },
};

const BackToTop = (props) => {
  const { scrollableClass } = props;

  const { language } = useContext(LanguageContext);

  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }
  const listener = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page && page.scrollTop + page.clientHeight > 2048) {
        document.getElementById("backToTop").style.display = "block";
      } else {
        document.getElementById("backToTop").style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page) {
        page.addEventListener("scroll", listener);
        return () => {
          page.removeEventListener("scroll", listener);
        };
      }
    }
    return null;
  });

  const handleScrollUp = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      page.scroll({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <Box sx={scrollerStyled}>
      <Button
        id="backToTop"
        sx={{
          animation: "fadeIn ease 700ms",
          "@keyframes fadeIn": {
            "0%": {
              opacity: 0,
            },
            "100%": {
              opacity: 1,
            },
          },
        }}
        onClick={handleScrollUp}
        size="lg"
        aria-label={t[language].components.back_to_top_icon_title}
      >
        <Icon icon="long-arrow-up" iconDSFR="arrow-up-line" title={t[language].components.back_to_top_icon_title} />
      </Button>
    </Box>
  );
};

BackToTop.propTypes = {
  scrollableClass: PropTypes.string.isRequired,
};

export default BackToTop;
