import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Stack from "@mui/material/Stack";
import classnames from "classnames";
import LanguageContext from "components/LanguageContext";
import BackToTop from "components/LayoutFront/BackToTop";
import LanguageSelector from "components/LayoutFront/LanguageSelector";
import ScrollIndicator from "components/LayoutFront/ScrollIndicator";
import SearchBar from "components/LayoutFront/SearchBar";
import SkipTo from "components/LayoutFront/SkipTo";
import PageVersionContext from "components/PageVersionContext";
import SiteContext from "components/SiteContext";
import Link from "components/templatesComponents/Link";
import UserTabbingContext from "components/UserTabbingContext";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionService from "services/SectionService";
import { getSiteName, getSitePrefix, stringToSnakeCaseString } from "utils/commonUtils";
import languages from "utils/languagesTypes";
import t from "utils/locales/translation.json";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import { getEnglishItems } from "utils/urlUtils";

const childrenStyled = {
  flex: "1 0 auto",
  overflow: "auto",
};

const rootStyled = {
  height: "100%",
  overflowY: "scroll",
  zIndex: 1100,
  "@media print": {
    display: "block",
    overflow: "visible",
    overflowY: "visible",
  },
};

const ScrollToTop = (props) => {
  const { scrollableClasses } = props;
  const { pathname, hash } = useLocation();

  let classes = scrollableClasses;
  if (!Array.isArray(scrollableClasses)) {
    classes = [scrollableClasses];
  }

  useEffect(() => {
    if (!hash || (hash && hash === "")) {
      classes.forEach((classe) => {
        const scrollableClass = document.getElementsByClassName(classe)[0];
        if (scrollableClass) {
          scrollableClass.scrollTop = 0;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};

ScrollToTop.propTypes = {
  scrollableClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Navigation = (props) => {
  const { children = null, pageWidth = "100%", allPnuPages, associatedPnuPages, homePage = {} } = props;

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [displaySearchModal, setDisplaySearchModal] = useState(false);
  const [userTabbing, setUserTabbing] = useState(false);

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { id: siteId, name: siteName, host } = site || {};
  const { path: homePagePath, fullPath: homePageFullPath } = homePage;

  const connectUrl = process.env.ants_auth_url ? `${process.env.ants_auth_url}?lang=${languages[language]?.lang}` : "";

  const displayLanguageSelector = useMemo(() => !!allPnuPages.length, [allPnuPages]);

  const [{ data: header }] = useAxiosCache(SectionService.getConfig("getHeader", siteId));

  const headerContents = (header && generateTemplatePropsFromContents(header.contents)) || {};
  let { profiles = [] } = headerContents;
  if (!Array.isArray(profiles)) {
    profiles = [profiles];
  }

  const isCurrent = (profile) => host === (profile && profile.link && profile.link.url);

  const profilesDisplayed =
    profiles &&
    profiles.map((profile) => {
      const { id, title: siteTitle = "", link = {}, icon = {} } = profile;
      const { iconDSFR = "" } = icon;
      return (
        <li key={id}>
          <Link
            button
            linkComponent
            {...link}
            key={id}
            aria-label={
              isCurrent(profile)
                ? siteTitle.concat(t[language].components.current_profile_label)
                : t[language].components.other_profile_label.concat(siteTitle)
            }
            className={`fr-btn ${iconDSFR ? `fr-icon-${iconDSFR}` : ""}`}
            sx={{ "&.fr-btn[target=_blank]:after": { content: "none" } }}
          >
            {siteTitle}
          </Link>
        </li>
      );
    });

  const [{ data: menu }] = useAxiosCache(SectionService.getConfig("getMenu", siteId));

  const menuContents = useMemo(() => (menu && generateTemplatePropsFromContents(menu.contents)) || {}, [menu]);

  const menuItemsWithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: menuContents.menus, allPnuPages }) || [];
    }
    return menuContents.menus || [];
  }, [language, menuContents, allPnuPages]);

  const [expandedMenuItem, setExpandedMenuItem] = useState({});

  const isOpen = (menuItem) => menuItem.title === expandedMenuItem.title;

  const { currentPageVersion } = useContext(PageVersionContext);

  const handleSubMenuClick = (menuItem) => {
    setExpandedMenuItem(isOpen(menuItem) ? {} : menuItem);
  };

  const closeAllSubMenus = () => {
    setExpandedMenuItem({});
  };

  const closeSubMenu = (item) => () => {
    if (isOpen(item)) {
      setExpandedMenuItem({});
    }
  };

  const handleMenuClick = () => {
    setDisplayMobileMenu(false);
    closeAllSubMenus();
  };

  // Ouvre le dropdown menu avec Espace ou Entrer
  const handleBtnKeyDown = (event, menuItem) => {
    if (event.target !== event.currentTarget) return;
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      setExpandedMenuItem(isOpen(menuItem) ? {} : menuItem);
    }
  };

  // définis si l'utilisateur utilise le clavier pour naviguer dans le site
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", (event) => {
        if (event.keyCode === 9) {
          setUserTabbing(true);
        }
      });
      window.addEventListener("mousedown", () => {
        setUserTabbing(false);
      });
    }
  }, []);

  const isActive = (menuItem) => {
    if (Array.isArray(menuItem)) {
      return menuItem.some((item) => isActive(item));
    }
    const { link } = menuItem || {};
    const { page } = link || {};
    const { path } = page || {};
    if (currentPageVersion && path) {
      if (path === homePagePath) {
        return currentPageVersion.path === path;
      }
      if (currentPageVersion.fullPath) {
        if (language.toUpperCase() === "EN") {
          return currentPageVersion.fullPath.replace(homePagePath, "").startsWith(path);
        }
        return currentPageVersion.fullPath.startsWith(path);
      }
    }
    return false;
  };

  const getMenuLinkTitle = (menuItem) => {
    const { link, title: menuItemTitle, subMenus } = menuItem || {};
    if (!subMenus) {
      const { url } = link || {};
      return url ? t[language].menu[stringToSnakeCaseString(menuItemTitle)] : menuItemTitle;
    }
    return t[language].menu[stringToSnakeCaseString(menuItemTitle.trim())] || menuItemTitle;
  };

  const renderMenuGroup = (menuItem) => {
    const { id } = menuItem;
    let { subMenus } = menuItem;

    if (!subMenus || (Array.isArray(subMenus) && !subMenus.length)) {
      return null;
    }

    if (!Array.isArray(subMenus)) {
      subMenus = [subMenus];
    }

    return (
      <ClickAwayListener key={id} onClickAway={closeSubMenu(menuItem)}>
        <Box
          component="li"
          className={classnames("fr-nav__item", isActive(subMenus) && "fr-nav__item--active")}
          sx={{ position: "relative" }}
        >
          <Box
            component="button"
            className="fr-nav__btn fr-nav__link"
            type="button"
            onClick={() => handleSubMenuClick(menuItem)}
            role="button"
            tabIndex="0"
            aria-expanded={isOpen(menuItem)}
            onKeyDown={(e) => {
              handleBtnKeyDown(e, menuItem);
            }}
            sx={!isActive(subMenus) ? { "&.fr-nav__link": { boxShadow: "none" } } : {}}
          >
            {getMenuLinkTitle(menuItem)}
          </Box>
          {isOpen(menuItem) ? (
            <Box
              component="ul"
              className="fr-menu__list"
              sx={{
                position: { lg: "absolute" },
                zIndex: 1,
                top: 70,
                right: 0,
                left: 0,
                boxShadow: { lg: 1 },
                "& a": { color: "componentColors.70" },
              }}
            >
              {subMenus.map((subMenuItem) => (
                <li className="fr-menu__item" key={subMenuItem.id}>
                  <Link
                    {...subMenuItem.link}
                    sx={{ fontSize: { xs: "1rem", lg: "0.875rem" } }}
                    onClick={handleMenuClick}
                    className="fr-nav__link"
                  >
                    {getMenuLinkTitle(subMenuItem)}
                  </Link>
                </li>
              ))}
            </Box>
          ) : null}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <UserTabbingContext.Provider value={userTabbing}>
        <Stack className="root" sx={rootStyled}>
          <SkipTo link="#main">{t[language].components.content_access}</SkipTo>
          <SkipTo link="#nav">{t[language].components.main_menu_access}</SkipTo>
          <ScrollToTop scrollableClasses={["root", "children"]} />
          <BackToTop scrollableClass="root" />
          <ScrollIndicator scrollableClass="root" />
          <Box sx={{ zIndex: 1100, bgcolor: { lg: "componentColors.30" }, p: 0 }}>
            <Box component="header" role="banner" className="fr-header" sx={{ maxWidth: pageWidth, mx: "auto" }}>
              <Box className="fr-header__body" sx={{ position: "relative" }}>
                <div className="fr-container">
                  <div className="fr-header__body-row">
                    <div className="fr-header__brand fr-enlarge-link">
                      <div className="fr-header__brand-top">
                        <div className="fr-header__logo">
                          <Link url={homePageFullPath || homePagePath} aria-label={t[language].components.header_logo}>
                            <p className="fr-logo">
                              République
                              <br />
                              Française
                            </p>
                          </Link>
                        </div>
                        <Box className="fr-header__logo" sx={{ p: 0 }}>
                          <Box
                            component="img"
                            src="/logo_France_Titres_clair.svg"
                            alt=""
                            sx={{ width: { xs: "9rem", md: "100%" } }}
                          />
                        </Box>
                        <div className="fr-header__navbar">
                          <button
                            type="button"
                            className="fr-btn--search fr-btn"
                            data-fr-opened="false"
                            title={t[language].common.search.button_title}
                            onClick={() => setDisplaySearchModal(true)}
                          >
                            {t[language].common.search.placeholder}
                          </button>
                          <button
                            type="button"
                            className="fr-btn--menu fr-btn"
                            data-fr-opened="false"
                            title={t[language].components.main_menu}
                            onClick={() => setDisplayMobileMenu(true)}
                          >
                            {t[language].components.main_menu}
                          </button>
                        </div>
                      </div>
                      {["AUTO_ECOLE", "MAIRIE", "PHA"].includes(siteName) && (
                        <div className="fr-header__service">
                          <Link
                            url={homePageFullPath || homePagePath}
                            aria-label={t[language].components.header_logo}
                            title={`Accueil -  ${getSiteName(siteName)}`}
                          >
                            <Box className="fr-header__service-title" sx={{ display: "flex" }}>
                              <Box sx={{ color: "#E1000F" }}>
                                {getSitePrefix(siteName).substring(0, getSitePrefix(siteName).length - 1)}
                              </Box>
                              .ants.gouv.fr
                            </Box>
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="fr-header__tools">
                      {[true, "true"].includes(process.env.display_version) && (
                        <Card sx={{ position: "absolute", top: 0, left: 0 }}>
                          <Box sx={{ p: 0.5, color: "#ce0500", borderRadius: 1.25, border: "1px solid #ce0500" }}>
                            {process.env.npm_package_version}
                          </Box>
                        </Card>
                      )}
                      <div className="fr-header__tools-links">
                        <ul className="fr-btns-group">
                          {profilesDisplayed}
                          <Box component="li" sx={{ order: { xs: -1, lg: 0 } }}>
                            <Link
                              button
                              type="button"
                              external
                              linkComponent
                              className="fr-btn fr-btn--tertiary fr-icon-account-circle-fill"
                              url={connectUrl}
                              title={t[language].components.log_in_title}
                              sx={{
                                fontWeight: "500",
                                "&.fr-btn[target=_blank]:after": { content: "none" },
                              }}
                            >
                              {t[language].components.log_in}
                            </Link>
                          </Box>
                        </ul>
                        {displayLanguageSelector && <LanguageSelector associatedPnuPages={associatedPnuPages} />}
                      </div>
                      <Box
                        className="fr-header__search fr-modal"
                        sx={{
                          visibility: { xs: displaySearchModal ? "visible" : "hidden", lg: "visible" },
                          opacity: { xs: displaySearchModal ? 1 : 0, lg: 1 },
                        }}
                      >
                        <div className="fr-container fr-container-lg--fluid">
                          <button
                            type="button"
                            className="fr-link--close fr-link"
                            title={t[language].common.close}
                            onClick={() => setDisplaySearchModal(false)}
                          >
                            {t[language].common.close}
                          </button>
                          <SearchBar onSearch={() => setDisplaySearchModal(false)} />
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </Box>
              <Box
                className="fr-header__menu fr-menu fr-modal"
                sx={{
                  visibility: { xs: displayMobileMenu ? "visible" : "hidden", lg: "visible" },
                  opacity: { xs: displayMobileMenu ? 1 : 0, lg: 1 },
                }}
              >
                <div className="fr-container">
                  <button
                    type="button"
                    className="fr-btn--close fr-btn"
                    // aria-controls="menu-modal"
                    title={t[language].common.close}
                    onClick={() => setDisplayMobileMenu(false)}
                  >
                    {t[language].common.close}
                  </button>
                  <Box className="fr-header__menu-links" sx={{ display: { xs: "grid", lg: "none" } }}>
                    {displayLanguageSelector && <LanguageSelector associatedPnuPages={associatedPnuPages} />}
                  </Box>
                  <nav
                    className="fr-nav"
                    id="navigation-478"
                    role="navigation"
                    aria-label={t[language].components.main_menu}
                  >
                    <ul className="fr-nav__list">
                      {menuItemsWithPNU?.map((menuItem) =>
                        !menuItem.subMenus && menuItem.link ? (
                          <li
                            className={classnames("fr-nav__item", isActive(menuItem) && "fr-nav__item--active")}
                            key={menuItem.id}
                          >
                            <Link
                              {...menuItem.link}
                              sx={{ fontSize: { xs: "1rem", lg: "0.875rem" } }}
                              className="fr-nav__link fr-nav__link__item"
                              onClick={handleMenuClick}
                            >
                              {getMenuLinkTitle(menuItem)}
                            </Link>
                          </li>
                        ) : (
                          renderMenuGroup(menuItem)
                        )
                      )}
                    </ul>
                  </nav>
                </div>
              </Box>
            </Box>
          </Box>
          <Stack
            sx={{
              flex: "1 0 auto",
              position: "relative",
              "@media print": {
                pt: 0,
              },
            }}
          >
            <Stack className="children" sx={childrenStyled}>
              {children}
            </Stack>
          </Stack>
        </Stack>
      </UserTabbingContext.Provider>
    </Box>
  );
};

Navigation.propTypes = {
  children: PropTypes.node,
  pageWidth: PropTypes.string,
  allPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  associatedPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  homePage: PropTypes.shape(),
};

export default Navigation;
