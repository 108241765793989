import Wrapper from "components/LayoutFront/Wrapper";
import PropTypes from "prop-types";
import React from "react";

const Decrees = (props) => {
  const { decrees = null } = props;

  return <Wrapper>{decrees}</Wrapper>;
};

Decrees.propTypes = {
  decrees: PropTypes.node,
};

export default Decrees;
