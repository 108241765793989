import Box from "@mui/material/Box";
import PageVersionContext from "components/PageVersionContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import sanitizeHtml from "sanitize-html";
import EulerianService from "services/EulerianService";

export const componentClassName = "Da-Text";

const textStyled = {
  width: "100%",
  "& a": {
    color: "var(--text-action-high-blue-france)",
  },
  "& a[target=_blank]:after": {
    content: `"\\f08e"`,
    fontSize: "12px",
    fontFamily: `"Font Awesome 5 Pro"`,
    pl: 0.5,
  },
  "&  ul": {
    listStyle: "none",
    pl: 2,
    "& li": {
      textIndent: "-16px",
      "&:before": {
        content: `"\\f111"`,
        fontWeight: "600",
        fontSize: "8px",
        verticalAlign: "middle",
        textIndent: "0",
        width: "16px",
        display: "inline-block",
        fontFamily: `"Font Awesome 5 Pro"`,
        color: "secondary.main",
      },
      "::marker": {
        content: "none",
      },
      "& ul": {
        ml: 3,
        "& li": {
          textIndent: "-22px",
          "&:before": {
            content: `"\\f00c"`,
            fontWeight: "400",
            fontSize: "14px",
            width: "22px",
            color: "secondary.main",
          },
          "::marker": {
            content: "none",
          },
        },
      },
    },
  },
};

const sanitizerOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["id", "class", "style", "title"],
    a: ["href", "name", "target"],
    img: ["src", "target", "alt"],
  },
  allowedSchemesByTag: {
    ...sanitizeHtml.defaults.allowedSchemesByTag,
    img: ["https", "data"],
  },
};

const Text = (props) => {
  const { children = null, sx = {}, highlight = false, ...others } = props;
  const childrenProps = {};
  if (typeof children === "string") {
    let childrenDecode = children;
    try {
      childrenDecode = decodeURIComponent(children);
    } catch (e) {
      // do nothing
    }
    childrenProps.dangerouslySetInnerHTML = { __html: sanitizeHtml(childrenDecode, sanitizerOptions) };
  } else {
    childrenProps.children = children;
  }

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  const handleClick = (e) => {
    const targetIsLink = e.target && e.target.tagName && e.target.tagName.toLowerCase() === "a";
    if (breadcrumb && targetIsLink) {
      EulerianService.sendClick({ link: [`link::click::${e.target?.firstChild?.nodeValue?.toLowerCase() || ""}`] });
    }
  };

  return (
    <Box
      component="p"
      sx={(theme) => ({
        ...textStyled,
        ...sx,
        boxShadow: highlight ? `inset .22rem 0 0 0 ${theme.palette.secondary.main}` : "none",
      })}
      className={`${componentClassName} ${highlight ? " fr-highlight" : ""}`}
      {...childrenProps}
      {...others}
      onClick={handleClick}
      role="presentation"
    />
  );
};

Text.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  highlight: PropTypes.bool,
};

export default Text;
