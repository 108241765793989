import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import LanguageContext from "components/LanguageContext";
import Hidden from "components/templatesComponents/Hidden";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Tags from "components/templatesComponents/Tags";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import ClampLines from "react-clamp-lines";
import t from "utils/locales/translation.json";

const clampLinesStyled = {
  ml: { xs: 2, lg: 0 },
  display: { lg: "flex" },
  flexDirection: "column",
  height: { lg: 82 },
  ".clamp-lines ": {
    pb: 1,
    fontWeight: 600,
    flex: 1,
    overflow: "hidden",
    whiteSpace: "normal",
    color: "#353535",
    fontSize: "1.1rem",
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
    lineHeight: "1.6",
  },
};

const KnowEverythingNews = (props) => {
  const { page = {} } = props;
  const { title, tags, image, id } = page;

  const { language } = useContext(LanguageContext);

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Link page={page} aria-label={title} sx={{ display: { xs: "flex", lg: "inline" } }}>
      {image && (
        <Box aria-hidden sx={{ minWidth: "64px", position: { lg: "relative" }, width: { lg: "344px" }, mb: { lg: 2 } }}>
          <ImageContainer ratio={{ xs: 100, lg: 98 }}>{image}</ImageContainer>
          <Hidden lgDown>
            {tags && tags.length > 0 && (
              <Box
                sx={{
                  fontSize: "0.75rem",
                  opacity: "0.9",
                  position: { lg: "absolute" },
                  bottom: 0,
                  left: 0,
                  maxWidth: { lg: "60%" },
                  bgcolor: { lg: "secondary.20" },
                  px: { lg: 3 },
                  py: { lg: 2 },
                }}
              >
                <Tags tags={page.tags} noIcon />{" "}
              </Box>
            )}
          </Hidden>
        </Box>
      )}
      <Box sx={clampLinesStyled}>
        {title && <ClampLines id={id} text={title} lines={isMobile ? 3 : 2} buttons={false} />}
        <Hidden lgUp>
          {tags && tags.length > 0 && (
            <div aria-hidden>
              <Tags tags={page.tags} noIcon />
            </div>
          )}
        </Hidden>
        <Hidden lgDown>
          <Box component="span" aria-hidden sx={{ display: "flex", color: "primary.40", fontWeight: 600 }}>
            {t[language].common.see_more} <ChevronRightIcon role="img" />
          </Box>
        </Hidden>
      </Box>
    </Link>
  );
};

KnowEverythingNews.propTypes = {
  page: PropTypes.shape(),
};

export default KnowEverythingNews;
