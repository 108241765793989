import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import SiteContext from "components/SiteContext";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React from "react";
import PageService from "services/PageService";
import elementsTypes from "utils/elementsTypes";

const getAllNewsPageSiteName = (page) =>
  page?.contents?.find((content) => content.type === elementsTypes.SITE_NAME)?.value;

const NewsTabs = (props) => {
  const { currentPageVersion } = props;
  const { page: currentPage } = currentPageVersion;
  const { lang = "FR" } = currentPage;

  const site = React.useContext(SiteContext);
  const { name: currentSiteName } = site || {};

  const [{ data: pages = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: ["ALLNEWS", "LASTNEWS"],
      siteName: currentSiteName,
      filters: [`page.lang||eq||${lang}`],
    })
  );

  const tabPages = pages?.pages || pages;

  React.useMemo(() => {
    if (Array.isArray(tabPages) && currentSiteName) {
      const getPageOrder = (page) => {
        switch (getAllNewsPageSiteName(page)) {
          case "allSites":
            return 0;
          case currentSiteName:
            return 1;
          case "IMMAT":
            return 2;
          case "PASSEPORT_CNI":
            return 3;
          case "PERMIS":
            return 4;
          default:
            return Infinity;
        }
      };
      tabPages.sort((pageA, pageB) => {
        if (pageB.template === pageA.template) {
          if (pageA.template === "ALLNEWS") {
            return getPageOrder(pageA) - getPageOrder(pageB);
          }
          return 0;
        }
        if (pageA.template === "ALLNEWS") {
          return 1;
        }
        return -1;
      });
    }
  }, [tabPages, currentSiteName]);

  return (
    <MuiTabs
      value={currentPageVersion?.pageId}
      variant="scrollable"
      indicatorColor="primary"
      TabIndicatorProps={{
        sx: {
          "&:before": {
            content: `""`,
            display: "block",
            height: "100%",
            width: "30%",
            m: "0 auto",
          },
        },
      }}
      selectionFollowsFocus
      sx={{
        borderBottom: "1px solid #E5E5E5",
        my: 0,
        mx: { xs: -2, lg: 0 },
      }}
    >
      {Array.isArray(tabPages) &&
        tabPages.map((page) => (
          <MuiTab
            key={page.id}
            label={page.title}
            value={page.pageId}
            component={Link}
            url={page.fullPath}
            page={page}
            sx={{
              textTransform: "none",
              minWidth: 0,
              p: 0,
              mr: 3,
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
              "a&:hover": {
                bgcolor: "#e0e0e080",
              },
              "&.Mui-focusVisible": {
                border: "2px solid #0a76f6",
              },
              "&.Mui-selected": {
                color: "primary.main",
              },
            }}
          />
        ))}
    </MuiTabs>
  );
};

NewsTabs.propTypes = {
  currentPageVersion: PropTypes.shape().isRequired,
};

export default NewsTabs;
