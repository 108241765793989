import { Grid } from "@mui/material";
import CardDsfrContext from "components/CardDsfrContext";
import DSFRListContext from "components/DSFRListContext";
import { ImageContainer } from "components/templatesComponents/Image";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const getCardDSFRContextValue = (t) => {
  switch (t) {
    case "ALLNEWS":
      return { showImage: true, showDetail: true };
    case "HELP":
      return { showImage: true };
    case "PAGE_LINKS":
      return { showBadge: true };
    default:
      return {};
  }
};

const DSFRSubpageContents = (props) => {
  const {
    cardsList = {},
    specificCardsList = {},
    specificTilesList = {},
    linksList = {},
    dynamicPart = {},
    image,
    description,
    template = "",
  } = props;

  const linksListLength = linksList.props?.children?.props?.children?.length || 0;

  const ratio = linksListLength > 3 ? 21 + linksListLength * 10 : 61;

  const dsfrListContextValue = useMemo(() => ({ xs: 12, sm: 6, lg: 4 }), []);

  return (
    <CardDsfrContext.Provider value={getCardDSFRContextValue(template)}>
      {template === "KNOW_EVERYTHING" && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ImageContainer ratio={ratio}>{image}</ImageContainer>
          </Grid>
          <Grid item xs={12} md={8} sx={{ "& ul": { pl: 0, "& li::marker": { content: "none" } } }}>
            <div className="fr-text--md">{description}</div>
            {linksList}
          </Grid>
        </Grid>
      )}
      {template === "HELP" && specificTilesList}
      {["PROCESSES", "ALLNEWS"].includes(template) && specificCardsList}
      {template === "PAGE_LINKS" && (
        <DSFRListContext.Provider value={dsfrListContextValue}>{cardsList}</DSFRListContext.Provider>
      )}
      {template === "NO_TEMPLATE" && dynamicPart}
    </CardDsfrContext.Provider>
  );
};

DSFRSubpageContents.propTypes = {
  cardsList: PropTypes.shape(),
  specificCardsList: PropTypes.shape(),
  specificTilesList: PropTypes.shape(),
  linksList: PropTypes.shape(),
  dynamicPart: PropTypes.shape(),
  image: PropTypes.shape(),
  description: PropTypes.string,
  template: PropTypes.string,
};

export default DSFRSubpageContents;
