import PropTypes from "prop-types";
import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import Icon from "components/templatesComponents/Icon";
import t from "utils/locales/translation.json";

const Tags = (props) => {
  const {
    tags = [],
    noIcon = false,
    iconColor = "inherit",
    withDSFR = false,
    tagLink = false,
    selectedTags = [],
    handleSelectTags = null,
    ...others
  } = props;

  const { language } = useContext(LanguageContext);

  const selected = React.useCallback(
    (s) => {
      return selectedTags.includes(s);
    },
    [selectedTags]
  );

  if (!Array.isArray(tags) || tags.length === 0) {
    return null;
  }

  if (withDSFR) {
    return (
      <ul className="fr-tags-group">
        {tags.map((tag) => (
          <li key={tag}>
            <Box
              component={tagLink ? "a" : "button"}
              type={tagLink ? undefined : "button"}
              className="fr-tag"
              sx={{ "&:first-letter": { textTransform: "uppercase" } }}
              aria-pressed={selected(tag) ? "true" : "false"}
              onClick={() => handleSelectTags(tag)}
              style={
                tagLink
                  ? {
                      color: "var(--text-action-high-blue-france)",
                      backgroundColor: "var(--background-action-low-blue-france)",
                    }
                  : undefined
              }
            >
              {tag}
            </Box>
          </li>
        ))}
      </ul>
    );
  }

  return (
    tags.length > 0 && (
      <Grid container alignItems="center" {...others}>
        {!noIcon && (
          <Icon
            icon="tag"
            iconDSFR="bookmark-line"
            sx={{ color: iconColor, fontSize: "16px", mr: 1 }}
            title={t[language].components.tag_icon_title}
          />
        )}
        {Array.isArray(tags) &&
          tags.map((tag) => (
            <Box key={tag} component="span" mr={1}>
              <span>#{tag}</span>
            </Box>
          ))}
      </Grid>
    )
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  noIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  withDSFR: PropTypes.bool,
  tagLink: PropTypes.bool,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleSelectTags: PropTypes.func,
};

export default Tags;
