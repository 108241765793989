import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Wrapper from "components/LayoutFront/Wrapper";
import DSFRSubpageContents from "components/templatesComponents/DSFRSubpageContents";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import SeparatorDSFR from "components/templatesComponents/SeparatorDSFR";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const DSFRSubpage = (props) => {
  const { link = null, title, ...contents } = props;

  const { noSeparator } = useContext(SubPageContext);

  return (
    <>
      {!noSeparator && <SeparatorDSFR />}
      <Box sx={{ minHeight: "200px" }}>
        <Wrapper>
          <Stack
            direction={{ md: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", lg: "center" }}
            spacing={2}
            sx={{ mt: 2, mb: 3, "& h2": { flex: 1 } }}
          >
            {title && <h2>{title}</h2>}
            {link.title && (
              <Link {...link} linkComponent sx={{ backgroundImage: "none", ml: 3 }}>
                {link.title}
                <Icon
                  icon="arrow-right"
                  iconDSFR="arrow-right-line"
                  sx={{ "&::before": { "--icon-size": "1rem", ml: 1 } }}
                />
              </Link>
            )}
          </Stack>
          <DSFRSubpageContents {...contents} />
        </Wrapper>
      </Box>
    </>
  );
};

DSFRSubpage.propTypes = {
  link: PropTypes.shape(),
  title: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};

export default DSFRSubpage;
