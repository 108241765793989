import Grid from "@mui/material/Grid";
import DSFRListContext from "components/DSFRListContext";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const Result = (props) => {
  const { icon = "null", number = "", text = "", ...others } = props;
  const { lg, xs } = useContext(DSFRListContext);

  return (
    <Grid
      item
      container
      direction="column"
      lg={lg}
      xs={xs}
      sx={{
        py: { xs: 2.25, lg: 2 },
        pl: { xs: 2.5, lg: 4 },
        pr: { xs: 2.5, lg: 0 },
        bgcolor: "primary.10",
        "&:focus": { zIndex: 1 },
      }}
      {...others}
    >
      {icon && (
        <Grid item sx={{ display: "inline-block", verticalAlign: "middle", mr: 1 }}>
          <Icon {...icon} sx={{ fontSize: { xs: "1.125rem", lg: "1.5rem" } }} title={text} />
        </Grid>
      )}

      <Grid item className="gridBorder" sx={{ mt: 1, flex: "1 1 auto", pr: { lg: 4 } }}>
        <h2>{number}</h2>
        <p className="fr-text--sm fr-text--bold" role="presentation">
          {text}
        </p>
      </Grid>
    </Grid>
  );
};

Result.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  text: PropTypes.string,
  details: PropTypes.node,
};

export default Result;
