import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import LanguageContext from "components/LanguageContext";
import MessageContext from "components/MessageContext";
import Autosuggest from "components/templatesComponents/Autosuggest";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ComedecService from "services/ComedecService";
import getComedecCities from "utils/comedecUtils";
import t from "utils/locales/translation.json";

const inputStyled = {
  bgcolor: "white",
  border: "1px solid",
  borderColor: "divider",
  borderRadius: 1,
  px: 2,
  height: "53.625px",
  fontSize: "1em",
  "&.Mui-focused": {
    borderColor: "secondary.main",
  },
  "& input": {
    height: "1.4rem",
  },
  "& button": {
    mr: -1.5,
    color: "componentColors.70",
  },
};
const autoSuggestClasses = (theme) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
  },
  suggestionsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.componentColors[30]}`,
    borderBottom: "none",
    borderTop: "none",
    zIndex: 100,
    maxHeight: "300px",
    overflow: "auto",
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: "0 !important",
    margin: 0,
    borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
  },
});

const SearchBar = (props) => {
  const { postalCode = "", codeDepartement = "", setCity } = props;

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const [value, setValue] = useState("");
  const theme = useTheme();

  useEffect(() => {
    if (postalCode && postalCode !== codeDepartement) {
      setValue("");
      setCity({});
    }
  }, [postalCode, codeDepartement, setCity]);

  const getSuggestions = async (val) => {
    try {
      const results = await ComedecService.getCities({ city: val });
      let { data: cities } = results;
      if (postalCode) {
        cities = (Array.isArray(cities) && cities.filter((d) => d.codeDepartement === postalCode)) || [];
      }
      const comedecCities = await getComedecCities({ val, postalCode });
      const citiesExistedName = cities.map((c) => c.nom);
      const citiesExistedCode = cities.map((c) => c.code);
      if (Array.isArray(comedecCities)) {
        comedecCities.map((cl) => {
          return (citiesExistedName.includes(cl.nom) && citiesExistedCode.includes(cl.code)) ||
            (postalCode && cl.codeDepartement !== postalCode)
            ? null
            : cities.push(cl);
        });
      }
      cities.sort((a, b) => a.nom.localeCompare(b.nom));
      return cities || [];
    } catch {
      try {
        const cities = [];
        const comedecCities = await getComedecCities({ val, postalCode });
        if (Array.isArray(comedecCities)) {
          comedecCities.map((cl) => {
            return postalCode && cl.codeDepartement !== postalCode ? null : cities.push(cl);
          });
        }
        cities.sort((a, b) => a.nom.localeCompare(b.nom));
        return cities;
      } catch {
        displayError(t[language].common.search.error_api_message);
        return [];
      }
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.nom;

  const getCodeValue = (suggestion) => {
    return suggestion.codesPostaux ? suggestion.codesPostaux[0] : suggestion.code;
  };

  const renderSuggestion = (suggestion) => (
    <Box key={suggestion.code} value={suggestion} onClick={() => setCity(suggestion)} sx={{ cursor: "pointer" }}>
      {suggestion.nom}&nbsp;({getCodeValue(suggestion)})
    </Box>
  );

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setValue(suggestionValue);
    setCity(suggestion);
  };

  const renderInputComponent = (inputProps) => (
    <Input role="combobox" aria-expanded="false" sx={inputStyled} disableUnderline {...inputProps} fullWidth />
  );

  const inputProps = {
    placeholder: t[language].comedec.placeholder,
    title: t[language].comedec.placeholder,
    value,
    onChange,
    inputProps: {
      "aria-label": t[language].comedec.city_title,
      title: t[language].comedec.city_title,
      style: {
        border: "none",
      },
    },
  };

  // Accessibilité: suppresion d'un role="listbox" non pertinent
  if (typeof document !== "undefined") {
    const suggestContainer = document.getElementById("react-autowhatever-1");
    if (suggestContainer) {
      suggestContainer.removeAttribute("role");
    }
  }

  return (
    <Toolbar disableGutters>
      <Autosuggest
        getSuggestions={getSuggestions}
        debounce={300}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        classes={autoSuggestClasses(theme)}
        noResult={
          <div>
            <b>
              {t[language].common.search.no_result_message} : &laquo; {value} &raquo;
            </b>
          </div>
        }
      />
    </Toolbar>
  );
};

SearchBar.propTypes = {
  postalCode: PropTypes.string,
  codeDepartement: PropTypes.string,
  setCity: PropTypes.func.isRequired,
};

export default SearchBar;
