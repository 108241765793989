import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const Content = (props) => {
  const { children = null, pageWidth } = props;

  return (
    <Box
      sx={{
        color: "componentColors.70",
        fontSize: 14,
        bgcolor: { xs: "#F8F8F8", lg: "#fff" },
        flex: "1 0 auto",
        width: "100%",
        zIndex: 1,
        pb: 10,
        mx: "auto",
        maxWidth: pageWidth,
        "@media print": {
          "& button": {
            display: "none",
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  pageWidth: PropTypes.string.isRequired,
};

export default Content;
