import HelpForm from "components/HelpForm";
import Wrapper from "components/LayoutFront/Wrapper";
import Accordion from "components/templatesComponents/Accordion";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import PageTitle from "components/templatesComponents/PageTitle";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Tabs from "components/templatesComponents/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { stringToPath } from "utils/urlUtils";

const frequentlyAskedQuestionsTabsStyled = {
  height: "97px",
  bgcolor: "componentColors.10",
  borderBottom: "8px solid",
  borderColor: "secondary.main",
  fontSize: "0.875rem",
  "&.Mui-selected": {
    bgcolor: "secondary.main",
    fontWeight: "bold",
    color: "inherit",
    "button&:hover": {
      bgcolor: "secondary.main",
    },
  },
  "& .MuiTab-wrapper": {
    display: "block",
  },
  "&.MuiTab-textColorInherit": {
    opacity: "inherit",
  },
  "&.Mui-focusVisible": {
    border: "2px solid #0a76f6",
  },
};

const Help = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { frequentlyAskedQuestionsTitle, frequentlyAskedQuestions } = contents;

  let { tabs: frequentlyAskedQuestionsTabs = [] } = frequentlyAskedQuestions;

  const displayTabs = typeof frequentlyAskedQuestionsTitle === "object" || frequentlyAskedQuestionsTabs.length > 0;

  const displayTabsBlock = frequentlyAskedQuestionsTitle || displayTabs;

  const [sendTag, setSendTag] = useState(false);

  // frequently asked questions
  if (!Array.isArray(frequentlyAskedQuestionsTabs)) {
    frequentlyAskedQuestionsTabs = [frequentlyAskedQuestionsTabs];
  }
  frequentlyAskedQuestionsTabs = frequentlyAskedQuestionsTabs.map((t) => ({ ...t, url: stringToPath(t.title) }));

  return (
    <>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        {displayTabsBlock && (
          <Block sx={{ pb: { lg: 10 } }}>
            {frequentlyAskedQuestionsTitle && (
              <SectionTitle sx={{ mb: { xs: 3, lg: 4 } }}>{frequentlyAskedQuestionsTitle}</SectionTitle>
            )}
            {displayTabs && (
              <>
                <Hidden lgDown>
                  <Tabs
                    {...frequentlyAskedQuestions}
                    tabProps={{ sx: frequentlyAskedQuestionsTabsStyled }}
                    panelClass={{ p: 4, border: "1px solid", borderColor: "componentColors.30" }}
                    tabs={frequentlyAskedQuestionsTabs}
                    variant="fullWidth"
                    sendTag={sendTag}
                    setSendTag={setSendTag}
                  />
                </Hidden>
                <Hidden lgUp>
                  <Accordion
                    panels={frequentlyAskedQuestionsTabs.map((q) => ({
                      id: q.id,
                      summary: q.title,
                      details: q.children,
                    }))}
                  />
                </Hidden>
              </>
            )}
          </Block>
        )}
      </Wrapper>
      <HelpForm page={page} />
    </>
  );
};

Help.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Help;
