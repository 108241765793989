import Box from "@mui/material/Box";
import CookiesModal from "components/CookiesModal";
import CookiesModalContext from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ConsentService from "services/ConsentService";
import EulerianService from "services/EulerianService";
import t from "utils/locales/translation.json";

const CookiesBanner = (props) => {
  const { bo = false } = props;
  const [cookieConsent, setCookieConsent] = useState(true);

  const { handleCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    setCookieConsent(ConsentService.getConsent());
  }, []);

  const handleValidateClick = () => {
    setCookieConsent(true);
    ConsentService.acceptConsent();
    EulerianService.acceptAll();
  };

  const handleRefuseClick = () => {
    setCookieConsent(false);
    ConsentService.refuseConsent();
    EulerianService.denyAll();
  };

  const host = (typeof window !== "undefined" && `${t[language].cookies.host_prefix} ${window.location?.host}`) || "";

  return (
    <>
      {!bo && cookieConsent == null && (
        <div className="fr-consent-banner">
          <h2 className="fr-h6">
            {t[language].cookies.title} {host}
          </h2>
          <div className="fr-consent-banner__content">
            <p className="fr-text--sm">
              {t[language].cookies.banner_description_1}
              <Link linkComponent external size="sm" url="/politique-de-confidentialite" sx={{ color: "inherit" }}>
                {t[language].cookies.banner_link}
              </Link>
              . {t[language].cookies.banner_description_2}
            </p>
          </div>
          <ul className="fr-consent-banner__buttons fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-sm">
            <li>
              <button
                type="button"
                className="fr-btn"
                title={t[language].cookies.accept_button_title}
                onClick={handleValidateClick}
              >
                {t[language].cookies.accept_button_label}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="fr-btn"
                title={t[language].cookies.refuse_button_title}
                onClick={handleRefuseClick}
              >
                {t[language].cookies.refuse_button_label}
              </button>
            </li>
            <li>
              <Box
                component="button"
                type="button"
                className="fr-btn fr-btn--secondary"
                data-fr-opened="false"
                title={t[language].cookies.personalize_button_title}
                sx={{ boxShadow: "inset 0 0 0 1px var(--border-action-high-blue-france)" }}
                onClick={(e) => {
                  handleCookiesModal();
                  e.stopPropagation();
                }}
              >
                {t[language].cookies.personalize_button_label}
              </Box>
            </li>
          </ul>
        </div>
      )}
      <CookiesModal
        cookieConsent={cookieConsent}
        handleCookiesModal={handleCookiesModal}
        handleValidateClick={handleValidateClick}
        handleRefuseClick={handleRefuseClick}
      />
    </>
  );
};

CookiesBanner.propTypes = {
  bo: PropTypes.bool,
};

export default CookiesBanner;
