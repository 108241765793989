import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import HorizontalScroll from "components/templatesComponents/HorizontalScroll";
import SeeMoreLink from "components/templatesComponents/SeeMoreLink";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import t from "utils/locales/translation.json";

const itemStyled = {
  height: "216px",
  pr: { xs: 2, lg: 3 },
};

const HomeServices = (props) => {
  const { page = {} } = props;
  const { title, contents } = page;
  const { pageLinks } = contents;

  const { language } = useContext(LanguageContext);

  const cardContextValue = useMemo(
    () => ({
      desktopStyleKey: styleKeys.WITH_ICON_LITTLE,
      mobileStyleKey: styleKeys.WITH_ICON_LITTLE,
      Wrapper: Box,
      wrapperProps: {
        role: "listitem",
        sx: itemStyled,
      },
    }),
    []
  );
  const cards = ((pageLinks && pageLinks.props && pageLinks.props.cards) || []).map((card) => (
    <CardContext.Provider value={cardContextValue} key={card.key}>
      {card}
    </CardContext.Provider>
  ));
  return (
    <Wrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: { xs: 3, lg: 8 },
        pb: { xs: 3, lg: 5 },
        bgcolor: "primary.10",
      }}
      leftProps={{ sx: { bgcolor: "primary.10" } }}
      rightProps={{ sx: { bgcolor: "primary.10" } }}
    >
      <Block>
        <Box component="h2" className="fr-h1" mb={{ xs: 3, lg: 5 }}>
          {title}
        </Box>
        <Box mx={{ lg: -9.5 }} role="list">
          <HorizontalScroll items={cards} />
        </Box>
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            pt: { xs: 2, lg: 3 },
            pb: { lg: 0.5 },
            "& a": {
              fontWeight: 600,
              color: "primary.main",
            },
          }}
        >
          <SeeMoreLink page={page}>
            {t[language].home_services.see_all} {title.toLowerCase()}
          </SeeMoreLink>
        </Grid>
      </Block>
    </Wrapper>
  );
};
HomeServices.propTypes = {
  page: PropTypes.shape(),
};

export default HomeServices;
