import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { amber, green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MUISnackbar from "@mui/material/Snackbar";
import MuiSnackbarContent from "@mui/material/SnackbarContent";
import LanguageContext from "components/LanguageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const snackbarStyled = {
  success: {
    bgcolor: green[600],
    "& > div > button:hover": {
      color: green[600],
    },
  },
  error: {
    bgcolor: "error.dark",
    "& > div > button:hover": {
      color: "error.dark",
    },
  },
  info: {
    bgcolor: "primary.main",
    "& > div > button:hover": {
      color: "primary.main",
    },
  },
  warning: {
    bgcolor: amber[700],
    "& > div > button:hover": {
      color: amber[700],
    },
  },
};

function SnackbarContent(props) {
  const { message = "", onClose, variant = "success", ...other } = props;
  const Icon = variantIcon[variant];
  const { language } = useContext(LanguageContext);

  return (
    <MuiSnackbarContent
      sx={snackbarStyled[variant]}
      message={
        <Grid container component="span" alignItems="center">
          <Icon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
          {message}
        </Grid>
      }
      action={[
        <IconButton key="close" aria-label={t[language].common.close} color="inherit" onClick={onClose}>
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

SnackbarContent.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]),
};

export default function Snackbar(props) {
  const {
    open = false,
    onClose,
    message = "",
    variant = "success",
    duration = 3000,
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "left",
    },
  } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose(event, reason);
  };

  return (
    <MUISnackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={duration} onClose={handleClose}>
      <div>
        <SnackbarContent onClose={handleClose} variant={variant} message={message} />
      </div>
    </MUISnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]),
  duration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
  }),
};
