import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { CookiesModalContextProvider } from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import Banner from "components/LayoutFront/Banner";
import Breadcrumb from "components/LayoutFront/Breadcrumb";
import Content from "components/LayoutFront/Content";
import CookiesBanner from "components/LayoutFront/CookiesBanner";
import Footer from "components/LayoutFront/Footer";
import Navigation from "components/LayoutFront/Navigation";
import PnuBanner from "components/LayoutFront/PnuBanner";
import Share from "components/LayoutFront/Share";
import Wrapper from "components/LayoutFront/Wrapper";
import PageVersionContext from "components/PageVersionContext";
import SiteContext from "components/SiteContext";
import homeDsfr from "components/templates/homeDsfr/homeDsfr";
import allNews from "components/templates/news/allNews";
import lastNews from "components/templates/news/lastNews";
import news from "components/templates/news/news";
import processesDsfr from "components/templates/processesDsfr/processesDsfr";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PageService from "services/PageService";
import t from "utils/locales/translation.json";
import { createQueryParams, getQueryParams } from "utils/urlUtils";

const pageWidth = "100%";

const Social = ({ socialNetworks = [] }) => {
  return (
    <div role="list">
      {socialNetworks.map((social) => {
        const { id, ...others } = social.icon || {};
        return (
          <Link
            role="listitem"
            url={social.url}
            external
            sx={{
              fontSize: { xs: "1.5rem", lg: "1.25rem" },
              color: "var(--text-action-high-blue-france)",
              mr: { xs: 2, lg: 0 },
            }}
            key={social.id}
            title={social.network || null}
            aria-label={social.network || null}
          >
            <Icon {...others} />
            <p className="fr-sr-only">{social.network}</p>
          </Link>
        );
      })}
    </div>
  );
};

Social.propTypes = {
  socialNetworks: PropTypes.arrayOf(PropTypes.shape()),
};

const LayoutFront = (props) => {
  const { children = null, bo = false } = props;

  const history = useHistory();

  const [displayBanner, setDisplayBanner] = useState(true);
  const { language, setLanguage } = useContext(LanguageContext);
  const { currentPageVersion = {}, loading } = useContext(PageVersionContext);
  const { template, pageId, page: currentPage = {} } = currentPageVersion;
  const { originalPageId, lang: currentPageLanguage } = currentPage;
  const site = useContext(SiteContext);
  const { id: siteId, name: siteName, settings: siteSettings } = site || {};

  const { pathname: locationPathname, search } = useLocation();

  const queryParams = getQueryParams(search) || {};
  const { lang = language } = queryParams;

  const [{ data: homePage = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      filters: [`page.lang||eq||${language}`, "page.parentId||isnull"],
    })
  );

  const [{ data: associatedPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: currentPageLanguage === "FR" ? pageId : originalPageId,
    }),
    { enabled: !loading }
  );

  const [{ data: allPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      filters: ["page.originalPageId||notnull"],
      perPage: currentPageLanguage === "FR" ? 1 : 200,
    })
  );

  const displayPnuBanner = useMemo(
    () =>
      displayBanner &&
      !!associatedPnuPages.length &&
      !associatedPnuPages.some((m) => m.page?.lang === lang.toUpperCase()),
    [displayBanner, associatedPnuPages, lang]
  );

  const displayShare = useMemo(
    () => !template || ![news.key, allNews.key, lastNews.key, homeDsfr.key, processesDsfr.key].includes(template),
    [template]
  );

  useEffect(() => {
    if (!allPnuPages.length) {
      setLanguage("FR");
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("language", "FR");
      }
      if (typeof document.documentElement !== "undefined") {
        document.documentElement.lang = "fr";
      }
    } else {
      setLanguage(lang.toUpperCase());
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("language", lang.toUpperCase());
      }
      if (typeof document.documentElement !== "undefined") {
        document.documentElement.lang = lang.toLowerCase();
      }
    }
  }, [setLanguage, lang, siteName, allPnuPages.length]);

  useEffect(() => setDisplayBanner(true), [locationPathname]);

  // Redirection vers la page associée à la langue du navigateur
  // (sauf page 404 et BO)
  useEffect(() => {
    if (
      typeof localStorage !== "undefined" &&
      associatedPnuPages.length > 0 &&
      associatedPnuPages.length <= 2 &&
      !bo &&
      Object.keys(currentPageVersion).length > 0 &&
      queryParams?.lang
    ) {
      const localStorageLang = localStorage.getItem("language");
      const getPageByLang = associatedPnuPages.find((p) => p.page?.lang === localStorageLang.toUpperCase());
      const pathname = getPageByLang?.fullPath || getPageByLang?.path || associatedPnuPages[0]?.fullPath;
      history.replace({
        pathname,
        search: `?${createQueryParams(queryParams)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedPnuPages, bo]);

  let { socialNetworks = [] } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  return (
    <Navigation
      pageWidth={pageWidth}
      allPnuPages={allPnuPages}
      associatedPnuPages={associatedPnuPages}
      homePage={homePage[0]}
    >
      <CookiesModalContextProvider>
        <Stack
          sx={{
            bgcolor: { xs: "transparent", lg: "componentColors.30" },
            flex: "1 0 auto",
            "& > footer": { bgcolor: "white" },
          }}
        >
          <Content pageWidth={pageWidth}>
            <Banner />
            <Wrapper breakpoint="xl">
              {displayPnuBanner && (
                <Box px={{ lg: 3 }}>
                  <PnuBanner language={language} setDisplayBanner={setDisplayBanner} />
                </Box>
              )}
              <Stack
                direction={{ lg: "row" }}
                alignItems={{ xs: "center", lg: "baseline" }}
                justifyContent="space-between"
                sx={{
                  px: { md: 3, lg: 0 },
                  "@media print": {
                    display: "none",
                  },
                }}
              >
                <Breadcrumb bo={bo} />
                <Stack
                  alignItems={{ xs: "center", lg: "end" }}
                  justifyContent="center"
                  sx={{
                    mx: 1,
                    mt: 1,
                    mb: displayShare ? 0 : 2,
                  }}
                >
                  {displayShare && <Share />}
                </Stack>
              </Stack>
            </Wrapper>
            <main id="main" role="main">
              {children}
            </main>
          </Content>
          <Box className="fr-social" sx={{ bgcolor: "#F2F2F9" }}>
            <Stack
              direction={{ lg: "row" }}
              alignItems={{ lg: "center" }}
              className="fr-container"
              sx={{
                pb: 3,
                "& h3": {
                  fontSize: { xs: "1.125rem", lg: "1rem" },
                  fontWeight: "bold",
                  minWidth: "200px",
                  color: "var(--text-default-grey)",
                  lineHeight: "1.5rem",
                  my: 3,
                },
              }}
            >
              <h3>
                {t[language].footer.social_networks_1}
                <br />
                {t[language].footer.social_networks_2}
              </h3>
              <Grid
                container
                sx={{
                  justifyContent: { lg: "flex-end" },
                  "& a:not(:first-of-type)": {
                    ml: 2.5,
                  },
                }}
              >
                <Social socialNetworks={socialNetworks} />
              </Grid>
            </Stack>
          </Box>
          <Footer allPnuPages={allPnuPages} homePage={homePage[0]} />
        </Stack>
        <CookiesBanner bo={bo} />
      </CookiesModalContextProvider>
    </Navigation>
  );
};

LayoutFront.propTypes = {
  children: PropTypes.node,
  bo: PropTypes.bool,
};

export default LayoutFront;
