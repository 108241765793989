import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import Accordion from "components/templatesComponents/Accordion";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import Hidden from "components/templatesComponents/Hidden";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Separator from "components/templatesComponents/Separator";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import EulerianService from "services/EulerianService";
import t from "utils/locales/translation.json";

const cardMobileStyle = {
  root: {
    height: 62,
  },
};

const HomeProcesses = (props) => {
  const { page = {} } = props;
  const { title, image, shortDescription, contents } = page;
  const { cardsGroups, cardZero, cardOne, cardTwo, cardThree, cardFour, otherCards } = contents;
  const { id, url, external } = cardZero.props.link;

  const { language } = useContext(LanguageContext);

  let { groups = [] } = cardsGroups || {};

  if (groups && !Array.isArray(groups)) {
    groups = [groups];
  }

  const theme = useTheme();

  const cardZeroContextValue = useMemo(
    () => ({
      desktopStyleKey: styleKeys.HORIZONTAL_COLOR,
      mobileStyleKey: styleKeys.HORIZONTAL_COLOR,
      mobileStyleOverride: cardMobileStyle,
    }),
    []
  );

  const cardContextValue = useCallback(
    (index) => ({
      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
      mobileStyleKey: styleKeys.HORIZONTAL,
      desktopStyleOverride: {
        root: {
          backgroundColor: theme.palette.primary.tones[index],
          "a&:hover": {
            backgroundColor: theme.palette.primary.tones[index],
          },
        },
      },
      mobileStyleOverride: cardMobileStyle,
      Wrapper: Grid,
      wrapperProps: {
        item: true,
      },
    }),
    [theme]
  );

  const cardOthersContextValue = useMemo(
    () => ({
      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
      mobileStyleKey: styleKeys.HORIZONTAL,
      mobileStyleOverride: cardMobileStyle,
      Wrapper: Grid,
      wrapperProps: {
        item: true,
      },
      camaieuStart: 1,
    }),
    []
  );

  const cardPanelContextValue = useMemo(
    () => ({
      desktopStyleKey: styleKeys.HORIZONTAL_BIG,
      mobileStyleKey: styleKeys.HORIZONTAL,
      Wrapper: Box,
      wrapperProps: {
        role: "listitem",
      },
      desktopStyleOverride: {
        root: {
          backgroundColor: "#ffffff",
          border: "1px solid",
          borderColor: "componentColors.30",
          minHeight: 0,
          "a&:hover": {
            bgcolor: "componentColors.30",
          },
        },
        title: {
          fontSize: "inherit",
          color: theme.palette.getContrastText("#ffffff"),
        },
        content: {
          py: 3,
          pr: 2,
          pl: 3,
        },
        arrow: {
          color: theme.palette.getContrastText("#ffffff"),
        },
      },
      camaieuStart: 1,
    }),
    [theme]
  );

  const panels = groups.map((group) => ({
    id: group.id,
    summary: group.name,
    details: group.contents,
  }));

  const handleClickCardZero = () => {
    EulerianService.sendClick({ button: [`button::click::suivre votre demarche en ligne`] });
  };

  return (
    <Block>
      <Wrapper>
        <Grid container sx={{ mt: 3 }}>
          <Grid item container direction="column" lg={8}>
            <Box
              component="h1"
              sx={{ fontSize: { xs: "1.75rem", lg: "2.5rem" }, lineHeight: { xs: "2.13rem", lg: "3rem" } }}
            >
              {title}
            </Box>
            <Hidden lgDown>
              <Box mt={6} mb={3} role="presentation">
                {shortDescription || t[language].home_processes.default_description}
              </Box>
              <Button
                icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
                iconPosition="right"
                component={Link}
                linkComponent
                id={id}
                url={url}
                size="lg"
                aria-label={cardZero.props.title}
                external={external}
                onClick={handleClickCardZero}
              >
                {cardZero.props.title}
              </Button>
            </Hidden>
            <Hidden lgDown>
              {image && (
                <Grid container sx={{ pt: 9.5 }}>
                  <Box sx={{ flex: "1 1 0px" }}>
                    <ImageContainer ratio={43}>{image}</ImageContainer>
                  </Box>
                  <Box>
                    <Box sx={{ pt: 34.5, width: "32px", bgcolor: "secondary.20" }} />
                    <Box sx={{ pt: 10.5, width: "32px", bgcolor: "secondary.main" }} />
                  </Box>
                </Grid>
              )}
            </Hidden>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Hidden lgUp>
              <Box mb={1}>
                <CardContext.Provider value={cardZeroContextValue}>{cardZero}</CardContext.Provider>
              </Box>
            </Hidden>
            {(!panels || panels.length === 0) && (
              <Box mb={1}>
                <Grid container spacing={1} direction="column">
                  <CardContext.Provider value={cardContextValue(0)}>{cardOne}</CardContext.Provider>
                  <CardContext.Provider value={cardContextValue(1)}>{cardTwo}</CardContext.Provider>
                  <CardContext.Provider value={cardContextValue(2)}>{cardThree}</CardContext.Provider>
                  <CardContext.Provider value={cardContextValue(0)}>{cardFour}</CardContext.Provider>
                  <CardContext.Provider value={cardOthersContextValue}>{otherCards}</CardContext.Provider>
                </Grid>
              </Box>
            )}
            {panels && panels.length > 0 && (
              <CardContext.Provider value={cardPanelContextValue}>
                <Accordion panels={panels} noPadding expansionPanelRole="list" />
              </CardContext.Provider>
            )}
            <Button
              seeAll
              fullWidth
              component={Link}
              linkComponent
              page={page}
              aria-label={t[language].home_processes.button_label}
            >
              {t[language].home_processes.button_label}
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
      <Separator />
    </Block>
  );
};

HomeProcesses.propTypes = {
  page: PropTypes.shape(),
};

export default HomeProcesses;
