import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import PageService from "services/PageService";
import { numericFormatDate } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const CardVertical = (props) => {
  const { page: pageVersion = {}, isHighlighted = false, showImage = true } = props;

  const { page = {}, fullPath, image, title, pageId } = pageVersion;

  const { originalPageId, siteId, lang, firstPublishedDate } = page || {};

  const { language } = useContext(LanguageContext);

  const site = useContext(SiteContext);

  const external = siteId !== site?.id;

  const [{ data: frenchPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: lang === "FR" ? pageId : originalPageId,
      onlyPublishedPages: true,
      filters: [`page.lang||eq||FR`],
    })
  );

  const getFirstPublishedDate = () => {
    if (frenchPnuPages.length === 1) {
      const { page: frenchPage = {} } = frenchPnuPages[0];
      return frenchPage.firstPublishedDate;
    }
    return page.firstPublishedDate;
  };

  const date = lang === "FR" ? firstPublishedDate : getFirstPublishedDate();

  return (
    <div className="fr-card fr-enlarge-link">
      <div className="fr-card__body">
        <div className="fr-card__content">
          <h4 className="fr-card__title">
            <Link button external={external} url={fullPath} sx={{ wordBreak: "break-word" }}>
              {title}
            </Link>
          </h4>
          {firstPublishedDate && (
            <div className="fr-card__start">
              <p className="fr-card__detail fr-icon-calendar-event-fill" role="presentation">
                {" "}
                {t[language].common.news.published_label} {numericFormatDate({ date, lang })}
              </p>
            </div>
          )}
        </div>
      </div>
      {showImage && (
        <div className="fr-card__header">
          <div className="fr-card__img">
            <img
              className="fr-responsive-img"
              src={image?.url || "/default-image.png"}
              alt={image?.alt}
              style={{ objectFit: "unset" }}
            />
          </div>
          {isHighlighted && (
            <ul className="fr-badges-group">
              <li>
                <p className="fr-badge fr-badge--info" role="presentation">
                  {t[language].common.news.highlighted}
                </p>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

CardVertical.propTypes = {
  page: PropTypes.shape(),
  isHighlighted: PropTypes.bool,
  showImage: PropTypes.bool,
};

export default CardVertical;
