import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import BannerService from "services/BannerService";
import { localeDateNow } from "utils/bannersUtils";

const Banner = () => {
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { id: siteId } = site || {};

  const [banners, setBanners] = useState([null]);
  const [hiddenBanners, setHiddenBanners] = useState(null);

  const getBanners = useCallback(() => {
    return BannerService.getBanners({ params: { siteId } }).then((response) => {
      setBanners(response.data);
    });
  }, [siteId]);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  useEffect(() => {
    if (typeof localStorage !== "undefined") {
      setHiddenBanners(localStorage.getItem("hiddenBanner"));
    }
  }, []);

  const hiddenBannerArray = useMemo(() => hiddenBanners?.split(",") || [], [hiddenBanners]);

  const handleHideBanner = useCallback(
    (id) => {
      if (!hiddenBannerArray.includes(id)) {
        setHiddenBanners(hiddenBanners ? hiddenBanners.concat(",", id) : id);
      }
    },
    [hiddenBanners, hiddenBannerArray]
  );

  if (hiddenBanners && typeof localStorage !== "undefined") {
    localStorage.setItem("hiddenBanner", hiddenBanners);
  }

  if (!Array.isArray(banners)) {
    return null;
  }

  return banners?.map((banner) => {
    const { id, type, contents, forceDisplay, displayStartDate, displayEndDate } = banner || {};
    const isDisplayed =
      forceDisplay || (Date.parse(displayStartDate) <= localeDateNow && Date.parse(displayEndDate) >= localeDateNow);
    if (hiddenBannerArray?.includes(id) || !isDisplayed) {
      return null;
    }
    const { title, text, link, linkLabel } = contents?.find((content) => content.lang === language) || {};
    return (
      <div className={`fr-notice fr-notice--${type}`} key={id}>
        <div className="fr-container">
          <div className="fr-notice__body">
            <p>
              <span className="fr-notice__title">{title}</span>
              {text && <span className="fr-notice__desc">{text}</span>}
              <a
                target="_blank"
                rel="noopener noreferrer"
                title={`${linkLabel} - nouvelle fenêtre`}
                href={link}
                className="fr-notice__link"
              >
                {linkLabel}
              </a>
            </p>
            <button
              type="button"
              title="Masquer le message"
              onClick={() => handleHideBanner(id)}
              id="hide-button"
              className="fr-btn--close fr-btn"
            >
              Masquer le message
            </button>
          </div>
        </div>
      </div>
    );
  });
};

export default Banner;
