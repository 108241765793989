import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const quoteStyled = {
  "& blockquote": { width: "100%" },
  "& figcaption": {
    mt: 2,
  },
  "& > img": { mt: 1 },
};

const Quote = (props) => {
  const { icon = {}, image = null, quote, author = "", link = null, quoteType } = props;
  const { url: linkUrl, page: linkPage, external, title } = link || {};
  const { url: imageUrl, alt: imageAlt } = image || {};

  const { language } = useContext(LanguageContext);

  let linkText;
  switch (quoteType) {
    case "basic":
      linkText = t[language].components.article_link;
      break;
    case "tweet":
      linkText = t[language].components.tweet_link;
      break;
    default:
      linkText = "";
  }

  return (
    <Box
      component="figure"
      className="fr-quote fr-quote--column"
      sx={{ ml: !imageUrl || quoteType === "tweet" ? "0px !important" : "", ...quoteStyled }}
    >
      {(icon?.icon || icon?.iconDSFR) && (
        <Icon
          {...icon}
          sx={{
            color: "#6A6AF4",
            fontSize: "2rem",
            "&::before": {
              "--icon-size": "2rem",
            },
            mb: 1.25,
          }}
        />
      )}
      <blockquote cite={linkUrl}>{quote}</blockquote>
      {quoteType === "tweet" && <img src={imageUrl} className="fr-responsive-img" alt={imageAlt} />}
      <figcaption>
        <Grid
          container
          direction="column"
          className="fr-quote__author"
          sx={{ ml: { xs: !imageUrl || quoteType === "tweet" ? "0px !important" : "", lg: "" } }}
        >
          {author}
          {(linkUrl || linkPage) && (
            <Grid item xs>
              <Link
                url={linkUrl}
                page={linkPage}
                external={external}
                iconPosition="right"
                icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
                linkComponent
              >
                {title || linkText}
              </Link>
            </Grid>
          )}
        </Grid>
        {imageUrl && quoteType === "basic" && (
          <div className="fr-quote__image">
            <img src={imageUrl} className="fr-responsive-img" alt={imageAlt} />
          </div>
        )}
      </figcaption>
    </Box>
  );
};

Quote.propTypes = {
  icon: PropTypes.shape(),
  image: PropTypes.shape(),
  quote: PropTypes.shape(),
  author: PropTypes.string,
  link: PropTypes.shape(),
  quoteType: PropTypes.oneOf(["basic", "tweet"]).isRequired,
};

export default Quote;
