import React from "react";

const CardDsfrContext = React.createContext({
  isHorizontal: false,
  hasGreyBackground: false,
  showImage: false,
  showBadge: false,
  showDetail: false,
});
export default CardDsfrContext;
