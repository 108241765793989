import Wrapper from "components/LayoutFront/Wrapper";
import Decrees from "components/templates/who/Decrees";
import Governance from "components/templates/who/Governance";
import History from "components/templates/who/History";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import Tabs from "components/templatesComponents/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const tabsIdentifier = "t";

const Who = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { history, decrees, governance } = contents;

  const [sendTag, setSendTag] = useState(false);

  const { tab: historyTab, ...historyProps } = history;
  const { tab: decreesTab, ...decreesProps } = decrees;
  const { tab: governanceTab, ...governanceProps } = governance;

  const tabs = [
    {
      ...historyTab,
      identifier: tabsIdentifier,
      children: <History {...historyProps} />,
    },
    {
      ...decreesTab,
      identifier: tabsIdentifier,
      children: <Decrees {...decreesProps} />,
    },
    {
      ...governanceTab,
      identifier: tabsIdentifier,
      children: <Governance {...governanceProps} />,
    },
  ];
  return (
    <>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        <Block>{shortDescription}</Block>
      </Wrapper>
      <Block>
        <Tabs tabs={tabs} sendTag={sendTag} setSendTag={setSendTag} />
      </Block>
    </>
  );
};

Who.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.node,
    shortDescription: PropTypes.node,
    contents: PropTypes.shape(),
  }).isRequired,
};

export default Who;
