import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import LanguageContext from "components/LanguageContext";
import { componentClassName as buttonClassName } from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import { componentClassName as linkClassName } from "components/templatesComponents/Link";
import { componentClassName as textClassName } from "components/templatesComponents/Text";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

export const Step = (props) => {
  const { index = "", title = "", tooltip = "", icon = null, detail = "" } = props;
  const { language } = useContext(LanguageContext);

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          px: { xs: 1, lg: 2 },
          py: { xs: 2, lg: 3 },
          fontSize: { xs: "1.05rem", lg: "1.3rem" },
          backgroundColor: "white",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          component="span"
          sx={{
            height: { xs: "32px", lg: "48px" },
            width: { xs: "32px", lg: "48px" },
            bgcolor: "secondary.10",
            color: "secondary.main",
            borderRadius: "50%",
            fontSize: { lg: "30px" },
            lineHeight: { lg: "30px" },
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: { xs: "22px", lg: "30px" },
              lineHeight: { xs: "22px", lg: "30px" },
              mt: { xs: -0.25, lg: -0.375 },
            }}
          >
            {index}
          </Box>
        </Grid>
        <Box sx={{ display: "flex", ml: 2.5, flex: "1 1 0px", color: "componentColors.70" }}>
          {title}
          {!!tooltip && (
            <Tooltip
              title={
                <Box component="span" sx={{ fontSize: "0.74rem" }}>
                  {tooltip}
                </Box>
              }
              aria-label={t[language].components.tooltip_aria_label}
              placement="top"
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                component="span"
                sx={{
                  ml: 2,
                  fontSize: "0.8em",
                  width: "24px",
                  height: "24px",
                  bgcolor: "secondary.10",
                  color: "secondary.main",
                  borderRadius: "50%",
                }}
              >
                <Icon {...icon} title="" />
              </Grid>
            </Tooltip>
          )}
        </Box>
      </Grid>
      {detail && (
        <Box
          sx={(theme) => ({
            minHeight: 50,
            bgcolor: "secondary.10",
            [`& > .${textClassName}`]: {
              p: { xs: 1, lg: 2 },
            },
            [`& > .${buttonClassName}, & > .${linkClassName} > .${buttonClassName}`]: {
              width: "100%",
            },
            "& .MuiExpansionPanelSummary-expandIcon": {
              p: { xs: 2, lg: 2.5 },
              color: theme.palette.getContrastText(theme.palette.secondary[30]),
            },
            "& .MuiExpansionPanel-root.Mui-expanded": {
              m: 0,
            },
            "& .MuiExpansionPanelSummary-root": {
              bgcolor: "secondary.30",
              color: theme.palette.getContrastText(theme.palette.secondary[30]),
            },
          })}
        >
          {detail}
        </Box>
      )}
    </Box>
  );
};

Step.propTypes = {
  index: PropTypes.number,
  icon: PropTypes.shape(),
  title: PropTypes.string,
  tooltip: PropTypes.string,
  detail: PropTypes.node,
};

const Stepper = (props) => {
  let { steps = [] } = props;
  if (steps && !Array.isArray(steps)) {
    steps = [steps];
  }

  return steps.map((step, index) => (
    <li key={step.props.id}>
      <step.type {...step.props} index={index + 1} />
      {index + 1 !== steps.length && (
        <Box
          sx={{ height: "50px", borderLeft: "2px dashed", borderColor: "secondary.light", ml: { xs: 3.125, lg: 5 } }}
        />
      )}
    </li>
  ));
};

Stepper.propTypes = {
  steps: PropTypes.node,
};

export default Stepper;
