import HomeMairie from "components/templates/homeMairie/HomeMairie";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HOME_MAIRIE",
  label: "Accueil Mairie",
  Component: HomeMairie,
  initialContents: [
    {
      key: "contentTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "subtitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },

    {
      key: "cardCNI",
      value: "",
      type: elementsTypes.CARD,
      children: [
        {
          key: "title",
          value: "Carte d'identité et Passeport (DR)",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "https://www.immatriculation.ants.gouv.fr/",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
      ],
    },
    {
      key: "cardPM",
      value: "",
      type: elementsTypes.CARD,
      children: [
        {
          key: "title",
          value: "Police municipale",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "https://www.permisdeconduire.ants.gouv.fr/",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
      ],
    },
    {
      key: "cardComedec",
      value: "",
      type: elementsTypes.CARD,
      children: [
        {
          key: "title",
          value: "COMEDEC",
        },
        {
          key: "link",
          type: elementsTypes.LINK,
          value: "",
          children: [
            {
              key: "url",
              type: elementsTypes.URL,
              value: "https://www.passeport.ants.gouv.fr/",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
      ],
    },
    {
      key: "Immatriculer",
      type: elementsTypes.SUBPAGES_LIST,
      value: "",
      children: [],
    },
  ],
};
