import PropTypes from "prop-types";
import React from "react";
import t from "utils/locales/translation.json";

const PnuBanner = (props) => {
  const { language, setDisplayBanner } = props;

  return (
    <div className="fr-alert fr-alert--warning fr-icon-translate-2" tabIndex="0">
      <b className="fr-text--md" lang={language}>
        {t[language].components.message}
      </b>
      <button
        className="fr-link--close fr-link"
        type="button"
        title={t[language].components.close_banner_icon_title}
        onClick={() => setDisplayBanner(false)}
      >
        {t[language].components.close_banner_icon_title}
      </button>
    </div>
  );
};

PnuBanner.propTypes = {
  language: PropTypes.string.isRequired,
  setDisplayBanner: PropTypes.func.isRequired,
};

export default PnuBanner;
