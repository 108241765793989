import Grid from "@mui/material/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React from "react";

const Standard = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { infoBlocks, dynamicPart } = contents;

  return (
    <Wrapper>
      <Grid container spacing={{ xs: 0, lg: 2 }}>
        <Grid item xs>
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
        </Grid>
        {infoBlocks && (
          <Grid item lg={6} xs={12}>
            <Block>{infoBlocks}</Block>
          </Grid>
        )}
      </Grid>
      {shortDescription && <Block>{shortDescription}</Block>}
      {dynamicPart}
    </Wrapper>
  );
};

Standard.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Standard;
