import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import Wrapper from "components/LayoutFront/Wrapper";
import PageVersionContext from "components/PageVersionContext";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import EulerianService from "services/EulerianService";
import { createQueryParams, getQueryParams } from "utils/urlUtils";
import wrappable from "utils/wrappable";

const TabPanel = wrappable((props) => {
  const { children = null, index, hidden, ...others } = props;

  return (
    <Box
      role="tabpanel"
      tabIndex="0"
      hidden={hidden}
      {...others}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {children}
    </Box>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const ScrollableTabs = wrappable((props) => {
  const {
    location,
    history,
    tabs = [],
    tabProps = {},
    tabsProps = {},
    indicatorColor = "secondary",
    defaultTabKey = null,
    identifier = "t",
    variant = "standard",
    scrollButtons = "auto",
    panelClass = null,
    navigate = true,
    sendTag = true,
    setSendTag = null,
  } = props;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  const getTabKey = useCallback((tab = {}) => tab.url || `${tabs.indexOf(tab)}`, [tabs]);

  const getCurrentTab = useCallback(() => {
    if (navigate) {
      const key = getQueryParams(location.search)[identifier];
      if (!tabs.find((tab) => getTabKey(tab) === key)) {
        if (defaultTabKey && tabs.find((tab) => getTabKey(tab) === defaultTabKey)) {
          return defaultTabKey;
        }
        return getTabKey(tabs[0]);
      }
      return key;
    }
    return getTabKey(tabs[0]);
  }, [location.search, getTabKey, defaultTabKey, identifier, tabs, navigate]);

  const [currentTab, setCurrentTab] = useState(getCurrentTab());

  useEffect(() => {
    if (navigate) {
      setCurrentTab(getCurrentTab());
    }
  }, [getCurrentTab, navigate]);

  useEffect(() => {
    if (currentTab && breadcrumb) {
      if (sendTag) {
        const tabActive = tabs.find((tab) => currentTab === getTabKey(tab));
        const pageTag = {
          page_name: tabActive?.title,
        };
        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0) {
            pageTag[`page_category${Number(key) + 1}`] = breadcrumb[key]?.title;
          }
        });
        EulerianService.sendPage(pageTag);
      } else {
        setSendTag(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleChange = (event, newTabKey) => {
    if (navigate) {
      const queryParams = getQueryParams(location.search);
      history.push({
        pathname: location.pathname,
        search: `?${createQueryParams({
          ...queryParams,
          [identifier]: newTabKey,
        })}`,
        state: { prevPath: location.pathname + location.search },
      });
    } else {
      setCurrentTab(newTabKey);
    }
  };

  if (typeof document !== "undefined") {
    const tabsScrollable = document.getElementsByClassName("MuiTabs-scrollable");
    if (tabsScrollable) {
      for (let i = 0; i < tabsScrollable.length; i += 1) {
        if (tabsScrollable[i] && tabsScrollable[i].classList && tabsScrollable[i].classList.value) {
          tabsScrollable[i].classList.value = tabsScrollable[i].classList.value.replace(
            "MuiTabs-scrollable",
            "customTabsScrollable"
          );
        }
      }
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        "& .customTabsScrollable": {
          overflowX: "auto",
        },
      }}
    >
      <Wrapper>
        <Box sx={scrollButtons === "on" ? { mt: 4, mx: -5, mb: 0 } : {}}>
          <Tabs
            role="tablist"
            value={currentTab}
            onChange={handleChange}
            variant={variant}
            indicatorColor={indicatorColor}
            selectionFollowsFocus
            scrollButtons={isMobile ? "auto" : scrollButtons}
            {...tabsProps}
          >
            {tabs.map((tab) => (
              <Tab
                role="tab"
                key={getTabKey(tab)}
                label={tab.title}
                value={getTabKey(tab)}
                disabled={tab.disabled}
                {...tabProps}
                {...a11yProps(getTabKey(tab))}
              />
            ))}
          </Tabs>
        </Box>
      </Wrapper>
      {tabs.map((tab) => {
        const { url, ...othersTabProps } = tab;
        return (
          <TabPanel
            {...othersTabProps}
            key={getTabKey(tab)}
            index={getTabKey(tab)}
            hidden={currentTab !== getTabKey(tab)}
            sx={panelClass || { mt: { lg: 4 } }}
          >
            {tab.children}
          </TabPanel>
        );
      })}
    </Box>
  );
});

ScrollableTabs.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      children: PropTypes.node,
    })
  ),
  scrollButtons: PropTypes.string,
  indicatorColor: PropTypes.string,
  defaultTabKey: PropTypes.string,
  panelClass: PropTypes.shape(),
  identifier: PropTypes.string,
  variant: PropTypes.string,
  navigate: PropTypes.bool,
  sendTag: PropTypes.bool,
  setSendTag: PropTypes.func,
  homeTabs: PropTypes.bool,
};

export default withRouter(ScrollableTabs);
