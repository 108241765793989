import { sanitizeUrl } from "@braintree/sanitize-url";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import CanSendTagContext from "components/CanSendTagContext";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { forwardRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import EulerianService from "services/EulerianService";
import languages from "utils/languagesTypes";

export const componentClassName = "Da-Link";

const internalNavigationUrls = (process.env.internal_navigation_urls || "").split(",");

const RouterLink = forwardRef((props, ref) => <HashLink innerRef={ref} {...props} />);

const Link = forwardRef((props, ref) => {
  const {
    url = "",
    page = null,
    external = false,
    title = "",
    iconPosition = "right",
    size = "md",
    icon = {},
    button = false,
    linkComponent = false,
    disabled = false,
    children = null,
    onClick = () => null,
    className = "",
    sx = {},
    ...others
  } = props;

  const { icon: fontIcon, iconDSFR } = icon;

  const displayFontIcon = !external && fontIcon && !iconDSFR;

  const { language } = useContext(LanguageContext);
  const canSendTag = useContext(CanSendTagContext);
  const { setCurrentPageVersion } = useContext(PageVersionContext);
  const { pathname, search } = useLocation();

  if (!url && !page && !linkComponent) {
    return (
      <Box
        component="a"
        onClick={(e) => e.preventDefault()}
        className={`${componentClassName} ${className}`}
        sx={sx}
        {...others}
      >
        {children}
      </Box>
    );
  }

  const sendTag = () => {
    if (canSendTag) {
      let name = title || (page && page.title) || (page && page.tab && page.tab.title) || children;

      if (Array.isArray(name)) {
        name.forEach((element) => {
          if (typeof element === "string") {
            name = element;
          }
        });
      }

      if (typeof name === "string") {
        EulerianService.sendClick(
          button
            ? { button: [`button::click::${name.toLowerCase()}`] }
            : { link: [`link::click::${name.toLowerCase()}`] }
        );
      }
    }
  };

  const handleClickLink = (e) => {
    if (!disabled) {
      sendTag();
      if (typeof onClick === "function") {
        onClick(e);
      }
      if (page && !external) {
        setCurrentPageVersion(page);
      }
    }
  };

  const dynamicProps = {
    component: RouterLink,
    target: external ? "_blank" : "_self",
    rel: external ? "noopener noreferrer" : undefined,
  };

  const getProtocol = (oldUrl) =>
    oldUrl && (oldUrl.match(/^(http|https):\/\//g) || oldUrl.startsWith("/")) ? oldUrl : `https://`.concat(oldUrl);
  const path = page && page.fullPath !== undefined ? page.fullPath || "/" : sanitizeUrl(getProtocol(url));
  if (external || !path.startsWith("/")) {
    dynamicProps.href =
      internalNavigationUrls.some((str) => url?.indexOf(str) !== -1) && !url?.includes("lang=")
        ? `${path}?lang=${languages[language]?.lang}`
        : path;
    dynamicProps.component = "a";
  } else {
    const [nextPathname, ...params] = path.split(/(\?|#)/i);
    let nextSearch = "";
    let nextHash = "";
    if (params && params.length > 1) {
      if (params.indexOf("?") !== -1) {
        nextSearch = `?${params[params.indexOf("?") + 1]}`;
      }
      if (params.indexOf("#") !== -1) {
        nextHash = `#${params[params.indexOf("#") + 1]}`;
      }
    }
    dynamicProps.to = {
      pathname: nextPathname,
      search: nextSearch,
      hash: nextHash,
      state: { prevPath: pathname + search },
    };
  }

  if (disabled) {
    dynamicProps.href = null;
  }
  const titleLink = title || (page ? page.title : null);

  // W3C: remove attribute "to" in span component
  if (typeof document !== "undefined") {
    const seeMoreLink = document.getElementById("spanLink");
    if (seeMoreLink) {
      seeMoreLink.removeAttribute("to");
    }
  }

  return (
    <MuiLink
      ref={ref}
      underline="none"
      variant="none"
      onClick={handleClickLink}
      color={linkComponent && !disabled ? "" : "textPrimary"}
      title={titleLink}
      {...dynamicProps}
      className={`${button ? "" : "fr-link "}fr-link--${size}${
        iconDSFR && iconPosition ? ` fr-link--icon-${iconPosition}` : ""
      }${!external && iconDSFR ? ` fr-icon-${iconDSFR}` : ""}${
        linkComponent ? "" : " fr-link-after"
      } ${className} ${componentClassName}`}
      sx={{
        ...(linkComponent
          ? {}
          : {
              "a&[href]": {
                backgroundImage: "none",
              },
            }),
        cursor: disabled ? "inherit" : "pointer",
        ...sx,
      }}
      {...others}
    >
      {displayFontIcon && iconPosition === "left" && <Icon icon={fontIcon} sx={{ mr: 1 }} />}
      {children}
      {displayFontIcon && iconPosition === "right" && <Icon icon={fontIcon} sx={{ ml: 1 }} />}
    </MuiLink>
  );
});

Link.propTypes = {
  url: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.shape(),
  button: PropTypes.bool,
  linkComponent: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  sx: PropTypes.shape(),
};

export default Link;
