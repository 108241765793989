import Box from "@mui/material/Box";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React from "react";

const InfoBlock = (props) => {
  const { icon = {}, title = "", text = null, link = {} } = props;
  const { icon: fontIcon, iconDSFR } = icon;
  const { title: titleLink, page, url } = link;

  return (
    <Box
      className={`fr-callout ${iconDSFR ? `fr-icon-${iconDSFR}` : ""}`}
      sx={{
        boxShadow: "inset .25rem 0 0 0 #6A6AF4",
        bgcolor: "#EEEEEE",
      }}
    >
      {fontIcon && !iconDSFR && (
        <Icon
          icon={fontIcon}
          sx={{
            "&::before ": {
              fontSize: "1.5rem",
              position: "absolute",
              top: 15,
              left: { xs: 20, lg: 15 },
            },
          }}
        />
      )}
      <h3 className="fr-callout__title">{title}</h3>
      <div className="fr-callout__text">{text}</div>
      {(url || page?.path) && (
        <Button component={Link} {...link} linkComponent sx={{ color: "#FFFFFF", bgcolor: "#000091" }}>
          {titleLink}
        </Button>
      )}
    </Box>
  );
};

InfoBlock.propTypes = {
  icon: PropTypes.shape(),
  title: PropTypes.string,
  text: PropTypes.node,
  link: PropTypes.shape(),
};

export default InfoBlock;
