import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import LanguageContext from "components/LanguageContext";
import MessageContext from "components/MessageContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { debounce } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const inputStyled = {
  backgroundColor: "white",
  border: "1px solid #bbb",
  height: 56,
  py: 1,
  pl: 2,
  pr: 0.5,
  fontSize: "0.875rem",
  "&.Mui-focused": {
    borderColor: "primary.main",
  },
  "& input": {
    height: "1.4rem",
  },
  "& i": {
    color: "secondary.main",
  },
};

const listCityStyled = {
  top: 56,
  position: "absolute",
  border: "1px solid",
  borderColor: "componentColors.20",
  bgcolor: "#fff",
  minWidth: { xs: 268, lg: 398 },
  maxHeight: 250,
  zIndex: 100,
  // Correctif pour safari :
  WebkitTransform: "translate3d(0,0,0)",
  transform: "translate3d(0,0,0)",
  m: 0,
  p: 0,
  overflowX: "hidden",
  overflowY: "auto",
  listStyle: "none",
  "& div": {
    display: "flex",
    justifyContent: "space-between",
    p: 2,
    cursor: "pointer",
  },
  "& div:hover": {
    bgcolor: "componentColors.20",
  },
};

const SearchBar = (props) => {
  const { setPosition, setCityPosition, inputValue = "", setInputValue, setGeoPoints, setBeforeSearch } = props;

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const [results, setResults] = useState({});
  const [cursor, setCursor] = useState(0);
  const [refList, setRefList] = useState({});
  const [load, setLoad] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const { features = [] } = results || {};
  const { geometry = {}, properties = {} } = features[cursor] || {};
  const { coordinates = [] } = geometry || {};
  const { nom = "" } = properties || {};

  const getInfo = (value, searchType) => {
    axios
      .get(`https://geo.api.gouv.fr/communes?${searchType}=${value}&fields=departement&boost=population&format=geojson`)
      .then(({ data }) => {
        setLoad(false);
        setResults(data);
      })
      .catch(() => {
        displayError(t[language].common.search.error_api_message);
        setResults([]);
      });
  };

  const handleClickPosition = (lat, long, city) => {
    setBeforeSearch(false);
    setPosition([lat, long]);
    setCityPosition(city);
    setGeoPoints([]);
    setResults([]);
    if (/^[A-zÀ-ú\-\s]*$/.test(inputValue)) {
      setInputValue(city);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    setCursor(0);
    if (value && value.length > 1) {
      setLoad(true);
      setResults([]);
      // Mettre en place debounce
      if (/^\d+$/.test(value?.trim())) {
        debounce(getInfo, 350)(value?.trim(), "codePostal");
      } else if (/^[A-zÀ-ú\-\s]*$/.test(value)) {
        debounce(getInfo, 350)(value, "nom");
      } else {
        setLoad(false);
      }
    } else {
      setResults([]);
    }
  };

  useEffect(() => {
    const refs = {};
    if (features?.length > 0) {
      features.forEach((item, i) => {
        refs[i] = React.createRef();
      });
      setRefList(refs);
    }
  }, [features]);

  const handleKey = (e) => {
    const ref = refList[cursor] && refList[cursor].current;
    setMouseOver(false);
    if (e.keyCode === 38 /* Arrow-up */ && cursor > 0) {
      setCursor((prevState) => prevState - 1);
      scrollIntoView(ref, {
        scrollMode: "if-needed",
        block: "end",
        inline: "nearest",
      });
    } else if (e.keyCode === 40 /* Arrow-down */ && cursor < features.length - 1) {
      setCursor((prevState) => prevState + 1);
      scrollIntoView(ref, {
        scrollMode: "if-needed",
        block: "start",
        inline: "nearest",
      });
    } else if (e.keyCode === 13 && coordinates.length >= 2) {
      /* Enter */ handleClickPosition(coordinates[1], coordinates[0], nom);
    }
  };

  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };

  const handleClickIcon = () => {
    if (coordinates.length >= 2) {
      handleClickPosition(coordinates[1], coordinates[0], nom);
    }
  };

  return (
    <Toolbar sx={{ minHeight: 56, width: { xs: 270, lg: 400 }, p: 0, display: "block" }} disableGutters>
      <Input
        onKeyDown={handleKey}
        role="combobox"
        placeholder={t[language].geolocalisation.placeholder}
        fullWidth
        inputProps={{
          "aria-label": t[language].geolocalisation.title,
          title: t[language].geolocalisation.title,
        }}
        sx={inputStyled}
        disableUnderline
        value={inputValue}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <Button
              onClick={handleClickIcon}
              aria-label={load ? t[language].common.search.loading : t[language].common.search.button_title}
              title={t[language].common.search.button_title}
              sx={{
                height: 56,
                mr: -0.625,
                width: { lg: 150 },
                "& > span": {
                  "& > p": {
                    mr: 1,
                  },
                },
              }}
            >
              {load ? (
                <CircularProgress sx={{ color: "componentColors.70" }} size={21} />
              ) : (
                <>
                  <Box component="p" sx={{ display: { xs: "none", lg: "block" } }}>
                    {t[language].common.search.placeholder}
                  </Box>
                  <Icon icon="search" iconDSFR="search-line" title={t[language].common.search.placeholder} />
                </>
              )}
            </Button>
          </InputAdornment>
        }
      />

      {results && features && (
        <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOver} sx={listCityStyled}>
          {features.map((item, i) => (
            <React.Fragment key={item.properties._score}>
              {item.geometry && (
                <Box
                  ref={refList[i]}
                  onClick={() =>
                    handleClickPosition(item.geometry.coordinates[1], item.geometry.coordinates[0], item.properties.nom)
                  }
                  sx={!mouseOver && cursor === i ? { bgcolor: "componentColors.20" } : {}}
                >
                  {item.properties.nom}
                  {item.properties.departement && (
                    <Box component="span" sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                      {" "}
                      {item.properties.departement.nom}
                    </Box>
                  )}
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Toolbar>
  );
};

SearchBar.propTypes = {
  setPosition: PropTypes.func.isRequired,
  setBeforeSearch: PropTypes.func.isRequired,
  setCityPosition: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  setGeoPoints: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
};

export default SearchBar;
