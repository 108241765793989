import Box from "@mui/material/Box";
import { queryParamPage, queryParamSite } from "components/adminComponents/AdminContext";
import JsonLd from "components/JsonLd";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import SiteContext from "components/SiteContext";
import templates from "components/templates/templates";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import EulerianService from "services/EulerianService";
import t from "utils/locales/translation.json";
import { createQueryParams } from "utils/urlUtils";

const BreadcrumbItem = (props) => {
  const { item, siteId, bo = false, ...others } = props;

  const convertFullPath = `/?${createQueryParams({
    [queryParamSite]: siteId,
    [queryParamPage]: item.pageId,
  })}`;

  return (
    <li title={item.title}>
      <Link
        linkComponent
        size="xs"
        className="fr-breadcrumb__link"
        title={item.title}
        url={bo ? convertFullPath : item.fullPath || "/"}
        sx={{ color: "inherit" }}
        {...others}
      >
        {item.title}
      </Link>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    fullPath: PropTypes.string,
    pageId: PropTypes.string,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  bo: PropTypes.bool,
};

const Breadcrumb = (props) => {
  const { bo = false } = props;
  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName, id: siteId } = site;

  const [displayBreadcrumb, setDisplayBreadcrumb] = useState(false);

  useEffect(() => {
    if (currentPageVersion) {
      const templateDefinition = templates.find((template) => template.key === currentPageVersion.template);

      if (breadcrumb && templateDefinition) {
        const pageTag = {
          page_name: breadcrumb.length > 1 ? breadcrumb[breadcrumb.length - 1].title : "accueil",
        };

        if (breadcrumb.length === 1) {
          pageTag.page_category2 = "accueil";
        }

        Object.keys(breadcrumb).forEach((key) => {
          if (key > 0 && key < breadcrumb.length - 1) {
            pageTag[`page_category${Number(key) + 1}`] = breadcrumb[key].title;
          }
        });

        if (templateDefinition.servesOtherPages || breadcrumb.length <= 2) {
          pageTag.page_name = "accueil";
          pageTag[`page_category${breadcrumb.length}`] = breadcrumb[breadcrumb.length - 1].title;
        } else if (templateDefinition.containsOnlyOnePage && breadcrumb.length > 2) {
          pageTag.page_name = breadcrumb[breadcrumb.length - 1].title;
          pageTag[`page_category${breadcrumb.length}`] = pageTag.page_name;
        }

        EulerianService.sendPage(pageTag, siteName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageVersion && currentPageVersion.id]);

  const structuredData = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [${
      breadcrumb &&
      breadcrumb.reduce(
        (str, breadcrumbItem, index) =>
          `${str}
        {
          "@type": "ListItem",
          "position": ${index + 1},
          "name": "${breadcrumbItem.title}",
          "item": "${site.host}${breadcrumbItem.fullPath || "/"}"
        }${breadcrumb.length !== index + 1 ? "," : ""}`,
        ""
      )
    }
    ]
  }`;

  return (
    breadcrumb &&
    breadcrumb.length > 1 && (
      <>
        <nav role="navigation" className="fr-breadcrumb" aria-label={t[language].components.breadcrumb}>
          <Box
            component="button"
            type="button"
            className="fr-breadcrumb__button"
            sx={{ display: { xs: !displayBreadcrumb ? "inline-block" : "none", md: "none" } }}
            onClick={() => setDisplayBreadcrumb(true)}
          >
            Voir le fil d’Ariane
          </Box>
          <Box
            component="ol"
            className="fr-breadcrumb__list"
            sx={{ display: { xs: displayBreadcrumb ? "block" : "none", md: "block" } }}
          >
            {breadcrumb.map((breadcrumbItem, index) =>
              index !== breadcrumb.length - 1 ? (
                <BreadcrumbItem key={breadcrumbItem.pageId} item={breadcrumbItem} siteId={siteId} bo={bo} />
              ) : (
                <li key={breadcrumbItem.pageId}>
                  <a title={breadcrumbItem.title} className="fr-breadcrumb__link" aria-current="page">
                    {breadcrumbItem.title}
                  </a>
                </li>
              )
            )}
          </Box>
        </nav>
        <JsonLd structuredData={structuredData} />
      </>
    )
  );
};

Breadcrumb.propTypes = {
  bo: PropTypes.bool,
};

export default Breadcrumb;
