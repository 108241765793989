import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import Share from "components/LayoutFront/Share";
import Wrapper from "components/LayoutFront/Wrapper";
import SiteContext from "components/SiteContext";
import allNews from "components/templates/news/allNews";
import Block from "components/templatesComponents/Block";
import CardVertical from "components/templatesComponents/CardVertical";
import { ImageContainer } from "components/templatesComponents/Image";
import TagsComponent from "components/templatesComponents/Tags";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router";
import PageService from "services/PageService";
import { numericFormatDate } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const Tags = (props) => {
  const { allNewsPages = [], parentId, tags = [] } = props;
  const history = useHistory();

  const goToAllNewsWithTag = (tag) => {
    const allNewsPath = allNewsPages?.find((p) => p.pageId === parentId)?.fullPath || allNewsPages[0]?.fullPath;

    if (allNewsPages) {
      const defaultSelectedTag = tag;

      history.push(allNewsPath, {
        defaultSelectedTag,
      });
    }
  };

  return (
    tags && (
      <Block>
        <TagsComponent tags={tags} withDSFR tagLink handleSelectTags={goToAllNewsWithTag} />
      </Block>
    )
  );
};

Tags.propTypes = {
  allNewsPages: PropTypes.arrayOf(PropTypes.shape()),
  parentId: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

const NewsTemplate = (props) => {
  const { page: pageVersion = {} } = props;

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { name: siteName } = site;

  const { id, title, shortDescription, image, contents, tags, publishedDate, pageId, page } = pageVersion;
  const { firstPublishedDate, parentId, lang, originalPageId, siteId } = page;
  const { watchTime, ratio, imageCaption, dynamicPart, dynamicAttachments } = contents;

  const [{ data: allNewsData = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [allNews.key],
      siteName,
      filters: [`page.lang||eq||${lang}`],
    })
  );

  const [{ data = [] }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: ["NEWS"],
      siteName,
      filters: [`id||ne||${id}`, `page.lang||eq||${lang}`],
    })
  );

  const [{ data: frenchPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: lang === "FR" ? pageId : originalPageId,
      onlyPublishedPages: true,
      filters: [`page.lang||eq||FR`],
    })
  );

  const getPublishedDate = useMemo(() => {
    if (frenchPnuPages.length === 1) {
      const { publishedDate: frenchPublishDate } = frenchPnuPages[0];
      return frenchPublishDate;
    }
    return publishedDate;
  }, [frenchPnuPages, publishedDate]);

  const getFirstPublishedDate = useMemo(() => {
    if (frenchPnuPages.length === 1) {
      const { page: frenchPage = {} } = frenchPnuPages[0];
      return frenchPage.firstPublishedDate;
    }
    return page.firstPublishedDate;
  }, [frenchPnuPages, page]);

  const publishedDateToDisplay = lang === "FR" ? publishedDate : getPublishedDate;
  const firstPublishedDateToDisplay = lang === "FR" ? firstPublishedDate : getFirstPublishedDate;

  const allNewsPages = allNewsData?.pages || allNewsData;

  const newsPages = data?.pages || data;

  const sameThemePages = React.useMemo(() => {
    const newPages = newsPages.map((p) => {
      const tagNumber =
        p.tags?.reduce((a, b) => (tags?.map((tag) => tag.toLowerCase())?.includes(b.toLowerCase()) ? a + 1 : a), 0) ||
        0;
      return { ...p, tagNumber };
    });
    return newPages
      .sort((newPage1, newPage2) => newPage2.tagNumber - newPage1.tagNumber)
      .filter((stp) => stp.tagNumber > 0)
      .slice(0, 3);
  }, [newsPages, tags]);

  return (
    <Wrapper breakpoint="xl" wrapperProps={{ sx: { position: "relative", pb: 8, maxWidth: "1440px", mx: "auto" } }}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={7}>
          <Box mx={{ xs: 2, lg: 0 }} mb={2}>
            <h1>{title}</h1>
          </Box>
          {shortDescription && (
            <Box mx={{ xs: 2, lg: 0 }} mb={3}>
              {shortDescription}
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={7}>
          <Box component="hr" mx={{ xs: 2, lg: 0 }} />
          <Grid container justifyContent="space-between" sx={{ px: { xs: 2, lg: 0 }, pt: { xs: 1, lg: 2 }, pb: 2 }}>
            <Grid item xs={10} sm={8} md={10} lg={7} xl={8} container alignItems="center">
              <Grid item>
                {publishedDateToDisplay && (
                  <p className="fr-text--xs" role="presentation">
                    <Box component="span" className="fr-icon-calendar-event-line" aria-hidden="true" />
                    {` `}
                    {t[language].common.news.updated_label} {numericFormatDate({ date: publishedDateToDisplay, lang })}
                  </p>
                )}
              </Grid>
              {firstPublishedDateToDisplay && (
                <Grid item sx={{ ml: 3.25 }}>
                  <p className="fr-text--xs" role="presentation">
                    {t[language].news.first_published} {numericFormatDate({ date: firstPublishedDateToDisplay, lang })}
                  </p>
                </Grid>
              )}
            </Grid>
            {watchTime && (
              <Grid item xs="auto">
                <p className="fr-text--xs" role="presentation">
                  <Box component="span" className="fr-icon-timer-line" aria-hidden="true" />
                  {` `}
                  {t[language].news.read_label} : {watchTime}
                </p>
              </Grid>
            )}
          </Grid>
          {image && (
            <>
              {typeof ratio?.props?.content === "object" && (
                <Grid container justifyContent="center" spacing={2} sx={{ mb: 1 }}>
                  <Grid item>Ratio d&apos;image :</Grid>
                  <Grid item xs>
                    {ratio}
                  </Grid>
                </Grid>
              )}
              <ImageContainer
                ratio={
                  (typeof ratio?.props?.content === "object" ? Number(ratio.props?.content.value) : Number(ratio)) || 52
                }
              >
                {image}
              </ImageContainer>
              {imageCaption && (
                <Box component="figcaption" className="fr-content-media__caption" mx={{ xs: 2, lg: 0 }}>
                  {imageCaption}
                </Box>
              )}
            </>
          )}
          {dynamicPart && <Box mt={{ xs: 4, lg: 5 }}>{dynamicPart}</Box>}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ position: "sticky", top: 32, mx: { xs: 2, lg: 0 }, mt: { xs: 8, lg: 0 } }}>
            {dynamicAttachments && (
              <Box>
                <Box component="p" className="fr-text--bold" mb={2}>
                  {t[language].news.pj_title}
                </Box>
                {dynamicAttachments}
              </Box>
            )}
            <Share forceDisplay withTitle />
          </Box>
        </Grid>
      </Grid>
      {tags && (
        <>
          <Box component="h3" className="fr-h5" mt={7.5} sx={{ mb: 0.5, px: { xs: 2, md: 0 } }}>
            {t[language].news.same_theme_title}
          </Box>
          <Tags allNewsPages={allNewsPages} parentId={parentId} tags={tags} />
          <Block>
            {sameThemePages.length > 0 ? (
              <Grid container spacing={2}>
                {sameThemePages.map((p) => (
                  <Grid item xs={12} md={4} key={p.id}>
                    <CardVertical page={p} showImage={false} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box component="p" role="presentation" sx={{ textAlign: "center", fontStyle: "italic" }}>
                {t[language].news.no_article_message}
              </Box>
            )}
          </Block>
        </>
      )}
    </Wrapper>
  );
};

NewsTemplate.propTypes = {
  page: PropTypes.shape(),
};

export default NewsTemplate;
