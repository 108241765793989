import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import MessageContext from "components/MessageContext";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";
import GeolocMapHeader from "components/templates/geolocalisation/GeolocMapHeader";
import GeolocMobileTabs from "components/templates/geolocalisation/GeolocMobileTabs";
import InfoGeoloc from "components/templates/geolocalisation/InfoGeoloc";
import SearchBar from "components/templates/geolocalisation/SearchBar";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import Icon from "components/templatesComponents/Icon";
import { ImageContainer } from "components/templatesComponents/Image";
import PageTitle from "components/templatesComponents/PageTitle";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import PropTypes from "prop-types";
import React, { createRef, useContext, useEffect, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const geoContainerStyled = {
  border: "1px solid",
  borderColor: "secondary.main",
  maxHeight: { xs: 600, lg: 498 },
  overflowY: "auto",
  /* width */
  "& ::-webkit-scrollbar": {
    width: 8,
  },
  /* Track */
  "& ::-webkit-scrollbar-track": {
    borderRadius: 10,
  },
  /* Handle */
  "& ::-webkit-scrollbar-thumb": {
    background: "#b3b3b3",
    borderRadius: 8,
  },
  /* Handle on hover */
  "& ::-webkit-scrollbar-thumb:hover": {
    background: "#939393",
  },
};

const Geolocalisation = (props) => {
  const { page } = props;

  const { title, image, shortDescription, contents } = page;

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const { description } = contents;
  let { source } = contents;
  let editSourceComponent = null;
  if (React.isValidElement(source)) {
    editSourceComponent = source;
    source = source.props.content?.value;
  }

  const radius1 = process.env.geoloc_radius_1;
  const radius2 = process.env.geoloc_radius_2;

  const [mapComponents, setMapComponents] = useState({});
  const [position, setPosition] = useState([46.7667, 2.45]); // Centre France metroplitaine
  const [cityPosition, setCityPosition] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [geoPoints, setGeoPoints] = useState([]);
  const [centerOnClick, setCenterOnClick] = useState([]); // centre la carte sur le point séléctionné en mobile
  const [geoPointsRefs, setGeoPointsRefs] = useState({});
  const [beforeSearch, setBeforeSearch] = useState(true);
  const [geoPointsRefsText, setGeoPointsRefsText] = useState({});
  const [load, setLoad] = useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const getLocationIcon = (cat) => {
    if (window) {
      // eslint-disable-next-line global-require
      const L = require("leaflet");
      return L.icon({
        iconUrl: `/location-icon-${cat?.toLowerCase() === categories[source].specific ? "rouge" : "bleu"}.png`,
        shadowUrl: iconShadow,
        iconSize: [36, 36],
        iconAnchor: [16, 36],
        popupAnchor: [0, -40],
      });
    }
    return null;
  };

  useEffect(() => {
    if (window) {
      // eslint-disable-next-line global-require
      require("leaflet/dist/leaflet.css");
      // eslint-disable-next-line global-require
      require("./geo.css");
      // eslint-disable-next-line global-require
      setMapComponents(require("react-leaflet"));
    }
  }, []);

  useEffect(() => {
    const refs = {};
    const refsText = {};
    geoPoints.forEach((geoPointItem) => {
      refs[geoPointItem._source.customId] = createRef();
      refsText[geoPointItem._source.customId] = createRef();
    });
    setGeoPointsRefs(refs);
    setGeoPointsRefsText(refsText);
  }, [geoPoints]);

  const getInfo = (lat, long, size = 100) => {
    axios
      .post(`/geoloc`, {
        lat,
        long,
        source,
        distance: size === 100 ? radius1 : radius2,
        from: 0,
        size,
      })
      .then(({ data }) => {
        if (data?.hits?.total?.value <= size) {
          if (/^\d+$/.test(inputValue.trim())) {
            setGeoPoints(
              data?.hits?.hits?.filter((hit) => (hit?._source?.others?.postalCode || "").trim() === inputValue.trim())
            );
          } else {
            setGeoPoints(data?.hits?.hits || []);
          }
          setLoad(false);
        } else {
          getInfo(lat, long, data?.hits?.total?.value);
        }
      });
  };

  useEffect(() => {
    if (position && position.length > 0 && !beforeSearch) {
      setLoad(true);
      getInfo(position[0], position[1]);
    }
    setCenterOnClick([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, source]);

  const showLocation = (positionNav) => {
    const { latitude, longitude } = positionNav.coords;
    setBeforeSearch(false);
    setPosition([latitude, longitude]);
  };

  const errorHandler = (err) => {
    let message = "";
    switch (err.code) {
      case 1:
        message = "Vous n'avez pas accès à la géolocalisation";
        break;
      case 2:
        message = "Votre position est indisponible";
        break;
      case 3:
        message = "Le delai de la requête est dépassé";
        break;
      default:
        message = "Une erreur est survenue";
    }
    displayError(message);
  };

  // Obtenir la localisation depuis le naviagteur
  const getLocation = () => {
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      const options = { timeout: 60000 };
      navigator.geolocation.getCurrentPosition(showLocation, errorHandler, options);
      setGeoPoints([]);
      setCityPosition("votre position");
      setInputValue("");
    } else {
      // eslint-disable-next-line no-alert
      alert(t[language].geolocalisation.browser_alert);
    }
  };

  const mapRef = createRef();

  const handleMouseOverGeoPoint = (point) => () => {
    const ref = geoPointsRefs[point._source.customId] && geoPointsRefs[point._source.customId].current;
    if (ref && typeof ref.openPopup === "function") {
      ref.openPopup();
    }
  };

  const handleClickGeoPoint = (point) => {
    const ref = geoPointsRefs[point._source.customId] && geoPointsRefs[point._source.customId].current;
    const refText = geoPointsRefsText[point._source.customId] && geoPointsRefsText[point._source.customId].current;
    if (isMobile) {
      const [latitude, longitude] = (point._source.location && point._source.location.split(",")) || [];
      setActiveTab("1");
      setCenterOnClick([latitude, longitude]);
      if (document) {
        scrollIntoView(document.getElementById("mapAnchorStart"), {
          block: "nearest",
          inline: "nearest",
        });
      }
    }
    if (ref && typeof ref.openPopup === "function") {
      ref.openPopup();
    }
    if (!isMobile) {
      scrollIntoView(refText, {
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  const handleClickPopup = (point) => () => {
    const refText = geoPointsRefsText[point._source.customId].current;
    setActiveTab("2");
    setTimeout(() => {
      scrollIntoView(refText, {
        block: "nearest",
        inline: "nearest",
      });
    }, 0);
  };

  const handleMouseLeave = () => {
    Object.entries(geoPointsRefs).forEach((geoPointItem) => {
      geoPointItem[1].current.closePopup();
    });
  };

  const handlePopupClose = (point) => () => {
    const ref2 = geoPointsRefsText[point._source.customId].current;
    ref2.style.backgroundColor = "transparent";
  };

  geoPoints.forEach((geoPointItem) => {
    // eslint-disable-next-line no-param-reassign
    geoPointItem.opening = [];
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeMonday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeTuesday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeWednesday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeThursday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeFriday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeSaturday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeSunday);
  });

  const { MapContainer, Marker, Popup, TileLayer } = mapComponents;

  const invalidateMap = () => {
    setTimeout(() => {
      if (mapRef?.current) {
        mapRef.current.invalidateSize(false);
      }
      return null;
    }, 0);
  };

  useEffect(() => {
    if (mapRef?.current) {
      mapRef.current._container.removeAttribute("tabindex");
      if (!beforeSearch) {
        mapRef.current.flyTo({ lat: position[0], lng: position[1] }, 10.5);
      }
    }
  });

  return (
    <Wrapper wrapperProps={{ sx: { position: "relative", maxWidth: "1440px", m: "0 auto" } }}>
      <Grid container justifyContent="space-between">
        <Grid item lg={image ? 6 : 12}>
          {!!editSourceComponent && <Block>{editSourceComponent}</Block>}
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
          {shortDescription && <Block>{shortDescription}</Block>}
          {description && <Block>{description}</Block>}
        </Grid>
      </Grid>
      {image && (
        <Hidden lgDown>
          <Box sx={{ position: "absolute", top: 16, right: 0, width: "40%", display: { xs: "none", lg: "block" } }}>
            <ImageContainer ratio={48}>{image}</ImageContainer>
          </Box>
        </Hidden>
      )}

      <div id="searchAnchorStart" />
      <Block>
        <p className="fr-text--bold" role="presentation">
          {t[language].geolocalisation.input_search_label}
        </p>
        <Box component="p" className="fr-text--xs" role="presentation" mb={1.5}>
          {t[language].geolocalisation.input_search_sublabel}
        </Box>
        <Grid container>
          <SearchBar
            setPosition={setPosition}
            setCityPosition={setCityPosition}
            setGeoPoints={setGeoPoints}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setBeforeSearch={setBeforeSearch}
          />
          <Box
            component="button"
            type="button"
            className="fr-btn"
            aria-label={t[language].geolocalisation.icon_tooltip}
            onClick={getLocation}
            sx={{ height: "56px", ml: 2 }}
          >
            <Box component="p" mr={1} sx={{ display: { xs: "none", lg: "block" } }}>
              {t[language].geolocalisation.locate}
            </Box>
            <Icon icon="location" iconDSFR="map-pin-user-line" title={t[language].geolocalisation.icon_tooltip} />
          </Box>
        </Grid>
      </Block>
      <Box mx={{ xs: 2, lg: 0 }}>
        {!beforeSearch && !load && (
          <GeolocMapHeader geoPointsLength={geoPoints.length} cityPosition={cityPosition} source={source} />
        )}
      </Box>
      <Box px={{ xs: 2, lg: 0 }}>
        <div id="mapAnchorStart" />
        <GeolocMobileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <Grid container onMouseLeave={handleMouseLeave} sx={geoContainerStyled}>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              display: { xs: activeTab === "1" ? "none" : "block", lg: "block" },
              maxHeight: { lg: 498 },
              overflowX: "hidden",
              overflowY: "auto",
              "& > div:not(:last-child)": {
                borderBottom: "1px solid",
                borderColor: "secondary.main",
              },
            }}
          >
            {load && (
              <CircularProgress
                sx={{
                  color: "secondary.main",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  ml: -2.5,
                  mt: -2.5,
                }}
              />
            )}
            {geoPoints.map(
              (geoPointItem) =>
                geoPointItem._source.others && (
                  <Box
                    px={4}
                    py={3}
                    onMouseOver={handleMouseOverGeoPoint(geoPointItem)}
                    ref={geoPointsRefsText[geoPointItem._source.customId]}
                    key={geoPointItem._source.id}
                    sx={{ "&:hover": { bgcolor: "secondary.10" } }}
                  >
                    <InfoGeoloc geoPointItem={geoPointItem} cityPosition={cityPosition} source={source} />
                    <Stack
                      alignItems="flex-end"
                      aria-hidden="true"
                      tabIndex={0}
                      onClick={() => handleClickGeoPoint(geoPointItem)}
                      onKeyDown={(e) => e.key === eventKey && handleClickGeoPoint(geoPointItem)}
                      sx={{
                        display: { lg: "none" },
                        cursor: "pointer",
                        "& > i,span": {
                          fontSize: "26px",
                          color: "componentColors.60",
                        },
                      }}
                    >
                      <Icon
                        icon="map-marked-alt"
                        iconDSFR="road-map-line"
                        title={t[language].geolocalisation.view_map}
                      />
                    </Stack>
                  </Box>
                )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              display: { xs: activeTab === "2" ? "none" : "block", lg: "block" },
              position: "relative",
              "& .leaflet-control > a": {
                backgroundImage: "none",
              },
            }}
            aria-hidden
          >
            {!!MapContainer && (
              <Box sx={{ "& .leaflet-container": { width: "100%", height: "498px", zIndex: 1 } }}>
                <MapContainer
                  center={isMobile && centerOnClick.length > 1 ? centerOnClick : position}
                  zoom={beforeSearch ? 5.5 : 10.5}
                  maxZoom={15}
                  ref={mapRef}
                  zoomControl={!isMobile}
                  onDragEnd={invalidateMap()}
                >
                  <TileLayer
                    url={`${process.env.geoloc_url}/{z}/{x}/{y}.png`}
                    attribution={process.env.geoloc_licence}
                  />

                  {geoPoints.map(
                    (geoPointItem) =>
                      geoPointItem._source &&
                      geoPointItem._source.location && (
                        <Marker
                          icon={getLocationIcon(geoPointItem._source.category)}
                          position={geoPointItem._source.location.split(",")}
                          ref={geoPointsRefs[geoPointItem._source.customId]}
                          eventHandlers={{
                            mouseover: handleMouseOverGeoPoint(geoPointItem),
                          }}
                          onClick={() => !isMobile && handleClickGeoPoint(geoPointItem)}
                          onPopupClose={handlePopupClose(geoPointItem)}
                          key={geoPointItem._source.id}
                        >
                          <Popup>
                            <div onClick={handleClickPopup(geoPointItem)}>
                              <Box component="p" className="fr-text--bold" my={2} role="presentation">
                                {geoPointItem._source.others.name || ""}
                              </Box>
                              <Box component="p" m={0} role="presentation">
                                {source && source === "siv"
                                  ? `${geoPointItem._source.others.numVoie || ""} ${
                                      geoPointItem._source.others.typeVoie || ""
                                    } ${geoPointItem._source.others.libelleVoie || ""}`
                                  : `${geoPointItem._source.others.address || ""} ${
                                      geoPointItem._source.others.address2 || ""
                                    }`}
                              </Box>
                              <Box component="p" mb={0.5} role="presentation">
                                {geoPointItem._source.others.postalCode || ""} {geoPointItem._source.others.city || ""}
                              </Box>
                              <Box
                                component="p"
                                sx={{ mb: 0.5, color: "secondary.main", display: { lg: "none" } }}
                                role="presentation"
                              >
                                {t[language].geolocalisation.see_more_infos}
                              </Box>
                            </div>
                          </Popup>
                        </Marker>
                      )
                  )}
                </MapContainer>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

Geolocalisation.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Geolocalisation;
