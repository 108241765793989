import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import LanguageContext from "components/LanguageContext";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const ExpansionText = (props) => {
  const { reducedLabel, expandedLabel, text, defaultExpanded, ...others } = props;

  const { language } = useContext(LanguageContext);

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleClickExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleKeyDown = (event) => {
    if (event.key === eventKey) {
      handleClickExpand();
    }
  };

  const Icon = isExpanded ? ExpandLess : ExpandMore;

  return (
    <div {...others}>
      <Box sx={{ display: isExpanded ? "block" : "none" }}>{text}</Box>
      <MuiLink component="span" underline="none" onClick={handleClickExpand} onKeyDown={handleKeyDown} tabIndex={0}>
        {isExpanded ? expandedLabel : reducedLabel}
        <Icon
          title={isExpanded ? expandedLabel : reducedLabel}
          isHidden={false}
          aria-label={isExpanded ? t[language].common.close : t[language].common.open}
          sx={{ ml: 0.5, verticalAlign: "middle", fontSize: "1.2em" }}
        />
      </MuiLink>
    </div>
  );
};

ExpansionText.propTypes = {
  reducedLabel: PropTypes.string.isRequired,
  expandedLabel: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
};

export default ExpansionText;
