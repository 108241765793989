import Box from "@mui/material/Box";
import PageVersionContext from "components/PageVersionContext";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import EulerianService from "services/EulerianService";

export const componentClassName = "Da-ExpansionPanel";

const ExpansionPanel = (props) => {
  const {
    summary = "",
    details = null,
    tag = false,
    initialExpanded = false,
    children = null,
    onChange = null,
    noPadding = false,
    ...others
  } = props;
  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  const { search } = useLocation();

  const { currentPageVersion } = useContext(PageVersionContext);
  const { breadcrumb = [] } = currentPageVersion || {};

  useEffect(() => {
    if (initialExpanded || !tag) {
      setIsExpanded(initialExpanded);
    }
  }, [initialExpanded, tag]);

  const handleIsExpanded = (e) => {
    if (typeof onChange === "function") {
      onChange(e, !isExpanded);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isExpanded && breadcrumb) {
      const tabQuery = "?t=";
      const pageTag = {
        page_name: summary,
      };
      Object.keys(breadcrumb).forEach((key) => {
        if (key > 0) {
          pageTag[`page_category${Number(key) + 1}`] = breadcrumb[key].title;
        }
      });

      if (search && search.includes(tabQuery)) {
        pageTag[`page_category${Object.keys(breadcrumb).length + 1}`] = search.replace(tabQuery, "");
      }
      EulerianService.sendPage(pageTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <section className="fr-accordion">
      <h3 className="fr-accordion__title">
        <Box
          component="button"
          type="button"
          className="fr-accordion__btn"
          aria-expanded={isExpanded ? "true" : "false"}
          onClick={handleIsExpanded}
          sx={{ position: "relative" }}
          {...others}
        >
          {summary}
        </Box>
      </h3>
      <Box
        sx={{
          bgcolor: "#fff",
          p: isExpanded && !noPadding ? "16px 16px 24px" : 0,
          display: isExpanded ? "block" : "none",
        }}
      >
        {children || details}
      </Box>
    </section>
  );
};

ExpansionPanel.propTypes = {
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  details: PropTypes.node,
  tag: PropTypes.bool,
  initialExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  noPadding: PropTypes.bool,
};

export default ExpansionPanel;
