import ExpansionPanel from "components/templatesComponents/ExpansionPanel";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const itemClassName = "Da-Accordion-Item";

const Accordion = (props) => {
  let { panels = [] } = props;
  const { noPadding = false, expansionPanelRole = undefined } = props;
  if (panels && !Array.isArray(panels)) {
    panels = [panels];
  }

  const [expanded, setExpanded] = useState(false);
  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <div role="list">
      {(panels || []).map((panel, index) => {
        const isExpanded = index === expanded;
        return (
          <div role="listitem" className={itemClassName} key={panel.id}>
            <ExpansionPanel
              {...panel}
              initialExpanded={isExpanded}
              onChange={handleChange(index)}
              noPadding={noPadding}
              role={expansionPanelRole}
            />
          </div>
        );
      })}
    </div>
  );
};

Accordion.propTypes = {
  panels: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  noPadding: PropTypes.bool,
  expansionPanelRole: PropTypes.string,
};

export default Accordion;
