import { Box } from "@mui/material";
import LanguageContext from "components/LanguageContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import t from "utils/locales/translation.json";

const scrollMenuStyled = {
  ".react-horizontal-scrolling-menu--inner-wrapper": {
    alignItems: "center",
  },
  ".react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right": {
    display: "block",
  },
  ".react-horizontal-scrolling-menu--scroll-container ": {
    "&::-webkit-scrollbar": {
      visibility: "hidden",
      display: "none",
    },
  },
};

const arrowStyled = (isItemVisible) => ({
  cursor: "pointer",
  display: { xs: "none", lg: "block" },
  visibility: isItemVisible ? "hidden" : "visible",
  " & i": {
    fontSize: { xs: "24px", lg: "32px" },
  },
});

const LeftArrow = () => {
  const { language } = useContext(LanguageContext);
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
  return (
    <Button
      tabIndex={-1}
      aria-label={t[language].components.left_icon}
      sx={arrowStyled(isFirstItemVisible)}
      onClick={() => scrollPrev()}
    >
      <Icon icon="long-arrow-left" iconDSFR="arrow-left-line" title={t[language].components.left_icon} />
    </Button>
  );
};

const RightArrow = () => {
  const { language } = useContext(LanguageContext);
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  return (
    <Button
      tabIndex={-1}
      aria-label={t[language].components.right_icon}
      sx={arrowStyled(isLastItemVisible)}
      onClick={() => scrollNext()}
    >
      <Icon icon="long-arrow-right" iconDSFR="arrow-right-line" title={t[language].components.right_icon} />
    </Button>
  );
};

const HorizontalScroll = (props) => {
  const { items = null } = props;

  return (
    <Box sx={scrollMenuStyled}>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} transitionDuration={2000} transitionBehavior="smooth">
        {items}
      </ScrollMenu>
    </Box>
  );
};
HorizontalScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

export default HorizontalScroll;
