import DefaultSubTemplate from "components/templates/default/DefaultSubTemplate";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";

const SubPage = (props) => {
  const { page = null } = props;

  const { subTemplate, getSubTemplate = () => null } = useContext(SubPageContext);

  // Template will take value of subTemplate or call getSubTemplate function if subTemplate is null
  let Template = subTemplate;
  if (!subTemplate) {
    Template = getSubTemplate(page.template);
  }

  Template = Template || DefaultSubTemplate;

  return <Template page={page} key={page.id} />;
};

SubPage.propTypes = {
  page: PropTypes.shape(),
};

export default SubPage;
