import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import LanguageContext from "components/LanguageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const radioStyled = {
  "&.Mui-focusVisible": {
    border: "2px solid #0a76f6",
  },
};

const CookiesTable = (props) => {
  const { consent = "", cookiesRows, setConsent = null, disabled = false, description = null, subtitle } = props;

  const { language } = useContext(LanguageContext);

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleChange = (e) => {
    const { value } = e.target || {};
    setConsent(value);
  };

  return (
    <div className="fr-consent-service">
      <fieldset
        aria-labelledby={`${subtitle}-legend ${subtitle}-desc`}
        role="group"
        className="fr-fieldset fr-fieldset--inline"
      >
        <legend id={`${subtitle}-legend`} className="fr-consent-service__title">
          {subtitle}
        </legend>
        <div className="fr-consent-service__radios">
          <RadioGroup value={disabled ? "Accepter" : consent} onChange={(e) => handleChange(e)} row>
            <div className="fr-radio-group">
              <FormControlLabel
                label={t[language].cookies.accept_radio}
                value="Accepter"
                control={<Radio color="primary" disabled={disabled} sx={radioStyled} />}
                className="fr-label"
              />
            </div>
            <div className="fr-radio-group">
              <FormControlLabel
                label={t[language].cookies.refuse_radio}
                value="Refuser"
                control={<Radio color="primary" disabled={disabled} sx={radioStyled} />}
                className="fr-label"
              />
            </div>
          </RadioGroup>
        </div>
        <div id={`${subtitle}-desc`} className="fr-consent-service__desc">
          {description}
        </div>
        <div className="fr-consent-service__collapse">
          <button
            type="button"
            className="fr-consent-service__collapse-btn"
            aria-expanded={openDetails ? "true" : "false"}
            aria-describedby={`${subtitle}-legend`}
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? t[language].components.see_less_details : t[language].components.see_more_details}
          </button>
        </div>
        {openDetails && (
          <div className="fr-consent-services">
            {Array.isArray(cookiesRows) &&
              cookiesRows.map((cookiesRow) => (
                <div className="fr-consent-service" key={cookiesRow.finality}>
                  <fieldset className="fr-fieldset fr-fieldset--inline">
                    <legend id={`${subtitle}-service-1-legend`} className="fr-consent-service__title">
                      {cookiesRow.name}
                    </legend>
                    <p id={`${subtitle}-service-1-desc`} className="fr-consent-service__desc" role="presentation">
                      {cookiesRow.finality}
                    </p>
                  </fieldset>
                </div>
              ))}
          </div>
        )}
      </fieldset>
    </div>
  );
};

CookiesTable.propTypes = {
  cookiesRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  consent: PropTypes.string,
  setConsent: PropTypes.func,
  disabled: PropTypes.bool,
  description: PropTypes.shape(),
  subtitle: PropTypes.string.isRequired,
};

export default CookiesTable;
