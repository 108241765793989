import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import languages from "utils/languagesTypes";
import t from "utils/locales/translation.json";

const LanguageSelector = (props) => {
  const { associatedPnuPages } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const { currentPageVersion = {} } = useContext(PageVersionContext);

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const { value } = e.target;
    setLanguage(value);
    setIsExpanded(false);
    if (Object.keys(currentPageVersion).length > 0) {
      const pathname = associatedPnuPages?.find((p) => p.page?.lang === value)?.fullPath;
      history.push(pathname);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
      <nav role="navigation" className="fr-translate fr-nav">
        <div className="fr-nav__item">
          <Box
            component="button"
            type="button"
            value={language}
            className="fr-translate__btn fr-btn fr-btn--tertiary"
            aria-expanded={isExpanded ? "true" : "false"}
            aria-label={t[language].components.select_language}
            title={t[language].components.select_language}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isMobile ? languages[language]?.label : languages[language]?.value}
          </Box>
          {isExpanded && (
            <div className=" fr-translate__menu fr-menu">
              <ul className="fr-menu__list">
                {Object.values(languages).map((l) => (
                  <li key={l.lang}>
                    <button
                      type="button"
                      className="fr-translate__language fr-nav__link"
                      hrefLang={l.lang}
                      lang={l.lang}
                      aria-current={language === l.value ? l.value : null}
                      value={l.value}
                      onClick={(e) => {
                        handleChange(e);
                      }}
                    >
                      {l.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </ClickAwayListener>
  );
};

LanguageSelector.propTypes = {
  associatedPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LanguageSelector;
