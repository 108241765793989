import ProcessesDsfr from "components/templates/processesDsfr/ProcessesDsfr";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "PROCESSESDSFR",
  label: "Toutes les démarches (DSFR)",
  Component: ProcessesDsfr,
  servesOtherPages: true,
  initialContents: [
    {
      key: "showLogo",
      type: elementsTypes.ALONE_CHECKBOX,
      value: "true",
    },
    {
      key: "contentTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "subTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "dynamicList",
      type: elementsTypes.DYNAMIC_LIST,
      value: "",
      children: [
        {
          key: "limit",
          value: "2",
        },
        { key: "contentType", value: [elementsTypes.BUTTON].toString() },
      ],
    },
    {
      key: "dsfrCards",
      type: elementsTypes.DSFR_TILE_CARDS_LIST,
      value: "",
    },
  ],
};
