import PropTypes from "prop-types";
import React from "react";

const JsonLd = ({ structuredData }) =>
  structuredData && (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: structuredData }}
    />
  );

JsonLd.propTypes = {
  structuredData: PropTypes.string.isRequired,
};

export default JsonLd;
