import Chip from "@mui/material/Chip";
import Icon from "components/templatesComponents/Icon";
import React from "react";
import { getSiteName } from "utils/commonUtils";

export const types = [
  { value: "info", label: "Information" },
  { value: "warning", label: "Avertissement" },
  { value: "alert", label: "Alerte" },
];

export const dateNowFormatted = new Date().toISOString().split("T")[0];

export const localeDateNow = Date.now();

export const formatDateToLocalTimezone = (date) => {
  const currentDate = new Date(date);
  const offset = currentDate.getTimezoneOffset();
  const newDate = new Date(currentDate.getTime() - offset * 60 * 1000);
  return newDate.toISOString().replace("Z", "");
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("FR-fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getDisplayPeriod = (banner) => {
  const { forceDisplay, displayStartDate, displayEndDate } = banner;
  if (forceDisplay) {
    return <p>Permanent</p>;
  }
  if (displayStartDate && displayEndDate) {
    if (localeDateNow > Date.parse(displayEndDate)) {
      return (
        <i>
          Expiré
          <Icon
            iconDSFR="information-line"
            title={`Du ${formatDate(banner.displayStartDate)} au ${formatDate(banner.displayEndDate)}`}
            sx={{ ml: 1 }}
          />
        </i>
      );
    }
    return (
      <>
        <p>Du {formatDate(banner.displayStartDate)}</p>
        <p>au {formatDate(banner.displayEndDate)}</p>
      </>
    );
  }
  return <i>Aucune période définie</i>;
};

export const displaySortedSites = (sites = []) =>
  sites
    .sort((a, b) => getSiteName(a.name)?.localeCompare(getSiteName(b.name)))
    .map((site) => (
      <Chip key={site.id} label={getSiteName(site.name)} color="primary" variant="outlined" sx={{ m: 0.25 }} />
    ));
