import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const GeolocMobileTabs = (props) => {
  const { activeTab, setActiveTab } = props;

  const { language } = useContext(LanguageContext);

  const tabArray = [
    { id: "1", label: t[language].geolocalisation.view_map },
    { id: "2", label: t[language].geolocalisation.view_list },
  ];
  return (
    <Grid
      container
      sx={{ width: "100%", position: "sticky", zIndex: 3, top: 0, backgroundColor: "#f5f5f5", display: { lg: "none" } }}
    >
      {tabArray.map((tab) => (
        <Grid item xs={6} key={tab.id} role="list">
          <Box
            role="listitem"
            onClick={() => setActiveTab(tab.id)}
            sx={(theme) => ({
              p: 2,
              border: "1px solid",
              borderColor: "secondary.main",
              textAlign: "center",
              ...(activeTab === tab.id
                ? { bgcolor: "secondary.main", color: theme.palette.getContrastText(theme.palette.secondary.main) }
                : {}),
            })}
          >
            {tab.label}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

GeolocMobileTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default GeolocMobileTabs;
