import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import CookiesModalContext from "components/CookiesModalContext";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import SectionService from "services/SectionService";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import { getEnglishItems } from "utils/urlUtils";

const ExternalIcon = () => {
  const { language } = useContext(LanguageContext);

  return (
    <Icon
      icon="external-link"
      iconDSFR="external-link-line"
      title={t[language].footer.new_tab_title}
      sx={{
        pl: 0.75,
        "&::before": {
          "--icon-size": "0.875rem",
        },
      }}
      isHidden={false}
      aria-label={t[language].footer.new_tab_title}
    />
  );
};

const Footer = (props) => {
  const { allPnuPages, homePage = {} } = props;
  const { handleCookiesModal } = useContext(CookiesModalContext);
  const { language } = useContext(LanguageContext);

  const site = useContext(SiteContext);
  const [{ data: footer }] = useAxiosCache(SectionService.getConfig("getFooter", site.id));
  const { path: homePagePath, fullPath: homePageFullPath } = homePage;

  const footerContents = (footer && generateTemplatePropsFromContents(footer.contents)) || {};
  let { LinkExternal = [], LinkInternal1 = [], LinkInternal2 = [] } = footerContents;
  if (!Array.isArray(LinkExternal)) {
    LinkExternal = [LinkExternal];
  }
  if (!Array.isArray(LinkInternal1)) {
    LinkInternal1 = [LinkInternal1];
  }
  if (!Array.isArray(LinkInternal2)) {
    LinkInternal2 = [LinkInternal2];
  }

  const LinkInternal1WithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: LinkInternal1, allPnuPages }) || [];
    }
    return LinkInternal1 || [];
  }, [language, LinkInternal1, allPnuPages]);

  const LinkInternal2WithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: LinkInternal2, allPnuPages }) || [];
    }
    return LinkInternal2 || [];
  }, [language, LinkInternal2, allPnuPages]);

  const { name: siteName, settings: siteSettings } = site || {};
  let { socialNetworks = [] } = siteSettings || {};

  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const LinkList1 = () =>
    (LinkInternal1WithPNU &&
      LinkInternal1WithPNU.map((list1item) => (
        <li className="fr-footer__bottom-item" key={list1item.id}>
          <Link className="fr-footer__bottom-link" {...list1item.link}>
            {list1item.title}
            {list1item && list1item.link && list1item.link.external && <ExternalIcon />}
          </Link>
        </li>
      ))) ||
    null;

  const LinkList2 = () =>
    (LinkInternal2WithPNU &&
      LinkInternal2WithPNU.map((list2item) => (
        <li className="fr-footer__bottom-item" key={list2item.id}>
          <Link className="fr-footer__bottom-link" {...list2item.link}>
            {list2item.title}
            {list2item && list2item.link && list2item.link.external && <ExternalIcon />}
          </Link>
        </li>
      ))) ||
    null;

  const handleKeyDown = (event) => {
    if (event.key === eventKey) {
      handleCookiesModal();
    }
  };

  return (
    <footer className="fr-footer" role="contentinfo" id="footer">
      <div className="fr-container">
        <div className="fr-footer__body">
          <div className="fr-footer__brand fr-enlarge-link" src="/logo-republique-francaise.svg">
            <Link url={homePageFullPath || homePagePath} title={t[language].footer.back_to_home} sx={{ mr: 3 }}>
              <p className="fr-logo">
                république
                <br />
                française
              </p>
            </Link>
            <img src="/logo_France_Titres_clair.svg" alt="" />
          </div>
          <div className="fr-footer__content">
            <ul>
              {LinkExternal.map((list1item) => (
                <li key={list1item.id}>
                  <Link {...list1item.link} className="fr-footer__content-desc">
                    {list1item.title}
                    {list1item && list1item.link && list1item.link.external && <ExternalIcon />}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="fr-footer__content-list">
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://info.gouv.fr">
                  info.gouv.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link linkComponent external className="fr-footer__content-link" url="https://service-public.fr">
                  service-public.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link external linkComponent className="fr-footer__content-link" url="https://legifrance.gouv.fr">
                  legifrance.gouv.fr
                </Link>
              </li>
              <li className="fr-footer__content-item">
                <Link linkComponent external className="fr-footer__content-link" url="https://data.gouv.fr">
                  data.gouv.fr
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="fr-footer__bottom">
          <Stack
            alignItems={{ xs: "center", sm: "flex-start" }}
            sx={{ fontSize: "14px", py: 2, mb: -5, width: "100%" }}
          >
            <h3 className="fr-text--sm fr-text--regular fr-m-0">{t[language].footer.partners} :</h3>
            <Stack
              direction={{ sm: "row" }}
              alignItems="center"
              spacing={{ xs: 1, sm: 2 }}
              sx={{
                mt: 1,
                "& a": {
                  backgroundImage: "none",
                },
              }}
            >
              {["ANTS", "IMMAT"].includes(siteName) && (
                <Link url="https://europa.eu/youreurope/index.htm#en" title="Your Europe">
                  <img src="/logo-yourEurope.svg" alt="Logo your Europe" />
                </Link>
              )}
              <Link url="https://www.plus.transformation.gouv.fr/" title="Services Publics +">
                <img src="/logo-servicesPublicsPlus.svg" alt="Logo Services Publics +" />
              </Link>
            </Stack>
          </Stack>
        </div>
        <div className="fr-footer__bottom">
          <ul className="fr-footer__bottom-list">
            <LinkList1 />
            <li className="fr-footer__bottom-item">
              <MuiLink
                className="fr-footer__bottom-link"
                color="inherit"
                underline="hover"
                onClick={() => handleCookiesModal()}
                onKeyDown={(e) => handleKeyDown(e)}
                tabIndex={0}
                sx={{ fontSize: "0.75rem" }}
              >
                {t[language].footer.cookies_link}
              </MuiLink>
            </li>
            <LinkList2 />
          </ul>
          <div className="fr-footer__bottom-copy">
            <p>
              {t[language].footer.etalab}{" "}
              <Link
                url="https://www.etalab.gouv.fr/licence-ouverte-open-licence"
                external
                linkComponent
                color="inherit"
              >
                licence etalab-2.0
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  allPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  homePage: PropTypes.shape(),
};

export default Footer;
