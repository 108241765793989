import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const SectionTitle = (props) => {
  const { children = null, ...others } = props;
  return (
    <Box component="h2" {...others}>
      {children}
    </Box>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.node,
};

export default SectionTitle;
