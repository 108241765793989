import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CanSendTagContext from "components/CanSendTagContext";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import SiteContext from "components/SiteContext";
import article from "components/templates/article/article";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import PageTitle from "components/templatesComponents/PageTitle";
import Separator from "components/templatesComponents/Separator";
import Text from "components/templatesComponents/Text";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import EulerianService from "services/EulerianService";
import PageService from "services/PageService";
import t from "utils/locales/translation.json";

const labelStyled = {
  display: "inline-block",
  px: 1.5,
  py: 0.5,
  border: { xs: "1px solid", lg: "none" },
  borderColor: "componentColors.70",
  color: "componentColors.70",
  fontSize: "0.77rem",
  bgcolor: { lg: "secondary.20" },
};

const documentsRequiredBlockStyled = {
  bgcolor: "primary.main",
  overflowWrap: "break-word",
  p: { xs: 2, lg: 4 },
  position: { lg: "absolute" },
  right: 0,
  "& a": {
    color: "white",
    textDecoration: "underline",
    fontWeight: 300,
  },
  "& *": {
    color: "white",
  },
  "& h2": {
    mb: 3,
  },
};

const textTruncatStyled = {
  "@media screen": {
    display: { xs: "-webkit-box", lg: "block" },
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "3",
  },
};

const stepsBlockStyled = {
  bgcolor: "primary.10",
  mt: { lg: 5 },
  pb: { lg: 8 },
  "& h2": {
    maxWidth: { lg: "650px" },
  },
};

const sectionStyled = {
  pt: { xs: 5, lg: 10 },
  px: { xs: 2, lg: 0 },
  "& h2": {
    mb: 3,
  },
};

const Process = (props) => {
  const { page: currentPage } = props;
  const { title, contents, page, pageId } = currentPage;
  const {
    processDescription,
    toKnow,
    startLink,
    startLinkFullWidth,
    documentsRequiredCount,
    documentsRequired,
    completionTime,
    stepper,
    relatedQuestions,
  } = contents;
  const { originalPageId } = page || {};
  const site = useContext(SiteContext);
  const { id: siteId } = site || {};

  let { videosList } = contents;
  if (videosList && !Array.isArray(videosList)) {
    videosList = [videosList];
  }

  let { panels } = relatedQuestions.props || {};

  if (panels && !Array.isArray(panels)) {
    panels = [panels];
  }

  const { language } = useContext(LanguageContext);

  const [seeMoreKnow, setseeMoreKnow] = useState(false);
  const [seeMoreDocument, setseeMoreDocument] = useState(false);

  const handleClickStartLink = () => {
    EulerianService.sendClick({ button: [`button::click::${title}`] });
  };

  const [{ data: associatedPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: originalPageId || pageId,
    })
  );

  const [{ data = {} }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      siteName: "ANTS",
      templates: [article.key],
      filters: [`page.name||eq||Art - Simplimmat`],
    })
  );

  const simplimmatPage = data?.pages?.[0] || data?.[0];

  const displaySimplimmat = ["/vendre-ou-donner-votre-vehicule", "/acheter-ou-recevoir-un-vehicule-doccasion"].some(
    (url) => associatedPnuPages.map((associatedPage) => associatedPage.path).includes(url)
  );

  // TODO voir pour factoriser le lien commencer la demarche
  return (
    <>
      <Wrapper sx={{ position: "relative" }}>
        <Hidden lgDown>
          <Box sx={labelStyled}>{processDescription}</Box>
        </Hidden>
        <Grid container sx={{ borderTop: { lg: "1px solid", borderColor: "componentColors.70" } }}>
          <Grid
            item
            sm={12}
            lg={8}
            sx={{
              mt: { lg: 2 },
              mr: { lg: 2 },
              "& > ol": {
                p: 0,
                "& > li::marker": {
                  content: "none",
                  fontSize: 0,
                },
              },
            }}
          >
            <Box mx={{ xs: 2, lg: 0 }}>
              <PageTitle>{title}</PageTitle>{" "}
            </Box>
            <Hidden lgUp>
              <Box sx={{ textAlign: "center", mt: 2, mb: 3 }}>
                <Box sx={labelStyled}>{processDescription}</Box>
              </Box>
            </Hidden>
            <Block
              sx={{
                bgcolor: { xs: "secondary.main", lg: "inherit" },
                "& h3": {
                  mt: { xs: 2, lg: 0 },
                  mb: 1,
                  fontSize: { lg: "0.875rem" },
                  lineHeight: { lg: "18px" },
                },
              }}
            >
              <h3>{t[language].process.to_know}</h3>
              <Text>
                <Box sx={seeMoreKnow ? {} : textTruncatStyled}>{toKnow}</Box>
                <Box sx={{ display: { lg: "none" } }} onClick={() => setseeMoreKnow(!seeMoreKnow)} role="button">
                  {!seeMoreKnow ? t[language].common.see_more : t[language].common.see_less}{" "}
                  <Icon
                    icon={!seeMoreKnow ? "chevron-down" : "chevron-up"}
                    iconDSFR={!seeMoreKnow ? "arrow-down-s-line" : "arrow-up-s-line"}
                    title={!seeMoreKnow ? t[language].common.see_more : t[language].common.see_less}
                  />
                </Box>
              </Text>
            </Block>
            <Hidden lgDown>
              <Box onClick={handleClickStartLink}>
                <CanSendTagContext.Provider value={false}>{startLink}</CanSendTagContext.Provider>
              </Box>
            </Hidden>
            {displaySimplimmat && (
              <Block>
                <Box my={2.5}>
                  <h3>{t[language].process.did_you_know}</h3>
                  <p>
                    {t[language].process.simplimmat_message1}
                    <Link linkComponent url={simplimmatPage?.fullPath}>
                      Simplimmat
                    </Link>
                    {t[language].process.simplimmat_message2}
                  </p>
                </Box>
              </Block>
            )}
          </Grid>
          <Grid item sm={12} lg={4} sx={documentsRequiredBlockStyled}>
            <Box>
              <h2>{t[language].process.document_required_title}</h2>
              <p className="fr-text-sm" role="presentation">
                {documentsRequiredCount}
              </p>
              <Box sx={seeMoreKnow ? {} : textTruncatStyled}>{documentsRequired}</Box>
              <Box sx={{ display: { lg: "none" } }} onClick={() => setseeMoreDocument(!seeMoreDocument)} role="button">
                {!seeMoreDocument ? t[language].common.see_more : t[language].common.see_less}
                <Icon
                  icon={!seeMoreDocument ? "chevron-down" : "chevron-up"}
                  iconDSFR={!seeMoreDocument ? "arrow-down-s-line" : "arrow-up-s-line"}
                  title={!seeMoreDocument ? t[language].common.see_more : t[language].common.see_less}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Wrapper>

      {/* Steps */}
      <Wrapper leftProps={{ sx: stepsBlockStyled }} sx={stepsBlockStyled}>
        <Separator />
        <Block>
          <h2>{t[language].process.steps_title}</h2>
          <p>
            <Icon
              icon="clock"
              iconDSFR="time-line"
              sx={{
                color: "secondary.main",
                fontSize: "16px",
                mr: 0.75,
              }}
            />
            <b>{completionTime}</b> {t[language].process.time_message}
          </p>
        </Block>
        <Grid container sx={{ "@media print": { display: "block" } }}>
          <Grid
            item
            sm={12}
            lg={8}
            sx={{
              pt: { lg: 2 },
              pr: { lg: 2 },
              "& > ol": {
                p: 0,
                "& > li::marker": {
                  content: "none",
                  fontSize: 0,
                },
              },
            }}
          >
            <ol>
              {stepper}
              <Box mt={4} onClick={handleClickStartLink}>
                <CanSendTagContext.Provider value={false}>{startLinkFullWidth}</CanSendTagContext.Provider>
              </Box>
            </ol>
          </Grid>
          <Hidden lgUp>
            <Separator />
          </Hidden>
        </Grid>
      </Wrapper>

      {/* Video */}
      {videosList && (
        <Wrapper sx={sectionStyled}>
          <h2>{t[language].common.see_more}</h2>
          {videosList}
        </Wrapper>
      )}

      {/* Related questions */}
      {(!panels || panels.length > 0) && (
        <Wrapper sx={sectionStyled}>
          <h2>{t[language].process.questions_title}</h2>
          {relatedQuestions}
        </Wrapper>
      )}
    </>
  );
};

Process.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Process;
