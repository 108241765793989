import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardDsfrContext from "components/CardDsfrContext";
import Wrapper from "components/LayoutFront/Wrapper";
import NewsTabs from "components/templates/news/NewsTabs";
import CardContext from "components/templatesComponents/CardContext";
import LastNewsBlock from "components/templatesComponents/LastNewsBlock";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import t from "utils/locales/translation.json";

const LastNewsTemplate = (props) => {
  const { page: pageVersion } = props;
  const { title, contents, page } = pageVersion;
  const { highlightedNews, oldCardsNews = {}, homeButton = {} } = contents;
  const { lang } = page;

  const { props: oldCardsNewsProps = {} } = oldCardsNews;
  const { cards: haveCards, children: isOnBackOffice } = oldCardsNewsProps;

  const { props: homeButtonProps = {} } = homeButton;
  const { children: homeButtonChildren, page: homeButtonPage, url: homeButtonUrl } = homeButtonProps;
  const buttonOnBackOffice = typeof homeButtonChildren === "object";

  const displaySubtitle = haveCards || isOnBackOffice || buttonOnBackOffice || homeButtonPage || homeButtonUrl;

  const displayCards = haveCards || isOnBackOffice;

  const displayButton = buttonOnBackOffice || (!haveCards && (homeButtonPage || homeButtonUrl));

  const cardHighlightedContextValue = useMemo(() => ({ isHorizontal: true, showImage: true }), []);
  const cardContextValue = useMemo(
    () => ({
      Wrapper: Grid,
      wrapperProps: {
        item: true,
        xs: 12,
        md: 6,
      },
    }),
    []
  );

  return (
    <CardDsfrContext.Provider value={cardHighlightedContextValue}>
      <Wrapper breakpoint="xl" sx={{ px: { xs: 2, lg: 0 } }}>
        <h1>{title}</h1>
        <NewsTabs currentPageVersion={pageVersion} />
        <LastNewsBlock highlightedNews={highlightedNews} lang={lang} />
        {displaySubtitle && (
          <Box component="p" className="fr-text--xl fr-text--bold" mt={2} role="presentation">
            {t[lang].last_news.tile_cards_title}
          </Box>
        )}
        {displayCards && (
          <Grid container spacing={2}>
            <CardContext.Provider value={cardContextValue}>{oldCardsNews}</CardContext.Provider>
          </Grid>
        )}
        {displayButton && (
          <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <button type="button" className="fr-btn fr-btn--tertiary">
                {homeButton}
              </button>
            </Grid>
            {buttonOnBackOffice && (
              <Grid item>
                <i>(Bouton également utilisé sur la page d&apos;Accueil)</i>
              </Grid>
            )}
          </Grid>
        )}
      </Wrapper>
    </CardDsfrContext.Provider>
  );
};

LastNewsTemplate.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.node,
    shortDescription: PropTypes.node,
    contents: PropTypes.shape(),
    page: PropTypes.shape(),
  }).isRequired,
};

export default LastNewsTemplate;
