import Stack from "@mui/material/Stack";
import LanguageContext from "components/LanguageContext";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import t from "utils/locales/translation.json";

const scrollerStyled = {
  color: "primary.40",
  position: "absolute",
  top: "500px",
  mx: "auto",
  maxWidth: "1400px",
  width: "100%",
  pointerEvents: "none",
  left: 0,
  fontSize: "28px",
  right: 0,
  pr: 8.75,
  display: { xs: "none", lg: "flex" },
  zIndex: 250,
  boxSizing: "border-box",
  visibility: "hidden",
  "& span::before": {
    "--icon-size": "3rem",
  },
  "@media print": {
    display: "none",
  },
};

const ScrollIndicator = (props) => {
  const { scrollableClass } = props;
  const { language } = useContext(LanguageContext);

  // Vérifie si la page est assez grande pour afficher le scroller
  if (typeof document !== "undefined" && scrollableClass) {
    const page = document.getElementsByClassName(scrollableClass)[0];
    const scrollElement = document.getElementById("ScrollIndicator");
    if (scrollElement !== null) {
      if (page && page.scrollHeight > page.clientHeight + 326 && page.scrollTop < 500) {
        scrollElement.style.visibility = "visible";
        scrollElement.style.display = "flex";
      } else {
        scrollElement.style.visibility = "hidden";
        scrollElement.style.display = "none";
      }
    }
  }

  const listener = () => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      const maxScrollTop = page.scrollHeight - page.offsetHeight;
      if ((page && page.scrollTop > 500) || page.scrollTop === maxScrollTop) {
        document.getElementById("ScrollIndicator").style.display = "none";
      }
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const page = document.getElementsByClassName(scrollableClass)[0];
      if (page) {
        page.addEventListener("scroll", listener);
        return () => {
          page.removeEventListener("scroll", listener);
        };
      }
    }
    return null;
  });

  return (
    <Stack alignItems="flex-end" id="ScrollIndicator" sx={scrollerStyled}>
      <Icon icon="chevron-double-down" iconDSFR="arrow-down-s-line" title={t[language].components.scroll_icon_title} />
      <Icon iconDSFR="arrow-down-s-line" title={t[language].components.scroll_icon_title} sx={{ mt: -4.375 }} />
    </Stack>
  );
};

ScrollIndicator.propTypes = {
  scrollableClass: PropTypes.string.isRequired,
};

export default ScrollIndicator;
