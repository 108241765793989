import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const RectangleBackground = (props) => {
  const {
    children = null,
    backgroundColor = "#EFF4FD",
    top = "0",
    bottom = "auto",
    height = "100%",
    position = "left",
  } = props;

  let { width } = props;

  if (width === 0.875) {
    width = "calc(960px + (100% - 960px) * 0.75)";
  } else if (width === 0.75) {
    width = "calc(960px + (100% - 960px) * 0.5)";
  } else if (width === 0.65) {
    width = "calc(960px + (75% - 960px))";
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          "&:before": {
            position: "absolute",
            top: `${top}`,
            bottom: `${bottom}`,
            left: position === "left" ? 0 : "unset",
            right: 0,
            width: `${width}`,
            bgcolor: `${backgroundColor}`,
            zIndex: -1,
            height: bottom === "auto" ? height : "auto",
            content: { lg: `""` },
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

RectangleBackground.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.oneOf([0.65, 0.75, 0.875]),
  position: PropTypes.oneOf(["left", "right"]),
};

export default RectangleBackground;
