import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactAutosuggest from "react-autosuggest";
import { debounce } from "utils/commonUtils";

const Autosuggest = (props) => {
  const {
    getSuggestions,
    getSuggestionValue,
    onSuggestionSelected,
    renderSuggestion: renderSuggestionProps,
    renderInputComponent,
    debounce: debounceDelay = null,
    inputProps,
    noResult = "",
    classes = {},
    ...others
  } = props;

  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = async ({ value }) => {
    let response = await getSuggestions(value);
    if (response && response.length === 0 && noResult) {
      response = [null];
    }
    setSuggestions(response);
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion === null && noResult) {
      return noResult;
    }
    return renderSuggestionProps(suggestion);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = (value) => {
    return value.trim().length > 2;
  };

  return (
    <ReactAutosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={
        debounceDelay ? debounce(onSuggestionsFetchRequested, debounceDelay) : onSuggestionsFetchRequested
      }
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      theme={classes}
      {...others}
    />
  );
};

Autosuggest.propTypes = {
  getSuggestions: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  renderInputComponent: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  inputProps: PropTypes.shape().isRequired,
  classes: PropTypes.shape(),
  noResult: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Autosuggest;
