import { Box } from "@mui/material";
import LanguageContext from "components/LanguageContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const Icon = (props) => {
  const { icon = "", type = "fal", iconDSFR = "", isHidden = true, groupDSFR, sx = {}, ...others } = props;
  const { language } = useContext(LanguageContext);

  if (icon === "ants-permis-noir") {
    return (
      <Box
        component="img"
        {...others}
        src="/assets-icons-icon-permis-black.svg"
        alt={t[language].components.icon_black}
        aria-hidden={isHidden}
        sx={sx}
      />
    );
  }
  if (icon === "ants-permis-blanc") {
    return (
      <Box
        component="img"
        {...others}
        src="/assets-icons-icon-permis-white.svg"
        alt={t[language].components.icon_white}
        aria-hidden={isHidden}
        sx={sx}
      />
    );
  }
  if (iconDSFR) {
    return <Box component="span" {...others} className={`fr-icon-${iconDSFR}`} sx={sx} aria-hidden={isHidden} />;
  }
  return <Box component="i" title={icon} {...others} className={`${type} fa-${icon}`} sx={sx} aria-hidden={isHidden} />;
};

Icon.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.oneOf(["fas", "far", "fal", "fad", "fab"]),
  iconDSFR: PropTypes.string,
  groupDSFR: PropTypes.string,
  isHidden: PropTypes.bool,
  sx: PropTypes.shape(),
};

export default Icon;
