import dsfrIcons from "apps/front-office/public/utility/icons/utility-icons.json";
import AccordionIcon from "components/adminComponents/contentForms/icons/AccordionIcon";
import ExpansionPanelIcon from "components/adminComponents/contentForms/icons/ExpansionPanelIcon";
import InfoBlockIcon from "components/adminComponents/contentForms/icons/InfoBlockIcon";
import TimelineIcon from "components/adminComponents/contentForms/icons/TimelineIcon";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import types from "utils/elementsTypes";
import geolocSourceType from "utils/geolocSourceType";
import videosOrigins from "utils/videosOrigins";
import { v4 as uuidv4 } from "uuid";

const contents = [
  {
    type: types.ACCORDION,
    label: "Accordéon",
    IconComponent: AccordionIcon,
    value: "",
    children: [
      {
        key: "panels",
        type: types.ACCORDION_PANEL,
      },
    ],
  },
  {
    label: "Panneau dépliant",
    type: types.ACCORDION_PANEL,
    value: "",
    editionModal: true,
    children: [
      {
        label: "Titre du paneau",
        key: "summary",
        type: types.INPUT,
        value: "Mon titre",
      },
      {
        key: "details",
        type: types.EXPANSION_PANEL_DETAILS,
        value: "",
      },
    ],
  },
  {
    type: types.ATTACHMENTS,
    label: "Liste de pièces jointes",
    icon: "list-ul",
    iconDSFR: "list-unordered",
  },
  {
    label: "Pièce jointe",
    type: types.ATTACHMENT,
    icon: "paperclip",
    iconDSFR: "attachment-line",
    editionModal: true,
    children: [
      {
        label: "Choisir un document",
        key: "file",
        type: types.FILE,
      },
      {
        label: "Texte du lien",
        key: "linkText",
        type: types.INPUT,
        value: "Télécharger le fichier",
      },
      {
        label: "Description",
        key: "description",
        type: types.INPUT,
        value: "Entrez la description de la pièce jointe",
      },
    ],
  },
  {
    label: "Pièce jointe",
    type: types.ATTACHMENT_LINK,
    editionModal: true,
    children: [
      {
        label: "Choisir un document",
        key: "file",
        type: types.FILE,
      },
      {
        label: "Texte du lien",
        key: "linkText",
        type: types.INPUT,
        value: "Télécharger le fichier",
      },
    ],
  },
  {
    label: "Bouton",
    type: types.BUTTON,
    icon: "hand-pointer",
    iconDSFR: "cursor-fill",
    editionModal: true,
    children: [
      {
        key: "text",
        type: types.INPUT,
        value: "Texte",
      },
      {
        label: "Lien du bouton",
        key: "link",
        type: types.LINK,
      },
      {
        label: "Placement de l'icône",
        key: "iconPosition",
        width: "45%",
        type: types.SELECT,
        options: [
          { label: "Gauche", value: "left" },
          { label: "Droite", value: "right" },
        ],
        value: "right",
      },
      {
        label: "Taille du bouton",
        key: "size",
        width: "45%",
        type: types.SELECT,
        options: [
          { label: "Petit", value: "sm" },
          { label: "Moyen", value: "md" },
          { label: "Grand", value: "lg" },
        ],
        value: "md",
      },
      {
        label: "Icone",
        key: "icon",
        type: types.ICON,
        value: "",
      },
      {
        label: "Prendre toute la largeur",
        key: "fullWidth",
        type: types.CHECKBOX,
        value: "false",
      },
      {
        label: "Couleur principal",
        key: "primary",
        type: types.CHECKBOX,
        value: "false",
      },
      {
        label: "Bouton secondaire",
        key: "outlined",
        type: types.CHECKBOX,
        value: "false",
      },
    ],
  },
  {
    label: "Cards",
    type: types.CARDS_LIST,
    value: "",
    children: [
      {
        label: "Style desktop",
        key: "desktopStyleKey",
        type: types.SELECT,
        options: Object.keys(styleKeys).map((k) => ({ label: k, value: k })),
        value: "",
      },
      {
        label: "Style mobile",
        type: types.SELECT,
        options: Object.keys(styleKeys).map((k) => ({ label: k, value: k })),
        key: "mobileStyleKey",
        value: "",
      },
    ],
  },
  {
    label: "Liste cards actualités",
    type: types.CARDS_LIST_NEWS,
    value: "",
    children: [],
  },
  {
    label: "Liste cards anciennes actualités",
    type: types.OLD_CARDS_LIST_NEWS,
    value: "",
    children: [],
  },
  {
    label: "Liste de cartes",
    type: types.DSFR_CARDS_LIST,
    icon: "th-large",
    iconDSFR: "layout-grid-line",
  },
  {
    label: "Liste de cartes",
    type: types.DSFR_CARDS_SPECIFIC_LIST,
    icon: "th-large",
    iconDSFR: "layout-grid-line",
  },
  {
    label: "Liste de tuiles",
    type: types.TILE_CARDS_LIST,
    icon: "th-large",
    iconDSFR: "layout-grid-line",
  },
  {
    label: "Liste de tuiles",
    type: types.TILE_CARDS_SPECIFIC_LIST,
    icon: "th-large",
    iconDSFR: "layout-grid-line",
  },
  {
    label: "Card",
    type: types.CARD,
    editionModal: true,
    value: "",
    children: [
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
      {
        key: "page",
        type: types.PAGE,
        value: "",
      },
      {
        key: "title",
        label: "Titre",
        type: types.INPUT,
        value: "",
      },
      {
        key: "description",
        label: "Description courte",
        type: types.SHORT_TEXT,
        value: "",
      },
      {
        label: "Lien",
        type: types.LINK,
        key: "link",
        children: [
          {
            label: "Url",
            key: "url",
            type: types.URL,
            value: "",
          },
          {
            label: "Page",
            key: "page",
            type: types.PAGE,
            value: "",
          },
          {
            key: "title",
            label: "Titre du lien",
            type: types.INPUT,
            value: "",
          },
          {
            label: "Ouvrir dans un nouvel onglet",
            key: "external",
            type: types.CHECKBOX,
            value: "false",
          },
        ],
      },
    ],
  },
  {
    label: "Actualité à la une",
    type: types.CARD_NEWS,
    editionModal: true,
    value: "",
    children: [
      {
        key: "page",
        type: types.PAGE,
        value: "",
      },
    ],
  },
  {
    label: "Actualité",
    type: types.OLD_CARD_NEWS,
    editionModal: true,
    value: "",
    children: [
      {
        key: "title",
        label: "Titre de la card",
        type: types.INPUT,
        value: "",
      },
      {
        key: "description",
        label: "Description de la card",
        type: types.INPUT,
        value: "",
      },
      {
        key: "page",
        type: types.PAGE,
        value: "",
      },
    ],
  },
  {
    label: "Card",
    type: types.DSFR_CARD,
    editionModal: true,
    value: "",
    children: [
      {
        key: "page",
        type: types.PAGE,
        value: "",
      },
      {
        key: "site",
        label: "Filtrer par site",
        type: types.SELECT,
        options: [
          { label: "Ants", value: "ANTS" },
          { label: "Immatriculation", value: "IMMAT" },
          { label: "Passeport / CNI", value: "PASSEPORT_CNI" },
          { label: "Permis", value: "PERMIS" },
          { label: "Auto-École ET CSSR", value: "AUTO_ECOLE" },
          { label: "Professionnels de l'automobile", value: "PHA" },
          { label: "Mairie", value: "MAIRIE" },
        ],
      },
      {
        key: "title",
        label: "Titre",
        type: types.INPUT,
        value: "",
      },
      {
        key: "description",
        label: "Description courte",
        type: types.SHORT_TEXT,
        value: "",
      },
      {
        label: "Lien",
        type: types.LINK,
        key: "link",
      },
    ],
  },
  {
    label: "Tuile",
    type: types.TILE_CARD,
    editionModal: true,
    value: "",
    children: [
      {
        key: "title",
        label: "Titre de la card",
        type: types.INPUT,
        value: "",
      },
      {
        key: "description",
        label: "Description de la card",
        type: types.INPUT,
        value: "",
      },
      {
        key: "site",
        label: "Filtrer par site",
        type: types.SELECT,
        options: [
          { label: "Ants", value: "ANTS" },
          { label: "Immatriculation", value: "IMMAT" },
          { label: "Passeport / CNI", value: "PASSEPORT_CNI" },
          { label: "Permis", value: "PERMIS" },
          { label: "Auto-École ET CSSR", value: "AUTO_ECOLE" },
          { label: "Professionnels de l'automobile", value: "PHA" },
          { label: "Mairie", value: "MAIRIE" },
        ],
      },
      {
        key: "page",
        type: types.PAGE,
        value: "",
      },
      {
        label: "Lien",
        key: "link",
        type: types.LINK,
      },
      {
        label: "Tuile horizontale",
        key: "isHorizontal",
        type: types.CHECKBOX,
        value: "false",
      },
      {
        label: "Icône",
        key: "icon",
        type: types.SELECT,
        options: [
          { label: "Ants", value: "ANTS" },
          { label: "Immatriculation", value: "IMMAT" },
          { label: "Passeport / CNI", value: "PASSEPORT_CNI" },
          { label: "Permis", value: "PERMIS" },
          { label: "Application téléphone", value: "application" },
          { label: "Passeport", value: "passeport" },
          { label: "Carte Permis", value: "carte-permis" },
          { label: "Police", value: "police" },
          { label: "Comedec", value: "comedec" },
          { label: "Auto-École ET CSSR", value: "AUTO_ECOLE" },
          { label: "Professionnels de l'automobile", value: "PHA" },
          { label: "Mairie", value: "MAIRIE" },
        ],
        value: "",
      },
    ],
  },
  {
    type: types.CHECKBOX,
  },
  {
    label: "Afficher le logo sur le site",
    type: types.ALONE_CHECKBOX,
  },
  {
    type: types.CONTACT_FORM_PROFILE_LIST,
    value: "",
    children: [
      {
        key: "ContactFormProfiles",
        type: types.CONTACT_FORM_PROFILE,
        value: "",
      },
    ],
  },
  {
    type: types.CONTACT_FORM_PROFILE,
    value: "",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "Nouveau profil",
      },
    ],
  },
  {
    type: types.CONTACT_FORM_SUBJECT_LIST,
    value: "",
    children: [
      {
        key: "ContactFormSubjects",
        type: types.CONTACT_FORM_SUBJECT,
        value: "",
      },
    ],
  },
  {
    type: types.CONTACT_FORM_SUBJECT,
    value: "",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "Nouvel objet",
      },
      {
        key: "recipient",
        type: types.INPUT,
        value: "",
      },
      {
        key: "autoReply",
        type: types.CHECKBOX,
        value: "false",
      },
      {
        key: "replyMessage",
        type: types.TEXT,
        value: "",
      },
    ],
  },
  {
    type: types.CONTENTS_GROUPS_LIST,
    value: "",
    children: [
      {
        type: types.CONTENTS_GROUP,
        key: "groups",
      },
    ],
  },
  {
    type: types.CONTENTS_GROUP,
    value: "",
    children: [
      {
        type: types.INPUT,
        key: "name",
        value: "",
      },
      {
        key: "contents",
      },
    ],
  },
  {
    label: "Choisir un fichier",
    type: types.FILE,
  },
  {
    label: "Panneau dépliant",
    type: types.EXPANSION_PANEL,
    IconComponent: ExpansionPanelIcon,
    editionModal: true,
    children: [
      {
        label: "Titre du paneau",
        key: "summary",
        type: types.INPUT,
        value: "Mon titre",
      },
      {
        key: "details",
        type: types.EXPANSION_PANEL_DETAILS,
      },
    ],
  },
  {
    type: types.EXPANSION_PANEL_DETAILS,
  },
  {
    label: "Texte dépliant",
    type: types.EXPANSION_TEXT,
    icon: "chevron-down",
    iconDSFR: "arrow-down-s-line",
    editionModal: true,
    children: [
      {
        label: "Libellé replié",
        key: "reducedLabel",
        type: types.INPUT,
        value: "Voir plus",
      },
      {
        label: "Libellé déplié",
        key: "expandedLabel",
        type: types.INPUT,
        value: "Voir moins",
      },
      {
        label: "Déplié par défaut",
        key: "defaultExpanded",
        type: types.CHECKBOX,
        value: "false",
      },
      {
        key: "text",
        type: types.TEXT,
      },
    ],
  },
  {
    type: types.DYNAMIC,
  },
  {
    label: "Type d'établissement",
    type: types.GEOLOC_SOURCETYPE,
    options: Object.values(geolocSourceType).map((geolocType) => ({
      label: geolocType,
      value: geolocType,
    })),
    value: Object.values(geolocSourceType)[0],
  },
  {
    type: types.GLOSSARY,
  },
  {
    label: "Définition",
    type: types.GLOSSARY_ITEM,
    // editionModal: true,
    children: [
      {
        key: "word",
        label: "Mot / Acronyme",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Description",
        key: "description",
        type: types.SHORT_TEXT,
        value: "",
      },
    ],
  },
  {
    label: "Grille",
    type: types.GRID,
    icon: "border-none",
    iconDSFR: "layout-grid-line",
    editionModal: true,
    children: [
      {
        key: "size",
        label: "Nombre de colonnes",
        type: types.SELECT,
        options: [
          { label: "2 colonnes", value: "6" },
          { label: "3 colonnes", value: "4" },
          { label: "4 colonnes", value: "3" },
          { label: "6 colonnes", value: "2" },
        ],
        value: "6",
      },
      {
        key: "sizeXs",
        label: "Nombre de colonnes en mobile",
        type: types.SELECT,
        options: [
          { label: "1 colonne", value: "12" },
          { label: "2 colonnes", value: "6" },
          { label: "3 colonnes", value: "4" },
          { label: "4 colonnes", value: "3" },
        ],
        value: "12",
      },
    ],
  },
  {
    label: "Icône",
    type: types.ICON,
    value: "",
    children: [
      {
        label: "Nom",
        key: "icon",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Type",
        key: "type",
        type: types.SELECT,
        options: [
          { label: "Solid", value: "fas" },
          { label: "Regular", value: "far" },
          { label: "Light", value: "fal" },
          { label: "Duotone", value: "fad" },
          { label: "Brands", value: "fab" },
        ],
        value: "fal",
      },
      {
        key: "iconDSFR",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Groupe",
        key: "groupDSFR",
        type: types.SELECT,
        options: Object.keys(dsfrIcons).map((group) => {
          return { label: group, value: group };
        }),
        value: "",
      },
    ],
  },
  {
    label: "Lien",
    type: types.ICON_LINK,
    icon: "link",
    iconDSFR: "links-line",
    editionModal: true,
    children: [
      {
        key: "text",
        type: types.INPUT,
        value: "Texte",
      },
      {
        label: "Lien",
        key: "link",
        type: types.LINK,
      },
      {
        label: "Placement de l'icône",
        key: "iconPosition",
        width: "45%",
        type: types.SELECT,
        options: [
          { label: "Gauche", value: "left" },
          { label: "Droite", value: "right" },
        ],
        value: "right",
      },
      {
        label: "Taille du lien",
        key: "size",
        width: "45%",
        type: types.SELECT,
        options: [
          { label: "Petit", value: "sm" },
          { label: "Moyen", value: "md" },
          { label: "Grand", value: "lg" },
        ],
        value: "md",
      },
      {
        key: "icon",
        type: types.ICON,
      },
    ],
  },
  {
    label: "Choisir une image",
    type: types.FILE_IMAGE,
  },
  {
    label: "Image",
    type: types.IMAGE,
    children: [
      {
        key: "file",
        type: types.FILE_IMAGE,
        value: "",
      },
      {
        label: "Texte alternatif",
        key: "alt",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Titre (visible au survol)",
        key: "title",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Position",
        key: "position",
        type: types.SELECT,
        options: [
          { label: "Haute", value: "top" },
          { label: "Centrée", value: "center" },
          { label: "Basse", value: "bottom" },
        ],
        value: "center",
      },
    ],
  },
  {
    label: "Image",
    type: types.IMAGE_BLOCK,
    editionModal: true,
    icon: "image",
    value: "",
    children: [
      {
        key: "image",
        type: types.IMAGE,
      },
      {
        label: "Largeur de l'image (en %)",
        key: "width",
        type: types.SLIDER,
      },
      {
        label: "Largeur de l'image (en %) sur mobile",
        key: "mobileWidth",
        type: types.SLIDER,
      },
      {
        key: "justifyContent",
        label: "Alignement",
        type: types.SELECT,
        options: [
          { label: "À gauche", value: "flex-start" },
          { label: "Centré", value: "center" },
          { label: "À droite", value: "flex-end" },
        ],
        value: "flex-start",
      },
      {
        key: "legend",
        label: "Légende de l'image",
        type: types.EDITABLE_INPUT,
        value: "",
      },
    ],
  },
  {
    label: "Mise en avant",
    type: types.INFO_BLOCK,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    children: [
      {
        key: "title",
        label: "Titre mise en avant",
        type: types.EDITABLE_INPUT,
        value: "",
      },
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
      {
        key: "text",
        label: "Texte",
        type: types.TEXT,
        value: "",
      },
      {
        key: "link",
        label: "Lien du bouton",
        type: types.LINK,
        children: [
          {
            key: "title",
            label: "Texte du bouton",
            type: types.INPUT,
            value: "Bouton",
          },
        ],
      },
    ],
  },
  {
    type: types.INFO_BLOCK_LIST,
  },
  {
    label: "Texte",
    type: types.INPUT,
    value: "",
  },
  {
    type: types.LINKS_LIST,
    label: "Liste de liens",
    icon: "list-ul",
    iconDSFR: "list-unordered",
  },
  {
    label: "Lien",
    type: types.LINK,
    children: [
      {
        label: "Url",
        key: "url",
        type: types.URL,
        value: "",
      },
      {
        label: "Page",
        key: "page",
        type: types.PAGE,
        value: "",
      },
      {
        key: "title",
        label: "Titre du lien",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Ouvrir dans un nouvel onglet",
        key: "external",
        type: types.CHECKBOX,
        value: "false",
      },
    ],
  },
  {
    label: "Lien mailto",
    type: types.MAILTO,
    icon: "at",
    iconDSFR: "mail-line",
    editionModal: true,
    children: [
      {
        label: "Texte",
        key: "text",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Email de destination",
        key: "recipient",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Objet",
        key: "subject",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Corps",
        key: "body",
        type: types.INPUT,
        value: "",
      },
    ],
  },
  {
    label: "Groupe de liens",
    editionModal: true,
    type: types.MENU_GROUP,
    icon: "link",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "",
      },
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
    ],
  },
  {
    label: "Lien",
    editionModal: true,
    type: types.MENU_ITEM,
    icon: "link",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "",
      },
      {
        key: "link",
        type: types.LINK,
        value: "",
      },
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
      {
        label: "Ajouter à la barre de navigation mobile (limité à 1 lien)",
        key: "mobileNav",
        type: types.CHECKBOX,
        value: "false",
      },
    ],
  },
  {
    label: "Résumé de page",
    type: types.PAGE,
  },

  {
    type: types.RESULT_LIST,
    icon: "poll-h",
    iconDSFR: "profil-line",
    label: "Liste résultats",
  },
  {
    label: "Résultat",
    type: types.RESULT,
    editionModal: true,
    children: [
      {
        label: "Icône",
        key: "icon",
        type: types.ICON,
        children: [
          {
            key: "icon",
            type: types.INPUT,
            value: "chart-bar",
          },
        ],
      },
      {
        label: "Résultat",
        key: "number",
        type: types.NUMERIC,
        value: "12345",
      },
      {
        label: "Description du résultat",
        key: "text",
        type: types.INPUT,
        value: "Description du résultat",
      },
    ],
  },
  {
    type: types.QUOTE,
    label: "Citation",
    icon: "comment-dots",
    iconDSFR: "message-2-line",
    editionModal: true,
    children: [
      {
        label: "Type",
        key: "quoteType",
        type: types.SELECT,
        options: [
          { label: "Citation", value: "basic" },
          { label: "Citation de tweet", value: "tweet" },
        ],
        value: "basic",
      },
      {
        label: "Citation",
        key: "quote",
        type: types.TEXT,
        value: "",
      },
      {
        key: "author",
        label: "Auteur",
        type: types.EDITABLE_INPUT,
        value: "",
      },
      {
        key: "image",
        type: types.FILE_IMAGE,
        value: "",
      },
      {
        label: "Lien",
        key: "link",
        type: types.LINK,
      },
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
    ],
  },
  {
    type: types.SELECT,
  },
  {
    type: types.SITE_SETTINGS,
    value: "",
    children: [
      {
        key: "socialNetworks",
        type: types.SOCIAL_NETWORK,
        value: "",
      },
    ],
  },
  {
    type: types.SITE_NAME,
    key: "siteName",
    value: "",
  },
  {
    type: types.SITE_ONBOARDING,
    key: "onboarding",
    value: "",
    children: [
      {
        type: types.INPUT,
        key: "videoSrc",
        value: "https://www.youtube.com/embed/JZPSwBfWAao",
      },
      {
        type: types.INPUT,
        key: "title",
        value: "Découvrez le nouveau site officiel de l'Agence Nationale des Titres Sécurisés.",
      },
      {
        type: types.INPUT,
        key: "mobileTitle",
        value: "Découvrez le nouveau site officiel de l'ANTS.",
      },
      {
        type: types.INPUT,
        key: "buttonNextStepLabel",
        value: "Qu'est-ce qui change ?",
      },
      {
        type: types.INPUT,
        key: "buttonGoToSiteLabel",
        value: "Accéder au site",
      },
      {
        type: types.INPUT,
        key: "desktopImageSrc",
        value: "https://i.ibb.co/L0t6mcs/wip.png",
      },
      {
        type: types.INPUT,
        key: "mobileImageSrc",
        value: "/bottom-nav.png",
      },
      {
        type: types.CHECKBOX,
        key: "isActive",
        value: "false",
      },
      {
        type: types.INPUT,
        key: "threshold",
        value: "3",
      },
      {
        type: types.INPUT,
        key: "resetDate",
        value: "",
      },
    ],
  },
  {
    label: "Plage de valeurs",
    type: types.SLIDER,
    value: "100",
    min: "0",
    max: "100",
    step: "10",
  },
  {
    label: "",
    type: types.SLIDER_INLINE,
    value: "50",
    min: "0",
    max: "100",
    step: "1",
  },
  {
    type: types.SOCIAL_NETWORK,
    value: "",
    children: [
      {
        key: "url",
        type: types.URL,
        value: "",
      },
      {
        key: "network",
        label: "Titre",
        type: types.INPUT,
        value: "",
      },
      {
        key: "icon",
        type: types.ICON,
        value: "",
      },
      {
        key: "appId",
        type: types.INPUT,
        value: "",
      },
    ],
  },
  {
    label: "Étape",
    type: types.STEP,
    children: [
      {
        key: "title",
        type: types.TEXT,
        value: "nouveau titre",
      },
      {
        key: "content",
        type: types.TEXT,
        value: "nouveau contenu",
      },
    ],
  },
  {
    label: "Étape",
    type: types.STEP_V2,
    children: [
      {
        key: "title",
        type: types.TEXT,
        value: "nouveau titre",
      },
      {
        key: "content",
        type: types.STEP_V2_CONTENT,
        value: "",
      },
    ],
  },
  {
    type: types.STEP_V2_CONTENT,
  },
  {
    label: "Sous-page prédéfinie",
    type: types.SUBPAGE,
    icon: "file-alt",
    iconDSFR: "file-text-line",
    editionModal: true,
  },
  {
    label: "Liste de sous pages",
    type: types.SUBPAGES_LIST,
    // editionModal: true,
    value: "",
    children: [
      {
        key: "pages",
        type: types.SUBPAGE,
      },
    ],
  },
  {
    label: "Sous-page administrable",
    type: types.DSFR_SUBPAGE,
    icon: "file-alt",
    iconDSFR: "article-line",
    editionModal: true,
    children: [
      {
        key: "template",
        type: types.SELECT,
        value: "",
        label: "Gabarit de la sous-page",
        options: [
          { label: "Actualités", value: "ALLNEWS" },
          { label: "Aide et Contact", value: "HELP" },
          { label: "Démarches en ligne", value: "PROCESSES" },
          { label: "Services", value: "PAGE_LINKS" },
          { label: "Tout savoir", value: "KNOW_EVERYTHING" },
          { label: "Template par défaut", value: "NO_TEMPLATE" },
        ],
      },
      {
        key: "title",
        type: types.EDITABLE_INPUT,
        value: "",
      },
      {
        key: "description",
        label: "Description",
        type: types.SHORT_TEXT,
        value: "",
      },
      {
        key: "link",
        type: types.LINK,
      },
      {
        key: "image",
        type: types.IMAGE,
      },
      {
        key: "specificCardsList",
        type: types.DSFR_CARDS_SPECIFIC_LIST,
      },
      {
        key: "cardsList",
        type: types.DSFR_CARDS_LIST,
      },
      {
        key: "specificTilesList",
        type: types.TILE_CARDS_SPECIFIC_LIST,
      },
      {
        key: "linksList",
        type: types.LINKS_LIST,
      },
      {
        key: "dynamicPart",
        type: types.DYNAMIC,
      },
    ],
  },
  {
    label: "Liste de sous pages",
    type: types.DSFR_SUBPAGES_LIST,
    value: "",
    children: [
      {
        key: "pages",
        type: types.DSFR_SUBPAGE,
      },
    ],
  },
  {
    type: types.MULTI_SUBPAGES_LIST,
  },
  {
    label: "Zone de texte",
    type: types.TEXT,
    icon: "text",
    iconDSFR: "h-1",
    value: "",
  },
  {
    label: "Mise en exergue",
    key: "highlighted",
    iconDSFR: "highlight",
    type: types.HIGHLIGHTED_TEXT,
    value: "",
  },
  {
    label: "Historique",
    type: types.TIMELINE,
    IconComponent: TimelineIcon,
    children: [
      {
        label: "Étape",
        type: types.STEP_V2,
        key: "steps",
      },
    ],
  },
  {
    label: "Etape",
    type: types.STEPPER_STEP,
    editionModal: true,
    children: [
      {
        label: "Titre",
        key: "title",
        type: types.INPUT,
        value: "Mon titre",
      },
      {
        label: "Informations complémentaires",
        key: "tooltip",
        type: types.INPUT,
        value: "",
      },
      {
        label: "Icône (associée aux informations complémentaires)",
        key: "icon",
        type: types.ICON,
        value: "",
        children: [
          {
            key: "icon",
            value: "info",
          },
          {
            key: "type",
            value: "fas",
          },
        ],
      },
      {
        key: "detail",
        type: types.STEPPER_DETAIL,
      },
    ],
  },
  {
    type: types.STEPPER_DETAIL,
  },
  {
    label: "Onglets",
    type: types.TABS,
    children: [
      {
        key: "tabs",
        type: types.TAB,
      },
    ],
  },
  {
    label: "Onglets",
    type: types.TABS_CARDS,
    children: [
      {
        key: "tabs",
        type: types.TAB_CARDS,
      },
    ],
  },
  {
    label: "Onglets",
    type: types.TABS_TILE_CARDS,
    children: [
      {
        key: "tabs",
        type: types.TAB_TILE_CARDS,
      },
    ],
  },
  {
    type: types.TAB,
    label: "Onglet",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "Titre de l'onglet",
      },
      {
        key: "children",
        type: types.DYNAMIC,
      },
    ],
  },
  {
    type: types.DSFR_TILE_CARDS_LIST,
  },
  {
    type: types.TAB_CARDS,
    label: "Onglet",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "Titre de l'onglet",
      },
      {
        key: "children",
        type: types.CARDS_LIST,
      },
    ],
  },
  {
    type: types.TAB_TILE_CARDS,
    label: "Onglet",
    children: [
      {
        key: "title",
        type: types.INPUT,
        value: "Titre de l'onglet",
      },
      {
        key: "children",
        type: types.DSFR_TILE_CARDS_LIST,
        children: [
          {
            key: "specific",
            value: "true",
          },
        ],
      },
    ],
  },
  {
    type: types.URL,
    label: "Url",
  },
  {
    type: types.VIDEO,
    editionModal: true,
    icon: "play-circle",
    iconDSFR: "play-circle-line",
    label: "Vidéo",
    children: [
      {
        key: "source",
        type: types.SELECT,
        value: videosOrigins.VIMEO,
        label: "Source",
        options: [
          { label: "Dailymotion", value: videosOrigins.DAILYMOTION },
          { label: "Vimeo", value: videosOrigins.VIMEO },
          { label: "Youtube", value: videosOrigins.YOUTUBE },
        ],
      },
      {
        type: types.URL,
        key: "src",
        value: "",
      },
      {
        key: "title",
        label: "Titre de la vidéo (modal + accessibilité)",
        type: types.INPUT,
        value: "",
      },
      {
        key: "legend",
        label: "Légende de la vidéo",
        type: types.EDITABLE_INPUT,
        value: "",
      },
      {
        key: "openButtonLabel",
        label: "Label du bouton (pour afficher la transcription)",
        type: types.EDITABLE_INPUT,
        value: "Transcription",
      },
      {
        key: "closeButtonLabel",
        label: "Label du bouton (pour masquer la transcription)",
        type: types.EDITABLE_INPUT,
        value: "Transcription",
      },
      {
        key: "transcription",
        type: types.TEXT,
        value: "",
      },
    ],
  },
];

// Get a random number for ids
export const generateContentId = (content, updatedIds = {}) => {
  const newId = uuidv4();
  if (content.id && typeof updatedIds === "object") {
    // eslint-disable-next-line no-param-reassign
    updatedIds[content.id] = newId;
  }
  const contentWithId = { ...content, id: newId };
  if (content && content.children) {
    contentWithId.children = content.children.map((child) => generateContentId(child, updatedIds));
  }
  return contentWithId;
};

export const enhanceContent = (content = {}) => {
  const { type, children = [] } = content;
  const defaultContent = contents.find((c) => c.type === type);

  const enhancedContent = {
    ...defaultContent,
    ...content,
  };

  // enchanced children
  const enhancedChildren = children.map((child) => {
    const defaultChild = ((defaultContent && defaultContent.children) || []).find((c) => c.key === child.key) || {};
    return enhanceContent({
      ...defaultChild,
      ...child,
    });
  });

  // add defaults children not founds
  ((defaultContent && defaultContent.children) || []).forEach((defaultChild, index) => {
    const defaultChildAlreadyExists = enhancedChildren.find((c) => c.key === defaultChild.key);
    if ((defaultChild.value !== undefined || defaultChild.children) && !defaultChildAlreadyExists) {
      enhancedChildren.splice(index, 0, generateContentId(enhanceContent(defaultChild)));
    }
  });
  enhancedContent.children = enhancedChildren;
  return {
    ...enhancedContent,
    value: enhancedContent.value || "",
  };
};

export const replacePagesByIds = (content, updatedIds = {}) => {
  const { type, value, children } = content;
  const newContent = { ...content };

  if ([types.PAGE, types.SUBPAGE, types.DSFR_SUBPAGE].includes(type) && typeof value === "object") {
    newContent.value = value.page ? value.page.id : "";
  } else if (type === types.CONTENTS_GROUP) {
    newContent.children = children.map((child) => {
      if (child.key === "contents") {
        return {
          ...child,
          value: updatedIds[child.value] || child.value,
          children: [],
        };
      }
      return child;
    });
  } else if (children && children.length) {
    newContent.children = children.map((child) => replacePagesByIds(child, updatedIds));
  }
  return newContent;
};

export const getDefaultContentByType = (type) => enhanceContent(contents.find((content) => content.type === type));

export const getAllDefaultContents = () => contents.map(enhanceContent);

export const adminProperties = ["label", "options", "editionModal", "icon", "iconDSFR", "min", "max", "step", "width"];

export default {};
