import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import SearchBar from "components/templates/comedec/SearchBar";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import Hidden from "components/templatesComponents/Hidden";
import { ImageContainer } from "components/templatesComponents/Image";
import Modal from "components/templatesComponents/Modal";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ComedecService from "services/ComedecService";
import { getTranslateIndex } from "utils/commonUtils";
import t from "utils/locales/translation.json";
import { accentNormalize } from "utils/urlUtils";

const redStyled = {
  "& > b": {
    color: "red",
  },
};

const FRANCE = "1";
const ABROAD = "2";

const Comedec = (props) => {
  const { page = {} } = props;

  const { title, shortDescription, image, contents } = page;
  const { dynamicPart } = contents;

  const { language } = useContext(LanguageContext);

  const [country, setCountry] = useState(FRANCE);
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState({});
  const [departments, setDepartments] = useState([]);
  const [returnCode, setReturnCode] = useState("");
  const [messageCityName, setMessageCityName] = useState("");
  const [messageDepartmentName, setMessageDepartmentName] = useState("");
  const [displayHelpModal, setDisplayHelpModal] = useState(false);
  const [getDepartmentsError, setGetDepartmentsError] = useState(false);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(true);

  const { nom: departmentName, code: postalCode } = department || {};

  const { nom: cityName, codeDepartement } = city || {};

  const getDepartments = async () => {
    try {
      const newDepartments = await ComedecService.getDepartments();
      setDepartments(newDepartments);
      setIsLoadingDepartments(false);
    } catch (e) {
      setGetDepartmentsError(true);
      setIsLoadingDepartments(false);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (!getDepartmentsError && codeDepartement) {
      setDepartment(Array.isArray(departments) && departments.find((dep) => dep.code === codeDepartement));
    }
  }, [departments, codeDepartement, getDepartmentsError]);

  const checkComedec = () => {
    if (window && cityName) {
      ComedecService.getCityInfos({ nom: accentNormalize(cityName.toUpperCase()), codeDepartement }).then(
        (response) => {
          // eslint-disable-next-line global-require
          const XMLParser = require("react-xml-parser");
          const json = new XMLParser().parseFromString(response);
          const children = json.children[0].children[0]?.children;
          const code = Array.isArray(children) && children.find((c) => c.name === "code_retour");
          setReturnCode(code ? code.value : "");
          setMessageCityName(cityName);
          setMessageDepartmentName(departmentName);
        }
      );
    }
  };

  let messages;

  switch (returnCode) {
    case "1":
      messages = [
        {
          key: "1",
          value: (
            <>
              {t[language].comedec.common_message} <b>{messageCityName}</b>
              {messageDepartmentName ? ` (${messageDepartmentName})` : ""} {t[language].comedec.no_demat_message1}
            </>
          ),
        },
        {
          key: "2",
          value: t[language].comedec.no_demat_message2,
        },
      ];
      break;
    case "2":
    case "3":
      messages = [
        {
          key: "1",
          value: (
            <>
              {t[language].comedec.common_message} <b>{messageCityName}</b>
              {messageDepartmentName ? ` (${messageDepartmentName})` : ""} {t[language].comedec.demat_message1}
            </>
          ),
        },
        {
          key: "2",
          value: <b>{t[language].comedec.demat_message2}</b>,
        },
        {
          key: "3",
          value: t[language].comedec.demat_message3,
        },
      ];
      break;
    default:
      break;
  }

  return (
    <Wrapper
      wrapperProps={{
        sx: {
          position: "relative",
          pb: 8,
          maxWidth: "1440px",
          mx: "auto",
        },
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} lg={image ? 6 : 12}>
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
          {shortDescription && <Block>{shortDescription}</Block>}
        </Grid>
      </Grid>
      {image && (
        <Hidden lgDown>
          <Box
            sx={{
              position: "absolute",
              top: 16,
              right: 0,
              width: "40%",
              display: { xs: "none", lg: "block" },
            }}
          >
            <ImageContainer ratio={48}>{image}</ImageContainer>
          </Box>
        </Hidden>
      )}
      {dynamicPart && (
        <Grid item xs={12} lg={image ? 6 : 12}>
          <Block>{dynamicPart}</Block>
        </Grid>
      )}
      <Block>
        <Grid container alignItems="center" spacing={4}>
          <Grid item className="fr-text--bold" sx={redStyled}>
            {t[language].comedec.country_label} : <b>*</b>
          </Grid>
          <Grid item xs component={FormControl}>
            <RadioGroup name="country" value={country} onChange={(e) => setCountry(e.target.value)} row>
              <FormControlLabel
                value={FRANCE}
                control={<Radio color="secondary" />}
                label={t[language].comedec.france}
              />
              <FormControlLabel
                value={ABROAD}
                control={<Radio color="secondary" />}
                label={t[language].comedec.foreign}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Block>
      {country === FRANCE && (
        <Block>
          <Grid container direction="column" spacing={2}>
            <Grid item className="fr-text--bold">
              {t[language].comedec.search_label} :
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={4} lg={2}>
                {t[language].comedec.department}
              </Grid>
              <Grid item xs lg={5}>
                <Grid container alignItems="center" sx={{ position: "relative" }}>
                  <Grid item xs>
                    <FormControl error={getDepartmentsError}>
                      <Select
                        onChange={(e) => setDepartment(e.target.value)}
                        color="secondary"
                        size="small"
                        name="department"
                        value={department}
                        displayEmpty
                        sx={{
                          width: "100%",
                          "&.Mui-error": {
                            border: "1px solid",
                            borderColor: "error.main",
                          },
                          "& .MuiSelect-select": {
                            py: 2.25,
                          },
                        }}
                        disabled={isLoadingDepartments || getDepartmentsError}
                        title={department?.nom || t[language].comedec.department_title}
                        MenuProps={{ getContentAnchorEl: null }}
                      >
                        <MenuItem value="">{t[language].comedec.all_departments}</MenuItem>
                        {Array.isArray(departments) &&
                          departments.map((d) => (
                            <MenuItem key={d.code} value={d}>
                              {d.code}&nbsp;-&nbsp;{d.nom}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    title={t[language].comedec.tooltip_label}
                    onClick={() => setDisplayHelpModal(true)}
                    sx={{
                      textAlign: "center",
                      bgcolor: "secondary.main",
                      borderRadius: 5,
                      py: 1,
                      px: 2,
                      fontSize: "1rem",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    ?
                  </Grid>
                </Grid>
                {getDepartmentsError && (
                  <FormHelperText error sx={{ position: "absolute" }}>
                    {t[language].comedec.api_error_message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item container alignItems="center" spacing={2} sx={{ pt: getDepartmentsError ? 4.5 : 1 }}>
              <Grid item xs={4} lg={2} sx={redStyled}>
                {t[language].comedec.city} <b>*</b>
              </Grid>
              <Grid item xs lg={5}>
                <SearchBar postalCode={postalCode} codeDepartement={codeDepartement} setCity={setCity} />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} lg={7} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => checkComedec()}
                  disabled={!cityName || !codeDepartement}
                  title={t[language].common.search.button_title}
                  sx={{ borderRadius: 1, py: 0.75, px: 2.5, mr: 1 }}
                >
                  {t[language].comedec.button_label}
                </Button>
              </Grid>
            </Grid>
            {returnCode && (
              <Grid
                item
                xs={12}
                lg={7}
                container
                spacing={2}
                sx={{ mt: 2, justifyContent: { xs: "center", lg: "flex-start" } }}
              >
                {Array.isArray(messages) &&
                  messages.map((message) => (
                    <Grid item xs={10} lg={12} key={message.key}>
                      {message.value}
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
          <Box
            component="p"
            sx={{ position: "absolute", bottom: 0, fontStyle: "italic", ...redStyled }}
            role="presentation"
          >
            <b>*</b> : {t[language].common.required_fields[getTranslateIndex(2)]}
          </Box>
        </Block>
      )}
      {country === ABROAD && (
        <Block>
          <p>{t[language].comedec.foreign_message}</p>
        </Block>
      )}
      <Modal
        aria-labelledby="admin-content"
        aria-describedby="content-administration"
        open={displayHelpModal}
        onClose={() => setDisplayHelpModal(false)}
        size="md"
        sx={{ p: 4, "& > p": { pb: 1 } }}
      >
        <p>{t[language].comedec.modal_message}</p>
        <ul>
          <li>{t[language].comedec.modal_li1}</li>
          <li>{t[language].comedec.modal_li2}</li>
          <li>{t[language].comedec.modal_li3}</li>
        </ul>
      </Modal>
    </Wrapper>
  );
};

Comedec.propTypes = {
  page: PropTypes.shape(),
};

export default Comedec;
