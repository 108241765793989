import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";
import EulerianService from "services/EulerianService";
import { getFileExtension, getFileNameWithoutExtension, getSize } from "utils/commonUtils";
import { getFilenameFromUrl, normalized } from "utils/urlUtils";

const Attachment = (props) => {
  const { title = "", linkText = "", file = { name: "", url: "", size: null }, description = "", link = false } = props;
  let { name, url } = file || {};
  const { id: fileId, size } = file || {};

  const extensionFile = getFileExtension(name)?.replace(".", "") || "";

  const sendTag = () => {
    EulerianService.sendClick({ download: [`${extensionFile}::download::${title || "piece jointe"}`] });
  };

  if ((name || title) && url && (url.startsWith(process.env.ged_public_url) || url.startsWith("/files"))) {
    const filename = getFilenameFromUrl(url);
    const fileNameWithoutExtension = getFileNameWithoutExtension(filename);
    if (!name) {
      name = `${normalized(title)}${getFileExtension(filename)}`;
    }
    if (name !== getFileExtension(name)) {
      url = url.replace(filename, `${fileNameWithoutExtension}/${name}`);
    }
  } else if (!name && url) {
    name = getFilenameFromUrl(url);
  }

  return (
    <Box
      className={`fr-download${!link ? " fr-enlarge-link fr-download--card" : ""}`}
      sx={link ? { pb: url ? 3 : 0 } : {}}
    >
      <p>
        <Box
          component="a"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download={name && name !== getFileExtension(name) ? name : fileId}
          className="fr-download__link"
          onClick={sendTag}
          sx={{ cursor: !link || url ? "pointer" : "initial", "&:focus": { outline: "2px solid #0a76f6" } }}
          tabIndex={0}
        >
          {linkText}
          {(name || size) && (
            <span className="fr-download__detail">
              {extensionFile.toUpperCase()} {name && size && "– "}
              {getSize({ size, unity: 0 })}
            </span>
          )}
        </Box>
      </p>
      {!link && (
        <p className="fr-download__desc" role="presentation">
          {description}
        </p>
      )}
    </Box>
  );
};

Attachment.propTypes = {
  title: PropTypes.string,
  linkText: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    size: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  description: PropTypes.string,
  link: PropTypes.bool,
};

export default Attachment;
