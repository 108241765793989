import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import CountCard from "components/templatesComponents/CountCard";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CounterService from "services/CounterService";

const jsonCache =
  typeof localStorage !== "undefined" && localStorage.getItem("compteur_FI")
    ? JSON.parse(localStorage.getItem("compteur_FI"))
    : {};

const Standard = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { firstDynamicPart, secondDynamicPart } = contents;
  const [loading, setLoading] = useState(true);
  const [counterInfos, setCounterInfos] = useState(jsonCache);

  const counterInformations = useMemo(() => {
    return (
      Object.keys(counterInfos)
        ?.filter((info) => !process.env.counter_hidden_informations.includes(info))
        ?.map((key) => ({
          name: key,
          number: counterInfos[key],
        })) || []
    );
  }, [counterInfos]);

  const getJsonFromUrl = useCallback(async () => {
    try {
      const json = await CounterService.getCounterInfos();
      setCounterInfos(json);
      setLoading(false);
      setTimeout(() => getJsonFromUrl(), process.env.counter_restart_time * 60 * 1000);
    } catch (e) {
      setLoading(false);
      setTimeout(() => getJsonFromUrl(), process.env.counter_restart_time * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    if (process.env.counter_url && process.env.counter_secret && process.env.counter_restart_time) {
      getJsonFromUrl();
    }
  }, [getJsonFromUrl]);

  useEffect(() => {
    if (typeof localStorage !== "undefined" && Object.keys(counterInfos).length > 0) {
      localStorage.setItem("compteur_FI", JSON.stringify(counterInfos));
    }
  }, [counterInfos]);

  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>
      {shortDescription && <Block>{shortDescription}</Block>}
      {firstDynamicPart}
      <Grid container justifyContent="center" spacing={3} sx={{ mb: 2, px: { xs: 2, lg: 0 }, "& .fr-h5": { mt: 2 } }}>
        {loading && <p className="fr-h5">Chargement des données...</p>}
        {!loading &&
          (counterInformations.length > 0 ? (
            counterInformations.map((info) => <CountCard information={info} />)
          ) : (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <p className="fr-h6">Le compteur France identité est actuellement indisponible...</p>
              <p className="fr-h6">Désolé pour la gêne occasionnée</p>
            </Box>
          ))}
      </Grid>
      {secondDynamicPart}
    </Wrapper>
  );
};

Standard.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Standard;
