import CardContext from "components/templatesComponents/CardContext";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";

const CardList = (props) => {
  const { desktopStyleKey = styleKeys.LIGHT_BIG, mobileStyleKey = styleKeys.LIGHT_LIGHT, cards = null } = props;

  const currentCardContext = useContext(CardContext);

  const newContextValue = useMemo(
    () => ({
      desktopStyleKey,
      mobileStyleKey,
      ...currentCardContext,
    }),
    [currentCardContext, desktopStyleKey, mobileStyleKey]
  );

  if (!cards || cards.length === 0) {
    return null;
  }

  return <CardContext.Provider value={newContextValue}>{cards}</CardContext.Provider>;
};

CardList.propTypes = {
  desktopStyleKey: PropTypes.string,
  mobileStyleKey: PropTypes.string,
  cards: PropTypes.node,
};

export default CardList;
