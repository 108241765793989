import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiModal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import React from "react";

export const ModalActions = (props) => {
  return <Stack direction="row" justifyContent="flex-end" spacing={1} {...props} />;
};

const Modal = (props) => {
  const { children = null, onClose, size = null, alignItems = "center", sx = {}, ...others } = props;

  return (
    <MuiModal onClose={onClose} sx={sx} {...others}>
      <Grid
        container
        justifyContent="center"
        alignItems={alignItems}
        sx={{
          position: "absolute",
          top: alignItems === "flex-start" ? "56px" : "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
        onClick={onClose}
      >
        <Paper
          onClick={(e) => e.stopPropagation()}
          sx={(theme) => ({
            width: `${size && theme.breakpoints.values[size] ? `${theme.breakpoints.values[size]}px` : "auto"}`,
            maxWidth: `100%`,
            maxHeight: "95%",
            overflow: "auto",
          })}
        >
          <Box p={2}>{children}</Box>
        </Paper>
      </Grid>
    </MuiModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  alignItems: PropTypes.oneOf(["flex-start", "center"]),
};

export default Modal;
