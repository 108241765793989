import axios from "axios";
import useAxiosCache from "hooks/axiosCache";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PageService from "services/PageService";

const PageVersionContext = React.createContext({
  currentPageVersion: {},
  setCurrentPageVersion: () => {},
  loading: false,
});
export default PageVersionContext;

axios.interceptors.request.use(
  (config) => {
    if (config.url === "/getPageInfos" && config.params && config.params.pathname === "/rechercher") {
      return {
        adapter: () =>
          new Promise((resolve) => {
            resolve({ data: {} });
          }),
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const PageVersionContextProvider = withRouter((props) => {
  const { location, children } = props;
  const [{ data: page, loading }] = useAxiosCache(PageService.getConfig("getPageInfos", location.pathname));

  const [currentPageVersion, setCurrentPageVersion] = useState(page || {});

  useEffect(() => {
    setCurrentPageVersion(page);
  }, [page]);

  const handleChangeCurrentVersion = (pageVersion) => {
    if (pageVersion && pageVersion.id !== page.id) {
      setCurrentPageVersion(pageVersion);
    }
  };

  return (
    <PageVersionContext.Provider
      value={{
        currentPageVersion,
        setCurrentPageVersion: handleChangeCurrentVersion,
        loading,
      }}
    >
      {children}
    </PageVersionContext.Provider>
  );
});
