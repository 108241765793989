import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const DSFRList = (props) => {
  let { cards = [] } = props;

  if (cards && !Array.isArray(cards)) {
    cards = [cards];
  }

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={{ xs: 3, md: 1 }} sx={{ "&&": { mt: 0 } }}>
      {cards}
    </Grid>
  );
};

DSFRList.propTypes = {
  cards: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
};

export default DSFRList;
