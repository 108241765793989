import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DSFRListContext from "components/DSFRListContext";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import SiteService from "services/SiteService";

const DSFRTile = (props) => {
  const { link = {}, title = "", description = "", icon = "", isHorizontal } = props;
  const { page: pageVersion = {} } = link || {};
  const { page = {} } = pageVersion;
  let { contents = [] } = pageVersion;
  const { siteId } = page;

  if (!Array.isArray(contents)) {
    contents = [contents];
  }

  const { xs, sm, lg } = useContext(DSFRListContext);

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const findSiteById = useMemo(() => allSites?.find((site) => site.id === siteId), [allSites, siteId]);

  const { name: siteNameById } = findSiteById || {};

  const logoSite = icon || siteNameById;

  const { id: linkId, ...linkProps } = link;

  return (
    <Grid item xs={xs} sm={isHorizontal ? 12 : sm} lg={isHorizontal ? 12 : lg}>
      <Box
        className={`fr-tile${isHorizontal ? " fr-tile--horizontal" : ""} fr-enlarge-link`}
        sx={{
          height: "100%",
          "& .fr-tile__title": {
            mb: isHorizontal ? 1 : 0,
            fontSize: "1.125rem",
            "& a:after": isHorizontal ? {} : { content: "none" },
          },
          "& .fr-tile__content": isHorizontal ? {} : { pb: "0px !important" },
        }}
      >
        <div className="fr-tile__body">
          <div className="fr-tile__content">
            <h6 className="fr-tile__title">
              <Link button {...linkProps} sx={{ wordBreak: "break-word" }}>
                {title}
              </Link>
            </h6>
            {isHorizontal && description && (
              <p className="fr-tile__desc" role="presentation">
                {description}
              </p>
            )}
          </div>
        </div>
        {logoSite && !isHorizontal && (
          <div className="fr-tile__img">
            <img src={logoSite ? `/logo-${logoSite}.svg` : "/default-image.png"} className="fr-responsive-img" alt="" />
          </div>
        )}
      </Box>
    </Grid>
  );
};

DSFRTile.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  link: PropTypes.shape(),
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

export default DSFRTile;
