import Stack from "@mui/material/Stack";
import Wrapper from "components/LayoutFront/Wrapper";
import LastNewsBlock from "components/templatesComponents/LastNewsBlock";
import SeparatorDSFR from "components/templatesComponents/SeparatorDSFR";
import PropTypes from "prop-types";
import React from "react";

const HomeNewsDsfr = (props) => {
  const { page: pageVersion = {} } = props;
  const { contents, page } = pageVersion;
  const { lang } = page;
  const { highlightedNews, homeButton } = contents;

  return (
    <>
      <SeparatorDSFR />
      <Wrapper>
        <Stack
          direction={{ md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", lg: "center" }}
          spacing={2}
          sx={{ mt: 2, mb: 3, "& h2": { flex: 1 }, "& a": { backgroundImage: "none", ml: 3 } }}
        >
          <h2>Actualités</h2>
          {homeButton}
        </Stack>
        <LastNewsBlock highlightedNews={highlightedNews} lang={lang} />
      </Wrapper>
    </>
  );
};

HomeNewsDsfr.propTypes = {
  page: PropTypes.shape(),
};

export default HomeNewsDsfr;
