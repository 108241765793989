import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import KnowEverythingNews from "components/templates/knowEverything/KnowEverythingNews";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import HorizontalScroll from "components/templatesComponents/HorizontalScroll";
import PageTitle from "components/templatesComponents/PageTitle";
import RectangleBackground from "components/templatesComponents/RectangleBackground";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Separator from "components/templatesComponents/Separator";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const KnowEverything = (props) => {
  const { page = {} } = props;
  const { title, contents } = page;
  const { topNews, highlighted, allNewsTitle, regulationsTitle, regulations } = contents;
  let { allNews = [] } = contents;
  if (allNews === "") {
    allNews = [];
  }
  if (!Array.isArray(allNews)) {
    allNews = [allNews];
  }

  allNews = allNews.map((news) => <div role="listitem">{news}</div>);

  const subPageContextValue = useMemo(() => ({ subTemplate: KnowEverythingNews }), []);

  return (
    <>
      <Wrapper>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        <Hidden lgDown>
          {highlighted}
          {topNews}
          <Separator />
        </Hidden>
      </Wrapper>
      <RectangleBackground height="400px" position="left" width={0.65}>
        <Wrapper sx={{ pt: { lg: 8 } }}>
          <Block sx={{ pb: { lg: 0 } }}>
            <SubPageContext.Provider value={subPageContextValue}>
              <SectionTitle sx={{ mb: 4 }}>{allNewsTitle}</SectionTitle>
              <Hidden lgDown>
                <Box mx={{ lg: -9.5 }} role="list">
                  <HorizontalScroll items={allNews} />
                </Box>
              </Hidden>
              <Hidden lgUp>
                {highlighted}
                {topNews}
                {allNews}
              </Hidden>
            </SubPageContext.Provider>
          </Block>
        </Wrapper>
      </RectangleBackground>
      <Hidden lgDown>
        <Separator />
      </Hidden>
      <Wrapper>
        <Block sx={{ pb: { md: 1 } }}>
          <SectionTitle sx={{ mb: { xs: 0, lg: 2 } }}>{regulationsTitle}</SectionTitle>
        </Block>
      </Wrapper>
      <Wrapper>
        <Block sx={{ "& div:not(:first-child)": { mt: 2 } }}>
          {(regulations || []).map((reg) => (
            <div key={reg.id}>
              <h3>{reg.title}</h3>
              {reg.decrees}
            </div>
          ))}
        </Block>
      </Wrapper>
    </>
  );
};

KnowEverything.propTypes = {
  page: PropTypes.shape(),
};

export default KnowEverything;
