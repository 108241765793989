import MuiExpandLessIcon from "@mui/icons-material/ExpandLess";
import MuiExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";
import Icon from "components/templatesComponents/Icon";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import t from "utils/locales/translation.json";

const buttonContainerStyled = {
  pt: 2,
  display: "flex",
  "& a": {
    px: 2,
    py: 1,
    border: "1px solid",
    borderColor: "secondary.main",
    mr: 1,
    color: "inherit",
    textDecoration: "none",
    backgroundImage: "none",
  },
};

const InfoGeoloc = (props) => {
  const { geoPointItem = {}, cityPosition, source = null } = props;

  const { type = "" } = geoPointItem._source || {};

  const { language } = useContext(LanguageContext);

  const { category = "", others } = geoPointItem._source || {};

  const {
    name = "",
    address = "",
    address2 = "",
    postalCode = "",
    city = "",
    phone = "",
    email = "",
    webSite = "",
    numVoie = "",
    typeVoie = "",
    libelleVoie = "",
  } = others || {};

  const { basic, specific } = categories[type] || {};

  const [isToggleOn, setToggle] = useState(false);

  const week = (t[language].geolocalisation.week_days || "").split(",");

  const d = new Date();
  const today = d.getDay() - 1;

  const webSiteUrl = !webSite.startsWith("https://") && !webSite.startsWith("http://") ? `https://${webSite}` : webSite;

  const addressToDisplay =
    source && source === "siv" ? `${numVoie} ${typeVoie} ${libelleVoie}` : `${address} ${address2}`;

  const cityToDisplay = `${postalCode} ${city}`;

  const distanceToDisplay = (geoPoint) => {
    if (geoPoint.sort && geoPoint.sort.length > 0) {
      if (geoPoint.sort[0] === 0) {
        return t[language].geolocalisation.near;
      }
      const convertDistance = (language === "FR" ? 1 : 0.621371) * geoPoint.sort[0];
      return `${Number.parseFloat(convertDistance).toFixed(1)} ${t[language].geolocalisation.from} ${cityPosition}`;
    }
    return "";
  };

  const iconTitle =
    category?.toLowerCase() === specific ? t[language].geolocalisation[specific] : t[language].geolocalisation[basic];
  const iconColor = category?.toLowerCase() === specific ? "#F32121" : "#396FF1";

  const iconDSFR = `${source === "mairie" ? "bank" : "camera"}-${
    category?.toLowerCase() === specific ? "fill" : "line"
  }`;

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{ position: "relative", mb: 1 }}
      >
        <Box component="h3" className="fr-h6 fr-mb-0" sx={{ wordBreak: "break-word" }} role="presentation">
          {name}
        </Box>
        {type !== "siv" && (
          <>
            <Box aria-label={iconTitle} sx={{ position: "absolute", right: 12 }} />
            <Icon icon="circle" type="fas" iconDSFR={iconDSFR} sx={{ color: iconColor }} title={iconTitle} />
          </>
        )}
      </Grid>
      <p>
        {addressToDisplay} {cityToDisplay}
      </p>
      <p className="fr-text--xs fr-mt-1w">{distanceToDisplay(geoPointItem)}</p>
      {phone && (
        <Link
          linkComponent
          url={`tel:${phone}`}
          icon={{ iconDSFR: "phone-line" }}
          iconPosition="left"
          title={t[language].geolocalisation.phone}
        >
          {phone}
        </Link>
      )}
      {geoPointItem.opening &&
        geoPointItem.opening.length > 0 &&
        geoPointItem.opening.reduce((hasValue, opening) => hasValue || !!opening, false) && (
          <Grid
            container
            onClick={() => setToggle(!isToggleOn)}
            sx={{ cursor: "pointer", mt: { xs: 2, lg: 1 }, fontSize: "0.77rem" }}
          >
            <Grid item sx={{ maxWidth: "87%" }} role="list">
              {Array.isArray(week) &&
                week.map((day, i) => (
                  <div key={day} role="listitem">
                    {i === today ? (
                      <Box sx={{ display: "flex" }}>
                        <p>
                          {day} :{" "}
                          {!geoPointItem.opening[i] ? (
                            <i>{t[language].geolocalisation.no_schedule}</i>
                          ) : (
                            geoPointItem.opening[i]
                          )}
                        </p>{" "}
                      </Box>
                    ) : (
                      <Box component="p" sx={{ display: isToggleOn ? "" : "none" }} role="presentation">
                        {day} :{" "}
                        {!geoPointItem.opening[i] ? (
                          <i>{t[language].geolocalisation.no_schedule}</i>
                        ) : (
                          geoPointItem.opening[i]
                        )}
                      </Box>
                    )}
                  </div>
                ))}
            </Grid>
            <Grid item sx={{ ml: 0.5 }}>
              <Box component="button" type="button" sx={{ p: 0, display: "flex" }}>
                {isToggleOn ? <MuiExpandLessIcon /> : <MuiExpandMoreIcon />}
              </Box>
            </Grid>
          </Grid>
        )}
      {(email || webSite) && (
        <Box role="list" sx={buttonContainerStyled}>
          {email && (
            <a
              href={`mailto:${email}`}
              title={t[language].geolocalisation.mail}
              className="fr-link fr-icon-mail-line fr-link--icon-left"
              role="listitem"
            >
              {t[language].geolocalisation.mail}
            </a>
          )}
          {webSite && (
            <Link external url={webSiteUrl} title={t[language].geolocalisation.site} role="listitem">
              <Icon icon="globe" iconDSFR="earth-line" sx={{ mr: 1 }} />
              {t[language].geolocalisation.site}
            </Link>
          )}
        </Box>
      )}
    </>
  );
};

InfoGeoloc.propTypes = {
  geoPointItem: PropTypes.shape().isRequired,
  cityPosition: PropTypes.string.isRequired,
  source: PropTypes.string,
};

export default InfoGeoloc;
