import Box from "@mui/material/Box";
import React from "react";

export default React.createContext({
  Wrapper: Box,
  wrapperProps: {},
  styleOverride: {},
  desktopStyleOverride: {},
  mobileStyleOverride: {},
});
