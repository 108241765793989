import Wrapper from "components/LayoutFront/Wrapper";
import PropTypes from "prop-types";
import React from "react";

const Governance = (props) => {
  const { governersTitle, dynamicPart = null } = props;

  return (
    <>
      <Wrapper>
        <h2>{governersTitle}</h2>
      </Wrapper>

      <Wrapper>{dynamicPart}</Wrapper>
    </>
  );
};

Governance.propTypes = {
  governersTitle: PropTypes.node.isRequired,
  dynamicPart: PropTypes.node,
};

export default Governance;
