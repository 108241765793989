import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import languages from "utils/languagesTypes";

const LanguageContext = React.createContext({
  language: "",
  setLanguage: () => null,
});
export default LanguageContext;

export const LanguageContextProvider = (props) => {
  const { children = null } = props;

  const [language, setLanguage] = useState(
    (typeof localStorage !== "undefined" && localStorage.getItem("language") !== "null"
      ? localStorage.getItem("language")
      : Object.keys(languages)[0]) || Object.keys(languages)[0]
  );

  const languageContextValue = useMemo(() => ({ language, setLanguage }), [language, setLanguage]);

  return <LanguageContext.Provider value={languageContextValue}>{children}</LanguageContext.Provider>;
};

LanguageContextProvider.propTypes = {
  children: PropTypes.node,
};
