import Counter from "components/templates/counter/Counter";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "COUNTER",
  label: "Compteur France Identité",
  Component: Counter,
  initialContents: [
    {
      key: "firstDynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
    {
      key: "secondDynamicPart",
      type: elementsTypes.DYNAMIC,
      value: "",
      children: [],
    },
  ],
};
