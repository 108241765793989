import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import { shortDescriptionStyled } from "components/templates/home/HomeWho";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import { ImageContainer } from "components/templatesComponents/Image";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const linksStyled = {
  width: { xs: "100%", lg: "calc(100% + 16px)" },
  m: { lg: -1 },
  zIndex: { lg: 1 },
  mt: { lg: -5 },
  "& > div": {
    px: 1,
    py: { xs: 0.5, lg: 1 },
  },
};

const HomeMissions = (props) => {
  const { page = {} } = props;
  const { title, shortDescription, image, contents } = page;
  const { pageLinks } = contents;

  const cardContextValue = useMemo(
    () => ({
      Wrapper: Grid,
      wrapperProps: {
        item: true,
        xs: 12,
        md: 4,
        container: true,
      },
      desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
      mobileStyleKey: styleKeys.HORIZONTAL,
      desktopStyleOverride: {
        root: {
          bgcolor: "primary.50",
          "a&:hover": {
            bgcolor: "primary.50",
          },
        },
      },
      mobileStyleOverride: {
        title: {
          fontWeight: 600,
        },
      },
    }),
    []
  );

  return (
    <SubTemplateWrapper backgroundColor={{ xs: "transparent", lg: "#f1f1f1" }} title={title} right>
      <Box sx={{ position: { lg: "relative" }, pt: { lg: 2 } }}>
        <Box sx={{ width: { lg: "60%" }, position: { lg: "absolute" }, top: -90, right: 0, zIndex: { lg: -1 } }}>
          <ImageContainer ratio={55}>{image}</ImageContainer>
        </Box>
        <Box sx={{ width: { lg: "50%" } }}>
          <Box sx={shortDescriptionStyled}>{shortDescription}</Box>
        </Box>
        <Grid container justifyContent="center" alignItems="stretch" sx={linksStyled}>
          <CardContext.Provider value={cardContextValue}>{pageLinks?.props?.cards}</CardContext.Provider>
        </Grid>
      </Box>
    </SubTemplateWrapper>
  );
};

HomeMissions.propTypes = {
  page: PropTypes.shape(),
};

export default HomeMissions;
