import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardDsfrContext from "components/CardDsfrContext";
import DSFRListContext from "components/DSFRListContext";
import LanguageContext from "components/LanguageContext";
import Link from "components/templatesComponents/Link";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import PageService from "services/PageService";
import SiteService from "services/SiteService";
import { getSiteName, numericFormatDate } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const DSFRCard = (props) => {
  const { link = {}, title } = props;
  const { page: pageVersion = null } = link || {};
  const { page = {}, image, pageId } = pageVersion || {};
  const { originalPageId, siteId, lang, firstPublishedDate } = page || {};

  const { language } = useContext(LanguageContext);
  const { showImage, showBadge, showDetail, hasGreyBackground } = useContext(CardDsfrContext);
  const { xs, sm, lg } = useContext(DSFRListContext);

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const findSiteById = useMemo(() => allSites?.find((s) => s.id === siteId), [allSites, siteId]);
  const { name: siteNameById } = findSiteById || {};

  const [{ data: frenchPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: lang === "FR" ? pageId : originalPageId,
      filters: [`page.lang||eq||FR`],
    })
  );

  const { firstPublishedDate: frenchPublishDate } = frenchPnuPages[0] || {};

  const date = lang === "FR" ? firstPublishedDate : frenchPublishDate || firstPublishedDate;

  const colorClass = useMemo(() => {
    switch (siteNameById) {
      case "IMMAT":
      case "PHA":
        return "orange-terre-battue";
      case "PERMIS":
      case "AUTO_ECOLE":
        return "purple-glycine";
      case "PASSEPORT_CNI":
      case "MAIRIE":
        return "green-archipel";
      default:
        return "";
    }
  }, [siteNameById]);

  const { id: linkId, ...linkProps } = link;

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      lg={lg}
      sx={{
        "& .fr-enlarge-link": hasGreyBackground
          ? {
              bgcolor: "var(--background-alt-grey)",
              "&:hover": {
                bgcolor: "var(--background-contrast-grey-hover)",
              },
            }
          : {},
        "& .fr-card__title": { fontSize: "1.25rem" },
      }}
    >
      <div className="fr-card fr-enlarge-link">
        <div className="fr-card__body">
          <div className="fr-card__content">
            <h4 className="fr-card__title">
              <Link button {...linkProps} sx={{ wordBreak: "break-word" }}>
                {title}
              </Link>
            </h4>
            {(showBadge || showDetail) && (
              <div className="fr-card__start">
                {firstPublishedDate && showDetail && (
                  <p className="fr-card__detail fr-icon-calendar-event-fill" role="presentation">
                    {" "}
                    {t[language].common.news.published_label} {numericFormatDate({ date, lang })}
                  </p>
                )}
                {showBadge && siteNameById && (
                  <Box component="p" className={`fr-badge${colorClass ? ` fr-badge--${colorClass}` : ""}`} mb={1}>
                    {getSiteName(siteNameById)}
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>
        {showImage && (
          <div className="fr-card__header">
            <div className="fr-card__img">
              <img
                className="fr-responsive-img"
                src={image?.url || "/default-image.png"}
                alt={image?.alt}
                style={{ objectFit: "unset" }}
              />
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
};

DSFRCard.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  link: PropTypes.shape(),
};

export default DSFRCard;
