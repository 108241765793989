import Grid from "@mui/material/Grid";
import DSFRListContext from "components/DSFRListContext";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const ResultList = (props) => {
  let { results = [] } = props;

  if (results && !Array.isArray(results)) {
    results = [results];
  }

  let xs = 6;
  let lg = 3;
  const size = React.Children.count(results);
  if (size === 1) {
    xs = 12;
    lg = 6;
  } else if (size === 2) {
    lg = 6;
  } else if (size === 3) {
    lg = 4;
  }

  const resultListContextValue = useMemo(() => ({ xs, lg }), [xs, lg]);

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <DSFRListContext.Provider value={resultListContextValue}>
      <Grid
        container
        sx={(theme) => ({
          "& > div:nth-of-type(even)": {
            borderLeft: { xs: `solid 2px ${theme.palette.primary.light}`, lg: "none" },
          },
          "& > div:nth-of-type(n+3)": {
            borderTop: { xs: `solid 2px ${theme.palette.primary.light}`, lg: "none" },
          },
          "& > div:not(:last-of-type):not(:nth-of-type(4n)) .gridBorder": {
            borderRight: { xs: "none", lg: `solid 2px ${theme.palette.primary.light}` },
          },
        })}
      >
        {results}
      </Grid>
    </DSFRListContext.Provider>
  );
};

ResultList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape()),
};

export default ResultList;
