import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import HomeLastNews from "components/templates/home/HomeLastNews";
import HomeProcesses from "components/templates/homeProcessSites/HomeProcesses";
import HomeServices from "components/templates/homeProcessSites/HomeServices";
import lastNews from "components/templates/news/lastNews";
import pageLinks from "components/templates/pageLinks/pageLinks";
import processes from "components/templates/processes/processes";
import Block from "components/templatesComponents/Block";
import Separator from "components/templatesComponents/Separator";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const getSubTemplate = (k) => {
  switch (k) {
    case pageLinks.key:
      return HomeServices;
    case processes.key:
      return HomeProcesses;
    case lastNews.key:
      return HomeLastNews;
    default:
      return null;
  }
};

const HomeProcessSites = (props) => {
  const { page = {} } = props;
  const { contents } = page;
  const { subpages, newsTitle, newsSubpage } = contents;

  const subPageContextValue = useMemo(() => ({ getSubTemplate }), []);

  return (
    <SubPageContext.Provider value={subPageContextValue}>
      {subpages}
      <Separator />
      <Block>
        <Wrapper>
          <Box component="h2" className="fr-h1" mb={3}>
            {newsTitle}
          </Box>
          {newsSubpage}
        </Wrapper>
      </Block>
    </SubPageContext.Provider>
  );
};

HomeProcessSites.propTypes = {
  page: PropTypes.shape(),
};

export default HomeProcessSites;
