import Grid from "@mui/material/Grid";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import Block from "components/templatesComponents/Block";
import LastNewsBlock from "components/templatesComponents/LastNewsBlock";
import PropTypes from "prop-types";
import React from "react";

const HomeLastNews = (props) => {
  const { page: pageVersion = {} } = props;
  const { title, contents, page } = pageVersion;
  const { lang } = page;
  const { highlightedNews, homeButton } = contents;

  return (
    <SubTemplateWrapper title={title}>
      <Block>
        <LastNewsBlock highlightedNews={highlightedNews} lang={lang} />
        <Grid container item xs={12} sx={{ mt: 7 }}>
          <button type="button" className="fr-btn fr-btn--tertiary" tabIndex="-1">
            {homeButton}
          </button>
        </Grid>
      </Block>
    </SubTemplateWrapper>
  );
};

HomeLastNews.propTypes = {
  page: PropTypes.shape(),
};

export default HomeLastNews;
