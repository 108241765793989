import CreateGroupIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdminContext from "components/adminComponents/AdminContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { enhanceContent, generateContentId } from "utils/adminContentsUtils";
import elementsTypes from "utils/elementsTypes";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";

export const defaultContext = {
  buttonLabel: "Gérer des groupes de contenus",
  title: "Gérer des groupes de contenus",
  subTitle: "",
  types: [],
  getLabelFromComponent: (component) => component.key,
  getLabelFromContent: (content) => content.key,
};

export const Context = createContext(defaultContext);

const getAvailableContents = (contents = [], types = [], usedContentsIds = []) => {
  const list = [];
  const extractContents = (cts) => {
    if (Array.isArray(cts)) {
      cts.forEach((c) => {
        if (c.type !== elementsTypes.CONTENTS_GROUPS_LIST) {
          if ((types.includes(c.type) || types.length === 0) && !usedContentsIds.includes(c.id)) {
            list.push(c);
          }
          if (c.children) {
            extractContents(c.children);
          }
        }
      });
    }
  };
  extractContents(contents);
  return list;
};

const Form = (props) => {
  const { content, onContentChange } = props;
  const { title, subTitle, types, getLabelFromComponent, getLabelFromContent } = useContext(Context);
  const { currentPageVersion } = useContext(AdminContext);
  const { contents: pageContents } = currentPageVersion;

  const usedContentsIds = [];
  content.children.forEach((group) => {
    if (group.children) {
      group.children.forEach((child) => {
        if (child.key === "contents") {
          usedContentsIds.push(child.value);
        }
      });
    }
  });

  const availableContents = getAvailableContents(pageContents, types, usedContentsIds);

  const [newGroupName, setNewGroupName] = useState("");

  let { groups } = generateTemplatePropsFromContents(content.children);

  if (groups && !Array.isArray(groups)) {
    groups = [groups];
  }

  const handleChangeNewGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  const handleSubmitNewName = (e) => {
    e.preventDefault();
    onContentChange({
      ...content,
      children: [
        ...content.children,
        generateContentId({
          type: elementsTypes.CONTENTS_GROUP,
          key: "groups",
          value: "",
          children: [
            {
              type: elementsTypes.INPUT,
              key: "name",
              value: newGroupName,
            },
          ],
        }),
      ],
    });
    setNewGroupName("");
  };

  const handleSelectContent = (group) => (e) => {
    const { value } = e.target;
    onContentChange({
      ...content,
      children: [
        ...content.children.map((c) => {
          if (c.id !== group.id) {
            return c;
          }
          return {
            ...c,
            children: [
              ...c.children,
              generateContentId({
                ...value,
                value: value.id,
                key: "contents",
              }),
            ],
          };
        }),
      ],
    });
  };

  const handleClickRemoveGroup = (group) => () => {
    onContentChange({
      ...content,
      children: [...content.children.filter((c) => c.id !== group.id)],
    });
  };

  const handleClickRemoveContentFromGroup = (idToRemove, group) => () => {
    onContentChange({
      ...content,
      children: [
        ...content.children.map((c) => {
          if (c.id !== group.id) {
            return c;
          }
          return {
            ...c,
            children: c.children.filter((child) => child.id !== idToRemove),
          };
        }),
      ],
    });
  };

  const swapArrayItems = (array, index1, index2) => {
    const newArray = [...array];
    if (index1 !== -1 && index2 !== -1) {
      const tmpItem = newArray[index1];
      newArray[index1] = newArray[index2];
      newArray[index2] = tmpItem;
    }
    return newArray;
  };

  const handleMoveGroup =
    (group, up = false) =>
    () => {
      const { children } = content;
      const groupToMove = children.find((child) => child.id === group.id);
      const groupIndex = children.indexOf(groupToMove);
      const targetIndex = up ? groupIndex - 1 : groupIndex + 1;
      onContentChange({
        ...content,
        children: swapArrayItems(children, groupIndex, targetIndex),
      });
    };

  const handleClickMoveGroupUp = (group) => handleMoveGroup(group, true);

  const handleClickMoveGroupDown = (group) => handleMoveGroup(group);

  const handleMoveContent =
    (idToMove, group, up = false) =>
    () => {
      onContentChange({
        ...content,
        children: [
          ...content.children.map((c) => {
            if (c.id !== group.id) {
              return c;
            }
            const { children } = c;
            const filteredChildren = children.filter((child) => child.key === "contents");
            const othersChildren = children.filter((child) => child.key !== "contents");
            const contentToMove = filteredChildren.find((child) => child.id === idToMove);
            const contentIndex = filteredChildren.indexOf(contentToMove);
            const targetIndex = up ? contentIndex - 1 : contentIndex + 1;
            return {
              ...c,
              children: [...othersChildren, ...swapArrayItems(filteredChildren, contentIndex, targetIndex)],
            };
          }),
        ],
      });
    };

  const handleClickMoveContentUp = (idToMove, group) => handleMoveContent(idToMove, group, true);

  const handleClickMoveContentDown = (idToMove, group) => handleMoveContent(idToMove, group);

  const renderGroup = (group, i, allGroups) => {
    const { name } = group;
    let { contents = [] } = group;

    if (contents && !Array.isArray(contents)) {
      contents = [contents];
    }

    return (
      <Box mb={2} key={group.id}>
        <div>
          {name}&nbsp;
          <IconButton onClick={handleClickMoveGroupUp(group)} edge="end" aria-label="Monter" disabled={i === 0}>
            <Icon icon="chevron-up" iconDSFR="arrow-up-s-line" title="Monter" />
          </IconButton>
          <IconButton
            onClick={handleClickMoveGroupDown(group)}
            edge="end"
            aria-label="Descendre"
            disabled={i === allGroups.length - 1}
          >
            <Icon icon="chevron-down" iconDSFR="arrow-down-s-line" title="Descendre" />
          </IconButton>
          <IconButton onClick={handleClickRemoveGroup(group)} aria-label="Supprimer">
            <Icon icon="trash" iconDSFR="delete-line" title="Supprimer" />
          </IconButton>
        </div>
        <List dense sx={{ p: 0 }}>
          {contents.map((c, index) => (
            <ListItem key={c.props.id}>
              <ListItemText primary={getLabelFromComponent(c)} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={handleClickMoveContentUp(c.props.id, group)}
                  edge="end"
                  aria-label="monter"
                  disabled={index === 0}
                >
                  <Icon icon="chevron-up" iconDSFR="arrow-up-s-line" title="Monter" />
                </IconButton>
                <IconButton
                  onClick={handleClickMoveContentDown(c.props.id, group)}
                  edge="end"
                  aria-label="Descendre"
                  disabled={index === contents.length - 1}
                >
                  <Icon icon="chevron-down" iconDSFR="arrow-down-s-line" title="Descendre" />
                </IconButton>
                <IconButton
                  onClick={handleClickRemoveContentFromGroup(c.props.id, group)}
                  edge="end"
                  aria-label="Supprimer"
                >
                  <Icon icon="trash" iconDSFR="delete-line" title="Supprimer" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          <ListItem>
            <ListItemText
              primary={
                <Select
                  value=""
                  onChange={handleSelectContent(group)}
                  displayEmpty
                  disableUnderline
                  sx={{ fontSize: "1em" }}
                  MenuProps={{ getContentAnchorEl: null }}
                >
                  <MenuItem value=""> -- Ajouter un contenu dans ce groupe -- </MenuItem>
                  {availableContents.map((c) => (
                    <MenuItem key={c.id} value={c}>
                      {getLabelFromContent(c)}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  return (
    <>
      <Box component="h2" sx={{ textAlign: "center" }}>
        {title}
      </Box>
      {subTitle && (
        <Box component="p" sx={{ textAlign: "center", mb: 2, fontSize: "0.9em", color: "#666", fontStyle: "italic" }}>
          {subTitle}
        </Box>
      )}
      {groups && groups.map(renderGroup)}
      <form onSubmit={handleSubmitNewName}>
        <Input
          placeholder="Ajouter un groupe"
          value={newGroupName}
          // disableUnderline
          onChange={handleChangeNewGroupName}
          endAdornment={
            <InputAdornment position="end" onClick={handleSubmitNewName}>
              <IconButton>
                <CreateGroupIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    </>
  );
};

Form.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

const AdminContentsGroups = (props) => {
  const { content, onContentChange } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [fullContent, setFullContent] = useState({});

  const { buttonLabel } = useContext(Context);

  useEffect(() => {
    if (modalOpened) {
      setFullContent(enhanceContent(content));
    }
  }, [modalOpened, content]);

  const handleButtonClick = () => {
    setModalOpened(true);
  };

  const handleClose = () => {
    setModalOpened(false);
  };

  const handleClickValidate = () => {
    onContentChange(fullContent);
    setModalOpened(false);
  };

  return (
    <>
      <Box py={3}>
        <Button onClick={handleButtonClick} icon={{ icon: "cog", iconDSFR: "settings-5-line" }} title={buttonLabel}>
          {buttonLabel}
        </Button>
      </Box>
      <Modal open={modalOpened} onClose={handleClose} size="md">
        <Form content={fullContent} onContentChange={setFullContent} />
        <ModalActions>
          <Button onClick={handleClose} outlined>
            Annuler
          </Button>
          <Button onClick={handleClickValidate} color="primary">
            Valider
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

AdminContentsGroups.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default AdminContentsGroups;
