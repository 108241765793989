import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import Hidden from "components/templatesComponents/Hidden";
import PropTypes from "prop-types";
import React from "react";

const History = (props) => {
  const { text = "", timelineTitle = "", timeline = null } = props;

  return (
    <Wrapper
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        "& > div": {
          flex: "1 1 0px",
        },
      }}
      leftProps={{ sx: { bgcolor: "primary.10" } }}
    >
      <Box sx={{ bgcolor: "primary.10", py: { lg: 4 }, pr: { lg: 10 } }}>{text}</Box>
      <Box>
        <Hidden lgUp>
          <Box component="h2" my={4}>
            {timelineTitle}
          </Box>
        </Hidden>
        <Box ml={{ lg: -1.25 }}>{timeline}</Box>
      </Box>
    </Wrapper>
  );
};

History.propTypes = {
  text: PropTypes.node,
  timelineTitle: PropTypes.node,
  timeline: PropTypes.node,
};

export default History;
