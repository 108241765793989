import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Captcha from "components/Captcha";
import HoneyPot from "components/HoneyPot";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import SiteContext from "components/SiteContext";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import CardContext from "components/templatesComponents/CardContext";
import Hidden from "components/templatesComponents/Hidden";
import Icon from "components/templatesComponents/Icon";
import SectionTitle from "components/templatesComponents/SectionTitle";
import Select from "components/templatesComponents/Select";
import Tabs from "components/templatesComponents/Tabs";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import ContactService from "services/ContactService";
import EulerianService from "services/EulerianService";
import {
  checkEmailRules,
  checkFrenchNameRules,
  checkMessageLength,
  eventKey,
  getTranslateIndex,
  immatCycloRegex,
  immatSivRegex,
  permisRegex,
  stringToSnakeCaseString,
} from "utils/commonUtils";
import t from "utils/locales/translation.json";
import { stringToPath } from "utils/urlUtils";

const selectFormStyled = {
  bgcolor: "white",
  borderRadius: 0,
  p: 2,
  minHeight: "56px",
  fontSize: "1em",
  "&:hover": {
    borderColor: "componentColors.30",
  },
  "&.Mui-focused": {
    borderColor: "primary.main",
  },
  "&.Mui-error": {
    borderColor: "error.main",
  },
  "& .MuiSelect-select": {
    pt: 0.75,
    pb: 0.875,
    pl: 0,
  },
};

const inputFormStyled = {
  bgcolor: "white",
  border: "1px solid",
  borderColor: "componentColors.30",
  p: 2,
  minHeight: "56px",
  fontSize: "1em",
  "&.Mui-focused": {
    borderColor: "primary.main",
  },
  "&.Mui-error": {
    borderColor: "error.main",
  },
};

const iconStyled = { fontSize: "1.3rem", color: "secondary.main", pr: 1.5 };

const HelpForm = (props) => {
  const { page } = props;
  const { title, image, contents, id: pageId } = page;
  const {
    questionTitle,
    othersQuestionsTitle,
    othersQuestions = {},
    contactTitle,
    contactText,
    contactBtn,
    contactUsTitle,
    ContactFormProfilesList = {},
    ContactFormSubjectsList = {},
    contactForm,
    contactPhone = {},
    contactDigitalPoints = {},
  } = contents;

  let { tabs: othersQuestionsTabs = [] } = othersQuestions;

  const displayTabsCards = typeof othersQuestionsTitle === "object" || othersQuestionsTabs.length > 0;

  const displayTabsCardsBlock = questionTitle || othersQuestionsTitle || displayTabsCards;

  const {
    id: contactPhoneId,
    title: contactPhoneTitle,
    subtitle: contactPhoneSubtitle,
    detail: contactPhoneDetail = {},
  } = contactPhone;

  const { phone1, phone2, phone3, deafAccess } = contactPhoneDetail;

  const { title: contactDigitalPointsTitle, detail: contactDigitalPointsDetail = {} } = contactDigitalPoints;

  const { text, digitalPoint } = contactDigitalPointsDetail;

  const bo = useMemo(() => typeof contactTitle === "object", [contactTitle]);

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { name: siteName } = site;

  const captchaRef = useRef(null);

  const honeyKey = process.env.honey_key;

  // Others questions
  if (!Array.isArray(othersQuestionsTabs)) {
    othersQuestionsTabs = [othersQuestionsTabs];
  }
  othersQuestionsTabs = othersQuestionsTabs.map((tab) => ({
    ...tab,
    url: stringToPath(tab.title),
    children: (
      <Grid container item xs={12} lg={6} spacing={isMobile ? 1 : 2} role="list">
        {tab.children}
      </Grid>
    ),
  }));

  const modes = useMemo(() => {
    const contact = [];
    if (Object.keys(contactPhone).length) {
      contactPhone.icons = [{ icon: "phone" }];
      contact.push(contactPhone);
    }
    if (Object.keys(contactDigitalPoints).length) {
      contactDigitalPoints.icons = [{ icon: "building" }];
      contact.push(contactDigitalPoints);
    }
    return contact;
  }, [contactDigitalPoints, contactPhone]);

  const [expandedContactModeItem, setExpandedContactModeItem] = useState(null);
  const isOpen = (contactMode) =>
    expandedContactModeItem && contactMode && contactMode.id === expandedContactModeItem.id;
  const handleContactModeClick = (mode) => {
    if (expandedContactModeItem === mode) {
      setExpandedContactModeItem(null);
    } else {
      setExpandedContactModeItem(mode);

      if (typeof contactPhoneTitle === "string" && mode.id === contactPhoneId) {
        EulerianService.sendClick({
          button: [`button::click::${contactPhoneTitle.toLowerCase()}`],
        });
      }
    }
  };

  const isContactModeClickDisabled = useMemo(() => !isMobile && modes.length === 1, [isMobile, modes]);

  useEffect(() => {
    if (isContactModeClickDisabled) {
      setExpandedContactModeItem(modes[0]);
    }
  }, [modes, isContactModeClickDisabled]);

  // Contact form
  const [form, setForm] = useState({
    name: "",
    firstName: "",
    profile: "",
    email: "",
    subject: "",
    numImmat: "",
    numPermis: "",
    birthDate: "",
    message: "",
  });
  const [fileForm, setFileForm] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // 4 states : 0 = selectSubject, 1 = auto reply, 2 = contact us, 3 = sent
  const [formState, setFormState] = useState(0);
  const { id: profileId } = ContactFormProfilesList;
  const { id: subjectId } = ContactFormSubjectsList;
  const [loader, setLoader] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [mailSendActivated, setMailSendActivated] = useState(false);
  const [sendTag, setSendTag] = useState(false);
  const [captchaBlacklisted, setCaptchaBlacklisted] = useState(false);

  let { ContactFormProfiles = [] } = ContactFormProfilesList;
  if (ContactFormProfiles && !Array.isArray(ContactFormProfiles)) {
    ContactFormProfiles = [ContactFormProfiles];
  }

  let { ContactFormSubjects = [] } = ContactFormSubjectsList;
  if (ContactFormSubjects && !Array.isArray(ContactFormSubjects)) {
    ContactFormSubjects = [ContactFormSubjects];
  }

  const cardContextValue = useMemo(
    () => ({
      desktopStyleKey: styleKeys.HORIZONTAL,
      mobileStyleKey: styleKeys.HORIZONTAL,
      Wrapper: Grid,
      wrapperProps: {
        item: true,
        xs: 12,
        role: "listitem",
      },
    }),
    []
  );

  useEffect(() => {
    if (form.subject !== "") {
      setMailSendActivated(form.subject && !form.subject.autoReply && process.env.mail_send_enable !== "true");
    }
  }, [form.subject]);

  const fileType = fileForm && fileForm.type.split("/");
  const authorizedFileType = process.env.mail_allowed_attachment_type.includes(fileType && fileType[1]);

  const errors = useMemo(() => {
    const { name, firstName, profile, email, subject, numImmat, numPermis, birthDate, message } = form;
    const notAutoReplySubject = !subject.autoReply || formState === 2;

    return {
      name: notAutoReplySubject && !checkFrenchNameRules({ key: name, length: 100 }),
      firstName: notAutoReplySubject && !checkFrenchNameRules({ key: firstName, length: 40 }),
      profile: notAutoReplySubject && !profile,
      email: notAutoReplySubject && !checkEmailRules({ key: email }),
      subject: !subject,
      numImmat:
        notAutoReplySubject &&
        ["IMMAT", "PHA"].includes(siteName) &&
        numImmat &&
        !(numImmat.match(immatSivRegex) || numImmat.match(immatCycloRegex)),
      numPermis:
        notAutoReplySubject &&
        ["PERMIS", "AUTO_ECOLE"].includes(siteName) &&
        numPermis &&
        !numPermis.match(permisRegex),
      birthDate:
        notAutoReplySubject &&
        siteName === "PERMIS" &&
        (!birthDate ||
          Number.isNaN(new Date(birthDate).getTime()) ||
          !(Date.parse(birthDate) > 0) ||
          !(Date.parse(birthDate) < Date.parse("10000-01-01"))),
      message: notAutoReplySubject && !checkMessageLength({ key: message }),
      fileForm:
        subject &&
        !subject.autoReply &&
        fileForm &&
        fileForm.size &&
        (fileForm.size >= process.env.mail_attachment_size || !authorizedFileType),
    };
  }, [form, formState, siteName, fileForm, authorizedFileType]);

  const showError = (key) => formSubmitted && errors[key];
  const InputAdornmentError = (
    <InputAdornment position="end">
      <Icon
        icon="exclamation-circle"
        iconDSFR="error-warning-line"
        sx={{ color: "error.main", fontSize: "1.5rem" }}
        title={t[language].help.error}
      />
    </InputAdornment>
  );

  const dateToSend = useMemo(() => {
    if (form.birthDate) {
      const date = new Date(form.birthDate);
      return `${date.getDate()}/${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}/${date.getFullYear()}`;
    }
    return "";
  }, [form.birthDate]);

  const handleFormChange = (e) => {
    const { value, name: fieldName } = e.target;
    setForm({
      ...form,
      [fieldName]: fieldName === "numImmat" ? value.toUpperCase() : value,
    });
  };

  const ScrollSent = () => {
    scrollIntoView(document.getElementById("contactForm"), {
      block: "nearest",
      inline: "nearest",
    });
  };

  const isFormValid = Object.values(errors).reduce((a, b) => a && !b, true);

  const sendMail = (token) => {
    const formData = new FormData();
    if (fileForm) {
      formData.append("attachment", fileForm, fileForm.name);
    }
    formData.append("siteName", siteName);
    formData.append(honeyKey, document.querySelector(`#${honeyKey}`)?.value);
    formData.append("name", form.name);
    formData.append("firstName", form.firstName);
    formData.append("email", form.email);
    formData.append("message", form.message);
    formData.append("profileTitle", form.profile && form.profile.title);
    formData.append("subjectTitle", form.subject && form.subject.title);
    formData.append("numImmat", form.numImmat);
    formData.append("numPermis", form.numPermis);
    formData.append("birthDate", dateToSend);
    formData.append("contentId", subjectId);
    formData.append("pageId", pageId);
    formData.append("subjectId", form.subject && form.subject.id);
    formData.append("tokenCaptcha", token);
    if (form.subject && (!form.subject.autoReply || formState === 2)) {
      ContactService.postMail(formData)
        .then((response) => {
          if (response && response.status && (response.status === 400 || response.status === 401)) {
            setSendError(response.message);
          } else {
            setFormState(3);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setFormState(0);
          setSendError(t[language].help.error_server);
        });
    } else {
      setFormState(1);
      setLoader(false);
    }
  };

  const checkCaptcha = async () => {
    if (captchaRef && captchaRef.current) {
      const tokenCode = captchaRef.current.getTokenCode();
      // 102 : blacklisted
      if (tokenCode === "102") {
        setSendError(t[language].components.captcha_blacklist_message);
        setCaptchaBlacklisted(true);
        setLoader(false);
        return;
      }
      const token = captchaRef.current.getToken();
      if (token === null) {
        setLoader(false);
        captchaRef.current.setCaptchaErrorMessage(t[language].components.captcha_error_message);
        return;
      }
      captchaRef.current.setCaptchaErrorMessage("");
      sendMail(token);
      if (form.subject.autoReply && formState === 0) {
        EulerianService.sendPage(
          {
            page_name: "affichage_reponse_automatique",
            page_category2: stringToSnakeCaseString(title),
            path: "/affichage_reponse_automatique",
            referrer: typeof document !== "undefined" ? document?.location?.pathname : "",
            page_title: "affichage_reponse_automatique",
          },
          siteName
        );
      } else {
        EulerianService.sendClick({ button: [`button::action::envoyer votre message`] });
      }
    } else {
      setLoader(false);
      captchaRef.current.setCaptchaErrorMessage(t[language].components.captcha_error_message);
    }
  };

  const handleValidate = () => {
    ScrollSent();
    setFormSubmitted(true);
    if (isFormValid) {
      setLoader(true);
      if (!process.env.liveidentity_key) {
        sendMail();
      } else {
        checkCaptcha();
      }
    }
  };

  const handleUploadFile = (e) => {
    const fileToUpload = e.target.files[0];
    setFileForm(fileToUpload);
  };

  const handleBackToForm = () => {
    setForm({
      ...form,
      subject: "",
      message: "",
    });
    setFormSubmitted(false);
    setFormState(0);
    EulerianService.sendClick({ button: [`button::click::retour au formulaire`] });
  };

  const handleContactUs = () => {
    setFormSubmitted(false);
    setFormState(2);
  };

  const handleKeyDown = (event, mode) => {
    if (event.key === eventKey) {
      handleContactModeClick(mode);
    }
  };

  const handleClickDigitalPoint = () => {
    EulerianService.sendClick({ button: [`button::click::trouver un point numerique`] });
  };

  const handleClickDeafAccess = () => {
    EulerianService.sendClick({ button: [`button::click::acces sourds malentendants`] });
  };

  return (
    <>
      {displayTabsCardsBlock && (
        <Box sx={{ pt: { xs: 0, lg: 8 }, position: "relative", bgcolor: { lg: "componentColors.10" } }}>
          <Wrapper sx={{ zIndex: 1, position: "relative" }}>
            <Block>
              {questionTitle && <SectionTitle>{questionTitle}</SectionTitle>}
              {othersQuestionsTitle && (
                <Box component="h3" mb={{ xs: 1.5, lg: 2.5 }} className={questionTitle ? "" : "fr-h2"}>
                  {othersQuestionsTitle}
                </Box>
              )}
              {displayTabsCards && (
                <Box sx={{ mb: 1, minHeight: { lg: 350 } }}>
                  <CardContext.Provider value={cardContextValue}>
                    <Tabs
                      {...othersQuestions}
                      navigate={!bo}
                      variant="scrollable"
                      tabProps={{
                        sx: {
                          minWidth: 0,
                          p: 0,
                          mr: 4,
                          textTransform: "none",
                          fontSize: "0.875rem",
                          "button&:hover": {
                            bgcolor: "transparent",
                          },
                          "&.Mui-selected": {
                            color: "inherit",
                          },
                          "&.Mui-focusVisible": {
                            border: "2px solid #0a76f6",
                          },
                        },
                      }}
                      tabs={othersQuestionsTabs}
                      sendTag={sendTag}
                      setSendTag={setSendTag}
                    />
                  </CardContext.Provider>
                </Box>
              )}
            </Block>
          </Wrapper>
          <Hidden lgDown>
            {image && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  top: questionTitle ? "264px" : "204px",
                  right: 0,
                  bottom: 0,
                  width: "68%",
                }}
              >
                {image}
              </Stack>
            )}
          </Hidden>
        </Box>
      )}
      <Wrapper sx={{ py: { lg: 8 } }}>
        <Block>
          <SectionTitle>{contactTitle}</SectionTitle>
          {contactText && <Box mb={2}>{contactText}</Box>}
          {contactBtn && <Box>{contactBtn}</Box>}
          {(contactText || contactBtn) && <Box sx={{ mb: 4 }}> </Box>}
          <Box component="h3" mb={{ xs: 1, lg: 3 }}>
            {contactUsTitle}
          </Box>
          <Grid container direction="column" spacing={4}>
            {/* FORMULAIRE */}
            <Grid item container>
              <Grid item xs={12} lg={4} container direction="column">
                <Stack
                  direction={{ lg: "row" }}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    bgcolor: "primary.main",
                    "& *": { color: "common.white" },
                    px: { xs: 1, lg: 4 },
                    pt: { xs: 1, lg: 4 },
                    pb: { xs: 1, lg: 0 },
                    cursor: { xs: "pointer", lg: "default" },
                  }}
                  onClick={() => handleContactModeClick(contactForm)}
                  id="contactForm"
                >
                  <Hidden lgUp>
                    <Icon
                      icon="file-alt"
                      iconDSFR="file-text-line"
                      title={t[language].help.form}
                      sx={{ fontSize: "1.5rem", p: 1 }}
                    />
                  </Hidden>
                  <Box
                    component="h4"
                    className={isMobile ? "" : "fr-h3"}
                    role="presentation"
                    sx={{ color: "common.white", textAlign: { xs: "center", lg: "left" } }}
                  >
                    {contactForm.title}
                  </Box>
                  <Hidden lgUp>
                    <Icon
                      icon={isOpen(contactForm) ? "chevron-up" : "chevron-down"}
                      iconDSFR={isOpen(contactForm) ? "arrow-up-s-line" : "arrow-down-s-line"}
                      isHidden={false}
                      aria-label={isOpen(contactForm) ? t[language].common.close : t[language].common.open}
                    />
                  </Hidden>
                </Stack>
                <Box
                  sx={{
                    bgcolor: { xs: "white", lg: "primary.main" },
                    display: { xs: isOpen(contactForm) && formState === 0 ? "block" : "none", lg: "block" },
                    flex: "1 1 auto",
                    borderLeft: { xs: "1px solid", lg: "none" },
                    borderRight: { xs: "1px solid", lg: "none" },
                    borderColor: "componentColors.30",
                    pb: { lg: 3 },
                    "& *": {
                      color: { lg: "common.white" },
                    },
                  }}
                >
                  <Box
                    sx={{
                      fontSize: { xs: "1.1rem", lg: "1.25rem" },
                      fontWeight: 600,
                      px: { xs: 2, lg: 4 },
                      pt: { xs: 3, lg: 1 },
                      pb: { xs: 2, lg: 3 },
                    }}
                  >
                    {contactForm.subTitle}
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1.25}
                    sx={{
                      bgcolor: "#4E63C7",
                      color: "#fff",
                      fontWeight: 600,
                      px: 4,
                      py: 1,
                    }}
                  >
                    <Icon icon="clock" iconDSFR="time-line" title={t[language].help.deadline} />
                    <div>{contactForm.delay}</div>
                  </Stack>
                  <Box px={{ xs: 2, lg: 4 }} pt={2} pb={{ xs: 1, lg: 2 }} role="presentation">
                    {contactForm.description}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={8}
                container
                justifyContent="space-between"
                sx={{
                  display: { xs: isOpen(contactForm) ? "flex" : "none", lg: "flex" },
                  position: "relative",
                  p: { xs: 2, lg: 4 },
                  bgcolor: { xs: "white", lg: "primary.10" },
                  borderBottom: { xs: "1px solid", lg: "none" },
                  borderRight: { xs: "1px solid", lg: "none" },
                  borderLeft: { xs: "1px solid", lg: "none" },
                  borderColor: "componentColors.30",
                  "& form": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  component="form"
                  action="/"
                  method="POST"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleValidate();
                  }}
                  sx={{ display: [0, 2].includes(formState) ? "block" : "none" }}
                >
                  {loader && (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        bgcolor: "#eff4fd",
                        opacity: "0.5",
                        zIndex: 1000,
                        "& svg": {
                          color: "secondary.main",
                        },
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  <Box sx={{ position: "absolute", top: 16, left: 32, fontSize: "0.75rem" }}>
                    * {t[language].common.required_fields[getTranslateIndex(2)]}
                  </Box>
                  <Grid container spacing={3} sx={process.env.liveidentity_key ? {} : { p: 2 }}>
                    {formState !== 3 && (
                      <Grid item xs={12}>
                        <Captcha ref={captchaRef} id="sample-captcha" />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl error={showError("subject")} sx={{ "& > label": { mb: 2 } }}>
                        <label role="presentation" htmlFor="subject" className="fr-text--bold">
                          {t[language].help.object} *
                        </label>
                        <Select
                          name="subject"
                          id={subjectId}
                          value={form.subject}
                          onChange={handleFormChange}
                          fullWidth
                          displayEmpty
                          disabled={formState === 2}
                          endAdornment={showError("subject") && InputAdornmentError}
                          sx={selectFormStyled}
                          MenuProps={{ getContentAnchorEl: null }}
                          Wrapper={ContactFormSubjectsList.Wrapper}
                          wrapperProps={ContactFormSubjectsList.wrapperProps}
                        >
                          <MenuItem value="" disabled>
                            <em>{t[language].help.object_placeholder}</em>
                          </MenuItem>
                          {ContactFormSubjects.map(
                            (subject) =>
                              subject.title &&
                              (subject.recipient || subject.autoReply) && (
                                <MenuItem value={subject} key={subject.id}>
                                  {subject.title}
                                </MenuItem>
                              )
                          )}
                        </Select>
                        {showError("subject") && (
                          <FormHelperText>{t[language].common.required_fields[getTranslateIndex(1)]}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {(formState === 2 || (form?.subject && !form.subject.autoReply)) &&
                      process.env.mail_send_enable === "true" && (
                        <>
                          <Grid item xs={12} lg={6}>
                            <FormControl error={showError("name")}>
                              <label role="presentation" htmlFor="name" className="fr-text--bold">
                                {t[language].help.name} *
                              </label>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                value={form.name}
                                placeholder={t[language].help.name}
                                onChange={handleFormChange}
                                error={showError("name")}
                                disableUnderline
                                endAdornment={showError("name") && InputAdornmentError}
                                sx={inputFormStyled}
                                autoComplete="family-name"
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("name") && (
                                <FormHelperText>
                                  {form.name.length > 100
                                    ? `100 ${t[language].help.max_characters}`
                                    : t[language].help.name_error_message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormControl error={showError("firstName")}>
                              <label role="presentation" htmlFor="firstName" className="fr-text--bold">
                                {t[language].help.first_name} *
                              </label>
                              <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={form.firstName}
                                placeholder={t[language].help.first_name}
                                onChange={handleFormChange}
                                error={showError("firstName")}
                                disableUnderline
                                sx={inputFormStyled}
                                autoComplete="given-name"
                                endAdornment={showError("firstName") && InputAdornmentError}
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("firstName") && (
                                <FormHelperText>
                                  {form.firstName.length > 40
                                    ? `40 ${t[language].help.max_characters}`
                                    : t[language].help.first_name_error_message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl error={showError("profile")} sx={{ "& > label": { mb: 2 } }}>
                              <label role="presentation" htmlFor="profile" className="fr-text--bold">
                                {t[language].help.profil_label} *
                              </label>
                              <Select
                                name="profile"
                                id={profileId}
                                value={form.profile}
                                onChange={handleFormChange}
                                fullWidth
                                displayEmpty
                                endAdornment={showError("profile") && InputAdornmentError}
                                sx={selectFormStyled}
                                MenuProps={{ getContentAnchorEl: null }}
                                Wrapper={ContactFormProfilesList.Wrapper}
                                wrapperProps={ContactFormProfilesList.wrapperProps}
                              >
                                <MenuItem value="" disabled>
                                  <em>{t[language].help.profil_placeholder}</em>
                                </MenuItem>
                                {ContactFormProfiles.map(
                                  (profile) =>
                                    profile.title && (
                                      <MenuItem value={profile} key={profile.id}>
                                        {profile.title}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                              {showError("profile") && (
                                <FormHelperText>
                                  {t[language].common.required_fields[getTranslateIndex(1)]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl error={showError("email")}>
                              <label role="presentation" htmlFor="email" className="fr-text--bold">
                                {t[language].help.mail} *
                              </label>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                value={form.email}
                                placeholder={t[language].help.mail}
                                onChange={handleFormChange}
                                error={showError("email")}
                                disableUnderline
                                endAdornment={showError("email") && InputAdornmentError}
                                sx={inputFormStyled}
                                autoComplete="email"
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("email") && (
                                <FormHelperText>
                                  {form.email.length > 80
                                    ? `80 ${t[language].help.max_characters}`
                                    : t[language].help.mail_error_message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {["IMMAT", "PHA"].includes(siteName) && (
                            <Grid item xs={12}>
                              <FormControl error={showError("numImmat")}>
                                <label role="presentation" htmlFor="numImmat" className="fr-text--bold">
                                  {t[language].help.immat_number}
                                </label>
                                <Input
                                  type="text"
                                  name="numImmat"
                                  id="numImmat"
                                  value={form.numImmat}
                                  placeholder={t[language].help.immat_number}
                                  onChange={handleFormChange}
                                  error={showError("numImmat")}
                                  disableUnderline
                                  endAdornment={showError("numImmat") && InputAdornmentError}
                                  sx={inputFormStyled}
                                  inputProps={{
                                    spellCheck: "false",
                                  }}
                                />
                                {showError("numImmat") && (
                                  <FormHelperText>{t[language].help.immat_number_error_message}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          {["PERMIS", "AUTO_ECOLE"].includes(siteName) && (
                            <Grid item xs={12}>
                              <FormControl error={showError("numPermis")}>
                                <label role="presentation" htmlFor="numPermis" className="fr-text--bold">
                                  {t[language].help.license_number}
                                </label>
                                <Input
                                  type="text"
                                  name="numPermis"
                                  id="numPermis"
                                  value={form.numPermis}
                                  placeholder={t[language].help.license_number}
                                  onChange={handleFormChange}
                                  error={showError("numPermis")}
                                  disableUnderline
                                  endAdornment={showError("numPermis") && InputAdornmentError}
                                  sx={inputFormStyled}
                                  inputProps={{
                                    spellCheck: "false",
                                  }}
                                />
                                {showError("numPermis") && (
                                  <FormHelperText>{t[language].help.license_number_error_message}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          {siteName === "PERMIS" && (
                            <Grid item xs={12}>
                              <FormControl error={showError("birthDate")}>
                                <label role="presentation" htmlFor="birthDate" className="fr-text--bold">
                                  {t[language].help.birth_date} *
                                </label>
                                <Input
                                  type="date"
                                  name="birthDate"
                                  id="birthDate"
                                  value={form.birthDate}
                                  placeholder={t[language].help.birth_date}
                                  onChange={handleFormChange}
                                  error={showError("birthDate")}
                                  disableUnderline
                                  endAdornment={showError("birthDate") && InputAdornmentError}
                                  sx={inputFormStyled}
                                  inputProps={{
                                    spellCheck: "false",
                                  }}
                                />
                                {showError("birthDate") && (
                                  <FormHelperText>{t[language].help.birth_date_error_message}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <FormControl error={showError("message")}>
                              <label role="presentation" htmlFor="message" className="fr-text--bold">
                                {t[language].help.message} *
                              </label>
                              <Input
                                type="text"
                                name="message"
                                id="message"
                                value={form.message}
                                placeholder={t[language].help.message}
                                onChange={handleFormChange}
                                multiline
                                rows={6}
                                disableUnderline
                                error={showError("message")}
                                endAdornment={showError("message") && InputAdornmentError}
                                sx={inputFormStyled}
                                inputProps={{
                                  spellCheck: "false",
                                }}
                              />
                              {showError("message") && (
                                <FormHelperText>
                                  {form.message.length > 2000
                                    ? `2000 ${t[language].help.max_characters}`
                                    : t[language].common.required_fields[getTranslateIndex(1)]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              component="input"
                              accept={process.env.mail_allowed_attachment_type}
                              id="input-file-attachment"
                              name="input-file-attachment"
                              type="file"
                              sx={{ display: "none" }}
                              onChange={handleUploadFile}
                            />
                            <label role="presentation" htmlFor="input-file-attachment">
                              <Button component="span">
                                {`${t[language].help.pj_prefix} (${process.env.mail_allowed_attachment_type})`}
                              </Button>
                            </label>
                            {fileForm && fileForm.name && (
                              <FormHelperText>
                                <Icon icon="paperclip" iconDSFR="attachment-line" title={t[language].help.pj} />{" "}
                                {fileForm.name}{" "}
                                <Icon
                                  icon="times"
                                  iconDSFR="close-line"
                                  tabIndex={0}
                                  onClick={() => {
                                    setFileForm(null);
                                  }}
                                  onKeyDown={(e) => e.key === eventKey && setFileForm(null)}
                                  sx={{ pl: 1, cursor: "pointer", "&:hover": { color: "secondary.main" } }}
                                  isHidden={false}
                                  aria-label={t[language].help.pj_delete_message}
                                />
                              </FormHelperText>
                            )}
                            {showError("fileForm") && (
                              <FormHelperText error>
                                {fileForm.size >= process.env.mail_attachment_size && (
                                  <>
                                    <Icon
                                      icon="exclamation-circle"
                                      iconDSFR="error-warning-line"
                                      title={t[language].help.error}
                                    />
                                    &nbsp;{t[language].help.pj_error_message}
                                  </>
                                )}
                                {fileForm.size >= process.env.mail_attachment_size && !authorizedFileType && <br />}
                                {!authorizedFileType && (
                                  <>
                                    <Icon
                                      icon="exclamation-circle"
                                      iconDSFR="error-warning-line"
                                      title={t[language].help.error}
                                    />
                                    &nbsp;{t[language].help.pj_unauthorized_message}
                                  </>
                                )}
                              </FormHelperText>
                            )}
                          </Grid>
                        </>
                      )}

                    <HoneyPot name={honeyKey} />
                    {[0, 2].includes(formState) && (
                      <Grid item xs={12}>
                        <Button type="submit" disabled={captchaBlacklisted || mailSendActivated}>
                          {formState === 2 ||
                          (form && form.subject && !form.subject.autoReply && process.env.mail_send_enable === "true")
                            ? t[language].help.button_label
                            : t[language].components.validate_button_label}
                        </Button>
                        {sendError && (
                          <FormHelperText error>
                            <Icon
                              icon="exclamation-circle"
                              iconDSFR="error-warning-line"
                              title={t[language].help.error}
                            />
                            &nbsp;{sendError}
                          </FormHelperText>
                        )}
                        {mailSendActivated && (
                          <FormHelperText error>
                            <Icon
                              icon="exclamation-circle"
                              iconDSFR="error-warning-line"
                              title={t[language].help.error}
                            />
                            &nbsp;{t[language].help.send_mail_error_message}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {formState === 1 && (
                  <Box sx={{ width: "100%" }}>
                    <Box pb={2.5}>
                      <h5>{t[language].help.auto_reply_title}</h5>
                    </Box>
                    <Box pb={2.5}>
                      <b>{t[language].help.auto_reply_object_prefix} : </b>
                      {form.subject.title}
                    </Box>
                    <hr />
                    <Box component="p" pb={2.5}>
                      {t[language].help.auto_reply_body1},
                    </Box>
                    <Box pb={2.5}>{form.subject.replyMessage}</Box>
                    <Box component="p" pb={2.5}>
                      {t[language].help.auto_reply_body2}
                    </Box>
                    <Stack spacing={2}>
                      <Button outlined onClick={handleBackToForm}>
                        {t[language].help.back_button_label}
                      </Button>
                      <Button outlined onClick={handleContactUs}>
                        {t[language].help.contact_button_label}
                      </Button>
                    </Stack>
                  </Box>
                )}
                {formState === 3 && (
                  <Stack alignItems="center" justifyContent="center" sx={{ flex: 1, textAlign: "center", py: 10 }}>
                    <Icon
                      icon="check-circle"
                      type="fas"
                      iconDSFR="checkbox-circle-fill"
                      sx={{
                        pb: 3,
                        color: "#24821a",
                        "&::before": {
                          "--icon-size": "3rem",
                        },
                      }}
                      title={t[language].help.message_sent_title}
                    />
                    <Box component="h5" pb={1.5}>
                      {t[language].help.message_sent_subtitle}
                    </Box>
                    <Typography
                      className="fr-text--md"
                      sx={{ pb: 3, maxWidth: 250, lineHeight: "22px" }}
                      role="presentation"
                    >
                      {t[language].help.message_sent_description}
                    </Typography>
                    <Button outlined onClick={handleBackToForm}>
                      {t[language].help.back_button_label}
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={12} container lg={expandedContactModeItem ? 6 : true}>
                  <Grid item container spacing={2}>
                    {modes.map(
                      (mode) =>
                        mode.title && (
                          <Grid item xs={12 / modes.length} lg={12} container sx={{ cursor: "pointer" }} key={mode.id}>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                bgcolor: "primary.main",
                                flexDirection: { xs: "column", lg: "row" },
                                flex: "1 1 auto",
                                p: { xs: 1, lg: 4 },
                                "& *": { color: "common.white" },
                              }}
                              onClick={() => !isContactModeClickDisabled && handleContactModeClick(mode)}
                            >
                              <Hidden lgUp>
                                {mode.icons.map((icon) => (
                                  <Icon
                                    sx={{ fontSize: "1.5rem", p: 1 }}
                                    {...icon}
                                    key={icon.icon}
                                    title={mode.title}
                                  />
                                ))}
                              </Hidden>
                              <Box sx={{ flex: "1 1 0px" }}>
                                <Box
                                  component="h4"
                                  sx={{ color: "common.white", textAlign: { xs: "center", lg: "left" } }}
                                  className={isMobile ? "" : "fr-h2"}
                                  onKeyDown={(e) => handleKeyDown(e, mode)}
                                  role="presentation"
                                >
                                  {mode.title}
                                </Box>
                                {mode.subtitle && (
                                  <Hidden lgDown>
                                    <p>{mode.subtitle}</p>
                                  </Hidden>
                                )}
                              </Box>
                              {!isContactModeClickDisabled && (
                                <Icon
                                  icon={isOpen(contactForm) ? "chevron-up" : "chevron-down"}
                                  iconDSFR={isOpen(mode) ? "arrow-up-s-line" : "arrow-down-s-line"}
                                  sx={{ fontSize: "2rem" }}
                                  tabIndex={0}
                                  onKeyDown={(e) => handleKeyDown(e, mode)}
                                  isHidden={false}
                                  aria-label={isOpen(contactForm) ? t[language].common.close : t[language].common.open}
                                />
                              )}
                            </Grid>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>

                {contactPhoneTitle && isOpen(contactPhone) && (
                  <Grid item xs lg={6} container>
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      sx={{ flex: 1, bgcolor: "primary.10", p: 8 }}
                    >
                      <Hidden lgUp>
                        <Box pb={3}>{contactPhoneSubtitle}</Box>
                      </Hidden>
                      {phone1?.props?.children && (
                        <Grid item container wrap="nowrap" sx={{ py: 3 }}>
                          <Icon
                            icon="phone"
                            iconDSFR="phone-line"
                            sx={iconStyled}
                            title={t[language].help.icon_phone_title}
                          />
                          {phone1}
                        </Grid>
                      )}
                      {phone2?.props?.children && (
                        <Grid item container wrap="nowrap" sx={{ py: 3 }}>
                          <Icon
                            icon="phone"
                            iconDSFR="phone-line"
                            sx={iconStyled}
                            title={t[language].help.icon_phone_title}
                          />
                          {phone2}
                        </Grid>
                      )}
                      {phone3?.props?.children && (
                        <Grid item container wrap="nowrap" sx={{ py: 3 }}>
                          <Icon icon="deaf" sx={iconStyled} title={t[language].common.deaf_icon_title} />
                          <Box sx={{ width: "100%" }}>
                            <Box pb={3}>{phone3}</Box>
                            <Box pb={3}>
                              <Box onClick={handleClickDeafAccess}>{deafAccess}</Box>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {contactDigitalPointsTitle && isOpen(contactDigitalPoints) && (
                  <Grid item xs lg={6} container>
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent="center"
                      sx={{ flex: 1, bgcolor: "primary.10", p: 8 }}
                    >
                      <Grid item sx={{ pb: 3 }}>
                        {text}
                      </Grid>
                      <Grid item sx={{ pb: 3 }}>
                        <Box onClick={handleClickDigitalPoint}>{digitalPoint}</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Block>
      </Wrapper>
    </>
  );
};

HelpForm.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default HelpForm;
