import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Wrapper from "components/LayoutFront/Wrapper";
import HomeLastNews from "components/templates/home/HomeLastNews";
import HomeMairieSubPage from "components/templates/homeMairie/HomeMairieSubPage";
import lastNews from "components/templates/news/lastNews";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import { ImageContainer } from "components/templatesComponents/Image";
import SubPageContext from "components/templatesComponents/SubPageContext";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";

const shortDescriptionStyled = {
  width: { lg: "50%" },
  p: { xs: 2, lg: 7.5 },
  boxSizing: { lg: "border-box" },
  border: { lg: "1px solid" },
  borderColor: { lg: "componentColors.30" },
  bgcolor: { lg: "white" },
  flex: { lg: "1 1 0px" },
  zIndex: { lg: 1 },
};

const linksStyled = {
  position: "relative",
  width: { xs: "100%", lg: "calc(100% + 16px)" },
  m: { lg: -1 },
  zIndex: { lg: 1 },
  mt: { lg: -5 },
  "& > div": {
    py: { xs: 0.5, lg: 1 },
    px: 1,
  },
};

const getSubTemplate = (k) => {
  if (k === lastNews.key) {
    return HomeLastNews;
  }
  return HomeMairieSubPage;
};

const HomeMairie = (props) => {
  const { page = {} } = props;

  const { image, shortDescription, contents } = page;

  const { contentTitle, cardCNI, cardPM, cardComedec, subpages } = contents;

  const cardContextValue = useCallback(
    (index) => ({
      desktopStyleKey: styleKeys.VERTICAL_BIG_ONE_LINE,
      mobileStyleKey: styleKeys.HORIZONTAL,
      styleOverride: {
        root: {
          bgcolor: `primary.${index}`,
          "a&:hover": {
            bgcolor: `primary.${index}`,
          },
        },
      },
      mobileStyleOverride: {
        title: {
          fontWeight: 600,
          color: "#fff",
        },
        arrow: {
          color: "#fff",
        },
      },
    }),
    []
  );

  const subPageContextValue = useMemo(() => ({ getSubTemplate }), []);

  return (
    <>
      <Wrapper>
        <Box sx={{ position: { lg: "relative" }, mt: { lg: 4 } }}>
          <Box sx={{ width: { lg: "60%" }, position: { lg: "absolute" }, top: 0, right: 0 }}>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <ImageContainer ratio={68}>{image}</ImageContainer>
            </Box>
          </Box>
          <Stack sx={{ height: { lg: "423px" } }}>
            <Box
              component="h1"
              sx={{ mt: { xs: 2, lg: 1 }, mb: { xs: 0, lg: 1 }, p: 2, px: { lg: 0 }, maxWidth: { lg: "40%" } }}
            >
              {contentTitle}
            </Box>
            <Box sx={shortDescriptionStyled}>{shortDescription}</Box>
          </Stack>
        </Box>

        <Grid container justifyContent="center" alignItems="stretch" sx={linksStyled}>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider value={cardContextValue(30)}>{cardCNI}</CardContext.Provider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider value={cardContextValue(40)}>{cardPM}</CardContext.Provider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardContext.Provider value={cardContextValue(50)}>{cardComedec}</CardContext.Provider>
          </Grid>
        </Grid>
      </Wrapper>
      <SubPageContext.Provider value={subPageContextValue}>{subpages}</SubPageContext.Provider>
    </>
  );
};

HomeMairie.propTypes = {
  page: PropTypes.shape(),
};

export default HomeMairie;
