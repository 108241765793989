import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import LazyLoad from "react-lazy-load";
import { getFileExtension, getFileNameWithoutExtension } from "utils/commonUtils";
import { getFilenameFromUrl, normalized } from "utils/urlUtils";

export const ImageContainer = (props) => {
  const { ratio, children = null } = props;

  if (typeof ratio === "object") {
    if (Number.isNaN(parseFloat(ratio.xs)) || Number.isNaN(parseFloat(ratio.lg))) {
      return children;
    }
  }
  if (typeof ratio !== "object" && Number.isNaN(parseFloat(ratio))) {
    return children;
  }

  return (
    <Box
      sx={{
        pt: typeof ratio === "object" ? { xs: `${ratio.xs}%`, lg: `${ratio.lg}%` } : `${ratio}%`,
        position: "relative",
        flex: "1 1 0px",
        "@media print": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

ImageContainer.propTypes = {
  ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ xs: PropTypes.number, lg: PropTypes.number })])
    .isRequired,
  children: PropTypes.node,
};

const Image = (props) => {
  const { file, title = null, alt = null, sx = {}, ...others } = props;

  const { thumbs } = file || {};
  let { url, name } = file || {};
  const [imgPlaceholder, setImgPlaceholder] = useState(null);

  const placeholderImgRef = useCallback((node) => {
    if (node !== null) {
      setImgPlaceholder(node.getBoundingClientRect());
    }
  }, []);

  const getThumbUrl = useCallback(() => {
    if (imgPlaceholder && thumbs) {
      let minWidth = Infinity;
      let minHeight = Infinity;
      const { width: placeHolderWidth, height: placeHolderHeight } = imgPlaceholder;
      Object.keys(thumbs).forEach((key) => {
        if (key !== "thumbUrl") {
          let [width, height] = key.split("x");
          width /= 1;
          height /= 1;
          if (width >= placeHolderWidth && width < minWidth && (height >= placeHolderHeight || height === "0")) {
            minWidth = width;
          }
          if (height >= placeHolderHeight && height < minHeight && (width >= placeHolderWidth || width === "0")) {
            minHeight = height;
          }
        }
      });
      if (minWidth !== Infinity && minHeight !== Infinity) {
        return thumbs[`${minWidth}x${minHeight}`];
      }
      if (minWidth !== Infinity) {
        return thumbs[`${minWidth}x0`];
      }
      if (minHeight !== Infinity) {
        return thumbs[`0x${minWidth}`];
      }
      return thumbs.thumbUrl;
    }
    return null;
  }, [imgPlaceholder, thumbs]);

  if ((name || title) && url && url.startsWith(process.env.ged_public_url)) {
    const filename = getFilenameFromUrl(url);
    const fileNameWithoutExtension = getFileNameWithoutExtension(filename);
    if (!name) {
      name = `${normalized(title)}${getFileExtension(filename)}`;
    }
    url = url.replace(filename, `${fileNameWithoutExtension}/${name}`);
  }
  return (
    <LazyLoad height="100%" throttle={100}>
      {!imgPlaceholder ? (
        <Box ref={placeholderImgRef} sx={{ height: "100%" }} />
      ) : (
        <Box
          component="img"
          src={getThumbUrl() || url}
          onError={(e) => {
            if (url !== e.target.src) {
              e.target.onerror = null;
              e.target.src = url;
            }
          }}
          alt={alt}
          title={title}
          sx={{ objectFit: "cover", height: "100%", width: "100%", ...sx }}
          {...others}
        />
      )}
    </LazyLoad>
  );
};

Image.propTypes = {
  file: PropTypes.shape().isRequired,
  title: PropTypes.string,
  sx: PropTypes.shape(),
  alt: PropTypes.string,
};

export default Image;
