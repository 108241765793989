import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const Block = (props) => {
  const { sx = {}, children, ...others } = props;
  return (
    <Box sx={{ ...sx, p: 2, px: { lg: 0 } }} {...others}>
      {children}
    </Box>
  );
};

Block.propTypes = {
  sx: PropTypes.shape(),
  children: PropTypes.node,
};

export default Block;
