import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import HomeNewsDsfr from "components/templates/home/HomeNewsDsfr";
import lastNews from "components/templates/news/lastNews";
import Separator from "components/templatesComponents/Separator";
import SubPageContext from "components/templatesComponents/SubPageContext";
import Tabs from "components/templatesComponents/Tabs";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { stringToPath } from "utils/urlUtils";

const homeBackgroundStyled = {
  pt: 6,
  pb: 4,
  minHeight: { xs: "268px", md: "300px" },
  width: "100%",
  bgcolor: "secondary.light",
};

const homeTabsStyled = {
  minHeight: "40px",
  mx: { xs: 2, md: 0 },
  "& .MuiTabs-scroller": {
    display: "flex",
  },
  "& .MuiTabs-flexContainer": {
    border: "1px solid",
    borderColor: "#DDDDDD",
    borderRadius: 1,
    width: { xs: "100%", md: "auto" },
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
  },
};

const homeTabStyled = {
  py: 0,
  px: 1.5,
  minHeight: "40px",
  color: "var(--text-title-grey)",
  maxWidth: "none",
  "&.Mui-selected": {
    border: "1px solid",
    borderColor: "primary.main",
    borderRadius: 1,
    width: { xs: "100%", md: "auto" },
  },
};

const getSubTemplate = (k) => {
  switch (k) {
    case lastNews.key:
      return HomeNewsDsfr;
    default:
      return null;
  }
};

const HomeDsfr = (props) => {
  const { page = {} } = props;

  const { contents, image } = page;

  const { contentTitle, subtitle, homeTabs, dsfrElements, multiSubpages } = contents;

  let { tabs = [] } = homeTabs;
  const { wrapperProps } = homeTabs;

  const [sendTag, setSendTag] = useState(false);

  const subPageDSFRContextValue = useMemo(() => ({ noSeparator: true }), []);
  const subPageContextValue = useMemo(() => ({ getSubTemplate }), []);

  if (!Array.isArray(tabs)) {
    tabs = [tabs];
  }
  tabs = tabs.map((t) => ({ ...t, url: stringToPath(t.title) }));

  const hasImage = useMemo(() => image?.props?.file?.id, [image]);

  return (
    <>
      <Wrapper>
        <Grid container spacing={3} columns={15}>
          <Grid
            item
            xs={16}
            lg={hasImage ? 9 : 16}
            sx={{
              "& *": { textAlign: { xs: "center", lg: hasImage ? "start" : "center" } },
              "& h1": { fontSize: { xs: "2rem", lg: "3rem" }, lineHeight: { xs: "2.5rem", lg: "3.5rem" } },
              "& .fr-text--xl": { margin: 0 },
            }}
          >
            <Box p={{ xs: 2, lg: 0 }}>
              <h1>{contentTitle}</h1>
              <p className="fr-text--xl">{subtitle}</p>
            </Box>
          </Grid>
          {hasImage && (
            <Grid item lg={6} sx={{ display: { xs: "none", lg: "block" } }}>
              {image}
            </Grid>
          )}
        </Grid>
      </Wrapper>
      <Separator />
      <Box sx={homeBackgroundStyled}>
        <Wrapper>
          <SubPageContext.Provider value={subPageDSFRContextValue}>
            {wrapperProps || tabs.length !== 0 ? (
              <Tabs
                {...homeTabs}
                tabsProps={{ sx: homeTabsStyled }}
                tabProps={{ sx: homeTabStyled }}
                panelClass={{ mt: 0 }}
                indicatorColor="none"
                tabs={tabs}
                sendTag={sendTag}
                setSendTag={setSendTag}
              />
            ) : null}
            {wrapperProps || tabs.length === 0 ? dsfrElements : null}
          </SubPageContext.Provider>
        </Wrapper>
      </Box>
      <SubPageContext.Provider value={subPageContextValue}>{multiSubpages}</SubPageContext.Provider>
    </>
  );
};

HomeDsfr.propTypes = {
  page: PropTypes.shape(),
};

export default HomeDsfr;
