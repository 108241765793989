import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { getTranslateIndex } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const GeolocMapHeader = (props) => {
  const { geoPointsLength = null, cityPosition = "", source } = props;
  const { language } = useContext(LanguageContext);
  const { basic, specific } = categories[source] || {};

  const displayColumnItems = source === "mairie";

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      sx={{ alignItems: { xs: "flex-start", lg: "center" }, flexDirection: { xs: "column", lg: "row" } }}
    >
      <Grid item xs lg={5} sx={{ mr: 1 }}>
        <p>
          <b> {geoPointsLength} </b> {t[language].geolocalisation.results_message[getTranslateIndex(geoPointsLength)]}{" "}
          {cityPosition}
        </p>
      </Grid>
      {source !== "siv" && (
        <Grid
          item
          xs
          container
          spacing={2}
          aria-hidden
          sx={{ flexDirection: { xs: "column", lg: displayColumnItems ? "column" : "row" } }}
        >
          <Grid item>
            <Icon
              icon="circle"
              type="fas"
              iconDSFR={source === "mairie" ? "bank-line" : "camera-line"}
              sx={{ color: "#396FF1", mr: 1 }}
            />
            {t[language].geolocalisation[basic]}
          </Grid>
          <Grid item>
            <Icon
              icon="circle"
              type="fas"
              iconDSFR={source === "mairie" ? "bank-fill" : "camera-fill"}
              sx={{ color: "#F32121", mr: 1 }}
            />
            {t[language].geolocalisation[specific]}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

GeolocMapHeader.propTypes = {
  geoPointsLength: PropTypes.number,
  cityPosition: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default GeolocMapHeader;
