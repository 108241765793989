import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const PageTitle = (props) => {
  const { children = null, sx = {}, ...others } = props;

  return (
    <Box component="h1" sx={{ textAlign: { xs: "center", lg: "left" }, mb: 0, ...sx }} {...others}>
      {children}
    </Box>
  );
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  sx: PropTypes.shape(),
};

export default PageTitle;
