import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import CardContext from "components/templatesComponents/CardContext";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const Missions = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { pageLinks } = contents;

  const cardContextValue = useMemo(
    () => ({
      Wrapper: Box,
      wrapperProps: {
        my: 1,
      },
    }),
    []
  );

  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>
      <Block>{shortDescription}</Block>
      <CardContext.Provider value={cardContextValue}>{pageLinks}</CardContext.Provider>
    </Wrapper>
  );
};

Missions.propTypes = { page: PropTypes.shape().isRequired };

export default Missions;
