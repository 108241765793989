import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React from "react";

const SeeMoreLink = (props) => {
  const { page = null, children = null, ...others } = props;

  return (
    <Link page={page} sx={{ display: "flex", alignItems: "center", color: "primary.40", fontWeight: 600 }} {...others}>
      {children} <ChevronRightIcon role="img" />
    </Link>
  );
};

SeeMoreLink.propTypes = {
  page: PropTypes.shape(),
  children: PropTypes.node,
};

export default SeeMoreLink;
