import Box from "@mui/material/Box";
import LanguageContext from "components/LanguageContext";
import TagsComponent from "components/templatesComponents/Tags";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import PageService from "services/PageService";
import { getSiteName } from "utils/commonUtils";
import t from "utils/locales/translation.json";

const SearchTagsBlock = (props) => {
  const {
    currentSiteName = "",
    selectedTags = [],
    handleSelectTagsForm = null,
    hiddenTitle = false,
    profil = false,
    lang,
  } = props;

  const { language } = useContext(LanguageContext);

  const [{ data }] = useAxiosCache(
    PageService.getConfig("getTags", {
      currentSiteName,
      lang,
    })
  );
  const { aggregations } = data || {};
  const { tags: objectTags } = aggregations || {};
  const { buckets } = objectTags || {};

  const profilTagsArray = useMemo(
    () =>
      (lang.toUpperCase() === "EN" ? process.env.profil_tags_en : process.env.profil_tags || "")
        .toLowerCase()
        .split(","),
    [lang]
  );

  const allTags = React.useMemo(
    () =>
      buckets
        ?.map((bucket) => bucket.key)
        ?.filter((b) =>
          profil ? profilTagsArray.includes(b.toLowerCase()) : !profilTagsArray.includes(b.toLowerCase())
        ),
    [buckets, profil, profilTagsArray]
  );

  const handleSelectTags = (tag) => {
    if (selectedTags.includes(tag)) {
      handleSelectTagsForm(selectedTags.filter((st) => st !== tag));
    } else {
      handleSelectTagsForm([tag, ...selectedTags]);
    }
  };

  if (allTags?.length === 0) {
    return null;
  }

  return (
    <>
      {currentSiteName && !hiddenTitle ? (
        <>
          <hr />
          <h5>{getSiteName(currentSiteName)}</h5>
        </>
      ) : (
        <Box sx={{ m: 1.5 }} />
      )}
      <div aria-label={t[language].all_news.tag_list} />
      <TagsComponent tags={allTags} withDSFR handleSelectTags={handleSelectTags} selectedTags={selectedTags} />
    </>
  );
};

SearchTagsBlock.propTypes = {
  currentSiteName: PropTypes.string,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  handleSelectTagsForm: PropTypes.func,
  hiddenTitle: PropTypes.bool,
  profil: PropTypes.bool,
  lang: PropTypes.string.isRequired,
};

export default SearchTagsBlock;
