import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import SubTemplateWrapper from "components/LayoutFront/SubTemplateWrapper";
import { tabsIdentifier } from "components/templates/who/Who";
import Block from "components/templatesComponents/Block";
import Button from "components/templatesComponents/Button";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React from "react";

export const shortDescriptionStyled = {
  position: "relative",
  p: { xs: 2, lg: 7.5 },
  minHeight: { lg: "170px" },
  "&:before": {
    content: { lg: `""` },
    bgcolor: "white",
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "2px solid #e7e7e7",
  },
};

const HomeWho = (props) => {
  const { page = {} } = props;
  const { title, shortDescription, fullPath, image, contents } = page;

  const { history, decrees, governance } = contents;

  const theme = useTheme();

  return (
    <SubTemplateWrapper backgroundColor={theme.palette.primary[10]} title={title}>
      <Box sx={{ position: { lg: "relative" } }}>
        <Box sx={{ width: { lg: "60%" }, position: { lg: "absolute" }, top: 0, left: 0, zIndex: { lg: -1 } }}>
          <ImageContainer ratio={55}>{image}</ImageContainer>
        </Box>
        <Box sx={{ width: { lg: "50%" }, float: { lg: "right" } }}>
          <Box sx={shortDescriptionStyled}>{shortDescription}</Box>
          <Block>
            <Stack spacing={1} sx={{ zIndex: 1, px: { lg: 7.5 }, mt: { lg: -7.5 } }}>
              <Button
                fullWidth
                component={Link}
                url={`${fullPath}?${tabsIdentifier}=${history.tab.url}`}
                page={history}
                size="lg"
                aria-label={history.tab.title}
              >
                {history.tab.title}
              </Button>
              <Button
                fullWidth
                component={Link}
                url={`${fullPath}?${tabsIdentifier}=${decrees.tab.url}`}
                page={decrees}
                size="lg"
                aria-label={decrees.tab.title}
              >
                {decrees.tab.title}
              </Button>
              <Button
                fullWidth
                component={Link}
                url={`${fullPath}?${tabsIdentifier}=${governance.tab.url}`}
                page={governance}
                size="lg"
                aria-label={governance.tab.title}
              >
                {governance.tab.title}
              </Button>
            </Stack>
          </Block>
        </Box>
      </Box>
    </SubTemplateWrapper>
  );
};

HomeWho.propTypes = {
  page: PropTypes.shape(),
};

export default HomeWho;
