import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const Hidden = (props) => {
  const { children = null, lgDown = false, lgUp = false } = props;

  let display = {};
  if (lgDown) {
    display = { xs: "none", lg: "block" };
  } else if (lgUp) {
    display = { xs: "block", lg: "none" };
  }

  return <Box sx={{ display }}>{children}</Box>;
};

Hidden.propTypes = {
  children: PropTypes.node,
  lgDown: PropTypes.bool,
  lgUp: PropTypes.bool,
};

export default Hidden;
