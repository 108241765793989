import HomeDsfr from "components/templates/homeDsfr/HomeDsfr";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HOMEDSFR",
  label: "Accueil (DSFR)",
  Component: HomeDsfr,
  initialContents: [
    {
      key: "contentTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "subtitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "",
    },
    {
      key: "homeTabs",
      type: elementsTypes.TABS_TILE_CARDS,
      value: "",
      children: [
        {
          key: "identifier",
          type: elementsTypes.INPUT,
          value: "th",
        },
      ],
    },
    {
      key: "dsfrElements",
      type: elementsTypes.DSFR_TILE_CARDS_LIST,
      value: "",
      children: [
        {
          key: "specific",
          value: "true",
        },
      ],
    },
    {
      key: "multiSubpages",
      type: elementsTypes.MULTI_SUBPAGES_LIST,
      value: "",
    },
  ],
};
