import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import PageDate from "components/templatesComponents/PageDate";
import PageTitle from "components/templatesComponents/PageTitle";
import Tags from "components/templatesComponents/Tags";
import PropTypes from "prop-types";
import React from "react";

const Article = (props) => {
  const { page } = props;
  const { shortDescription, title, tags, contents } = page;

  const theme = useTheme();

  const { dynamicPart } = contents;

  const iconColor = theme?.templates?.Article?.icon?.color || null;

  return (
    <Box sx={{ position: "relative" }}>
      <Hidden lgUp>
        <Block>
          <PageTitle>{title}</PageTitle>
          <PageDate page={page} iconColor={iconColor} />
        </Block>
        <Block sx={{ py: 1.5, bgcolor: "#fff" }}>
          <Block>{shortDescription}</Block>
          {dynamicPart}
          {tags && <Tags tags={tags} iconColor={iconColor} sx={{ px: 2 }} />}
        </Block>
      </Hidden>

      <Hidden lgDown>
        <Wrapper
          leftProps={{
            sx: {
              height: "260px",
              bgcolor: "primary.50",
              "@media print": {
                height: "0",
              },
            },
          }}
        >
          <Box
            sx={{
              height: "260px",
              width: "60%",
              py: 4,
              bgcolor: "primary.50",
              "@media print": {
                height: "auto",
                width: "100%",
              },
            }}
          >
            {/* TODO color titre */}
            <PageTitle sx={{ overflowWrap: "break-word", color: theme.palette.common.white }}>{title}</PageTitle>
          </Box>
        </Wrapper>
        <Wrapper sx={{ mt: -15.75 }}>
          <PageDate page={page} position="right" iconColor={iconColor} sx={{ height: 50 }} />
          <Paper
            square
            sx={{
              zIndex: 30,
              position: "relative",
              px: 15,
              pt: 8,
              pb: 0,
              boxShadow: "none",
              border: "1px solid",
              borderColor: "componentColors.30",
              "@media print": {
                border: "none",
                p: 2,
              },
            }}
          >
            {shortDescription}
            <br />
            {dynamicPart}
            {tags && <Tags tags={tags} iconColor={iconColor} sx={{ pt: 10, pb: 5 }} />}
          </Paper>
        </Wrapper>
      </Hidden>
    </Box>
  );
};

Article.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Article;
