import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import SiteContext from "components/SiteContext";
import contact from "components/templates/contact/contact";
import help from "components/templates/help/help";
import helpMairie from "components/templates/help/helpMairie";
import home from "components/templates/home/home";
import homeMairie from "components/templates/homeMairie/homeMairie";
import homeProcessSites from "components/templates/homeProcessSites/homeProcessSites";
import missions from "components/templates/missions/missions";
import processes from "components/templates/processes/processes";
import Block from "components/templatesComponents/Block";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import PageTitle from "components/templatesComponents/PageTitle";
import useAxiosCache from "hooks/axiosCache";
import React, { useContext } from "react";
import Helmet from "react-helmet";
import PageService from "services/PageService";
import t from "utils/locales/translation.json";

const listLinkStyled = {
  p: 0,
  listStyle: "inside",
  mt: 2,
  fontWeight: 600,
  color: "primary.main",
  "& li:after": {
    content: { xs: `"\\f105"`, lg: "none" },
    fontWeight: 400,
    fontSize: "16px",
    verticalAlign: "middle",
    textIndent: "0",
    ml: 1,
    display: "inline-block",
    fontFamily: `"Font Awesome 5 Pro"`,
  },
  "& li:not(last-child)": {
    mb: 2.5,
  },
  "& a": {
    color: "primary.main",
    backgroundImage: "var(--underline-img), var(--underline-img) !important",
  },
};

const NotFound = () => {
  const { language } = useContext(LanguageContext);
  const site = useContext(SiteContext);
  const { name: siteName } = site || {};

  const [{ data }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      templates: [
        home.key,
        homeProcessSites.key,
        homeMairie.key,
        contact.key,
        help.key,
        helpMairie.key,
        missions.key,
        processes.key,
      ],
      filters: [`page.lang||eq||${language}`],
    })
  );

  const { pages = [] } = data || {};

  const homePage =
    pages && pages.find((version) => [home.key, homeProcessSites.key, homeMairie.key].includes(version.template));
  const contactPage =
    pages && pages.find((version) => [contact.key, help.key, helpMairie.key].includes(version.template));
  const missionPage = pages && pages.find((version) => version.template === missions.key);
  const processesPage = pages && pages.find((version) => version.template === processes.key);

  return (
    <Wrapper wrapperProps={{ sx: { position: "relative" } }}>
      <Helmet>
        <title>
          {t[language].not_found.balise_title}
          {siteName === "ANTS" ? "" : " - "}
          {t[language].site_name[siteName]} - France Titres (ANTS)
        </title>
      </Helmet>
      <Grid container justifyContent="space-between">
        <Grid item lg={6} container direction="column">
          <Grid item>
            <Block>
              <PageTitle>{t[language].not_found.balise_title}</PageTitle>
            </Block>
          </Grid>
          <Grid item>
            <Block>
              <h2>{t[language].not_found.subtitle}</h2>
              <Box component="ul" sx={listLinkStyled}>
                {homePage && (
                  <li>
                    <Link page={homePage}>{t[language].not_found.home_message}</Link>
                  </li>
                )}
                {missionPage && (
                  <li>
                    <Link page={missionPage}>{t[language].not_found.missions_message}</Link>
                  </li>
                )}
                {processesPage && (
                  <li>
                    <Link page={processesPage}>{t[language].not_found.processes_message}</Link>
                  </li>
                )}
                {contactPage && (
                  <li>
                    <Link page={contactPage}>{t[language].not_found.contact_message}</Link>
                  </li>
                )}
              </Box>
            </Block>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ position: { lg: "absolute" }, bottom: 0, right: "5%", width: { lg: "40%" }, zIndex: { lg: -1 } }}>
        <ImageContainer ratio={{ xs: 42, lg: 60 }}>
          <Box
            component="img"
            src="/NotFound.jpg"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt={t[language].not_found.img_alt}
          />
        </ImageContainer>
      </Box>
    </Wrapper>
  );
};

export default NotFound;
