import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const ImageBlock = (props) => {
  const { width = null, mobileWidth = null, image, justifyContent, legend = "" } = props;

  return (
    <Box
      component="figure"
      className="fr-content-media"
      role="group"
      aria-label={legend?.props?.value || legend}
      sx={{ flexDirection: "row", justifyContent }}
    >
      <Box
        sx={{
          height: "100%",
          width: { xs: mobileWidth ? `${mobileWidth}%` : "100%", lg: width ? `${width}% ` : "100%" },
        }}
      >
        {image}
        {legend && <figcaption className="fr-content-media__caption">{legend}</figcaption>}
      </Box>
    </Box>
  );
};

ImageBlock.propTypes = {
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  justifyContent: PropTypes.oneOf(["flex-start", "center", "flex-end"]).isRequired,
  image: PropTypes.shape().isRequired,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

export default ImageBlock;
