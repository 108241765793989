import axios from "axios";

class CounterService {
  static getCounterInfos = () => {
    return axios.get("/@counter").then((response) => {
      return response.data;
    });
  };
}

export default CounterService;
