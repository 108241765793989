import Box from "@mui/material/Box";
import LanguageContext from "components/LanguageContext";
import Wrapper from "components/LayoutFront/Wrapper";
import Button from "components/templatesComponents/Button";
import Hidden from "components/templatesComponents/Hidden";
import Link from "components/templatesComponents/Link";
import Separator from "components/templatesComponents/Separator";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const backgroundStyled = {
  position: "relative",
  "&:before": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: { xs: "100%", lg: "300px" },
    bgcolor: "primary.10",
    zIndex: -1,
  },
};

const HomeMairieSubPage = (props) => {
  const { page = {} } = props;
  const { title, shortDescription } = page;

  const { language } = useContext(LanguageContext);

  return (
    <Box p={2} pb={0}>
      <Hidden lgDown>
        <Separator />
      </Hidden>
      <Wrapper leftProps={{ sx: backgroundStyled }} sx={backgroundStyled}>
        <Separator />
        <Box component="h2" className="fr-h1" mb={0}>
          {title}
        </Box>
        <Box py={4}>{shortDescription}</Box>
        <Button
          primary
          icon={{ icon: "long-arrow-right", iconDSFR: "arrow-right-line" }}
          iconPosition="right"
          component={Link}
          linkComponent
          page={page}
          aria-label={t[language].common.know_more}
          tabIndex={0}
        >
          {t[language].common.know_more}
        </Button>
      </Wrapper>
    </Box>
  );
};

HomeMairieSubPage.propTypes = {
  page: PropTypes.shape(),
};

export default HomeMairieSubPage;
