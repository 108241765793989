import LanguageContext from "components/LanguageContext";
import MessageContext from "components/MessageContext";
import PageVersionContext from "components/PageVersionContext";
import SiteContext from "components/SiteContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

const Share = ({ forceDisplay = false, withTitle = false }) => {
  const { language } = useContext(LanguageContext);
  const { displaySuccess } = useContext(MessageContext);
  const { currentPageVersion } = useContext(PageVersionContext);
  const site = useContext(SiteContext);

  const { breadcrumb = [], fullPath = "", title: pageTitle } = currentPageVersion || {};

  const URL = `${site.host}${fullPath}`;

  const openWindow = (url, title) => {
    const WIDTH = 550;
    const HEIGHT = 285;
    const TOP = (window && window.innerHeight - HEIGHT) / 2;
    const LEFT = (window && window.innerWidth - WIDTH) / 2;
    window?.open(url, title, `height=${HEIGHT},width=${WIDTH},top=${TOP},left=${LEFT},resizable=1`);
  };

  const shares = [
    {
      iconClassName: "fr-share__link--facebook",
      onClick: () => openWindow(`https://www.facebook.com/sharer/sharer.php?u=${URL}`, "Facebook"),
      title: t[language].components.icon_fb_share_title,
    },
    {
      iconClassName: "fr-share__link--twitter",
      onClick: () => openWindow(`https://twitter.com/intent/tweet?text=${URL}`, "Twitter"),
      title: t[language].components.icon_twitter_share_title,
    },
    {
      iconClassName: "fr-share__link--linkedin",
      onClick: () => openWindow(`https://www.linkedin.com/sharing/share-offsite/?url=${URL}`, "Linkedin"),
      title: t[language].components.icon_linkedIn_share_title,
    },
    {
      iconClassName: "fr-share__link--mail",
      onClick: () =>
        openWindow(
          `mailto:?subject=${t[language].components.mail_object} ${site.name}&body=${t[language].components.mail_body1}%0A${pageTitle}%0A%0A${URL}%0A%0A${t[language].components.mail_body2}`,
          "Email"
        ),
      title: t[language].components.icon_mail_share_title,
    },
    {
      iconClassName: "fr-share__link--copy",
      onClick: () => {
        navigator.clipboard.writeText(URL);
        displaySuccess(t[language].components.copy_link_message);
      },
      title: t[language].components.copy_link_title,
      buttonStyle: { marginRight: 0 },
    },
  ];

  return (
    ((breadcrumb && breadcrumb.length > 1) || forceDisplay) && (
      <div className="fr-share">
        {withTitle && (
          <p className="fr-share__title" role="presentation">
            {t[language].components.share_title}
          </p>
        )}
        <ul className="fr-share__group">
          {shares.map((share) => {
            const { title, iconClassName, onClick, buttonStyle } = share;
            return (
              <li key={title}>
                <button
                  type="button"
                  className={`fr-share__link ${iconClassName}`}
                  title={title}
                  onClick={onClick}
                  style={buttonStyle}
                >
                  {title}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

Share.propTypes = {
  forceDisplay: PropTypes.bool,
  withTitle: PropTypes.bool,
};

export default Share;
