import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import App from "apps/front-office/client/App";
import { SiteContextProvider } from "components/SiteContext";
import { loadCache } from "hooks/axiosCache";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

const emotionCache = createCache({ key: "css" });

function Main() {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Router>
        <SiteContextProvider>
          <App />
        </SiteContextProvider>
      </Router>
    </CacheProvider>
  );
}

if (window.__AXIOS_HOOKS_CACHE__) {
  loadCache(window.__AXIOS_HOOKS_CACHE__);
  delete window.__AXIOS_HOOKS_CACHE__;
}

// You cannot use hydrate because of Onboarding
// SSR will never display Onboarding
// Onboarding will be rendered client side only x times for each user / site
createRoot(document.querySelector("#root")).render(<Main />);
