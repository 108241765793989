import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import { ImageContainer } from "components/templatesComponents/Image";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

const FloatingImage = (props) => {
  const { page } = props;
  const { title, contents, image } = page;
  const { dynamicPart } = contents;

  const [rightPartPlaceHolderStyle, setRightPartPlaceHolderStyle] = useState(null);

  const mr = typeof window !== "undefined" ? `${(992 - window.innerWidth) / 2}px` : "unset";

  const rightPartRef = useRef();

  const handleResize = useCallback(() => {
    if (rightPartRef.current !== null) {
      setTimeout(() => {
        setRightPartPlaceHolderStyle({
          height: rightPartRef.current.offsetHeight || 400,
          width: rightPartRef.current.offsetWidth,
        });
      }, 0);
    }
  }, [rightPartRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
          position: "absolute",
          width: "50%",
          top: 0,
          right: 0,
        }}
        ref={rightPartRef}
      >
        {image && (
          <Box pb={2} pl={2}>
            <ImageContainer ratio={50}>{image}</ImageContainer>
          </Box>
        )}
      </Box>
      <Wrapper>
        {rightPartPlaceHolderStyle && (
          <Box
            sx={{
              float: "right",
              mr,
              ...rightPartPlaceHolderStyle,
            }}
          />
        )}
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
        {dynamicPart}
      </Wrapper>
    </Box>
  );
};

FloatingImage.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default FloatingImage;
