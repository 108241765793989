import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Wrapper from "components/LayoutFront/Wrapper";
import {
  Context as GroupContext,
  defaultContext as defaultGroupContext,
} from "components/adminComponents/AdminContentsGroups";
import Block from "components/templatesComponents/Block";
import CardContext from "components/templatesComponents/CardContext";
import PageTitle from "components/templatesComponents/PageTitle";
import { styleKeys } from "components/templatesComponents/card/cardStyles";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import elementsTypes from "utils/elementsTypes";

const Processes = (props) => {
  const { page } = props;
  const { title, contents } = page;
  const { cardsGroups, cardZero, cardOne, cardTwo, cardThree, cardFour, subtitle, otherCards, otherProcesses } =
    contents;

  const theme = useTheme();

  const allProcesses = (cardZero && cardZero.props && cardZero.props.link && cardZero.props.link.url) || false;

  const getCardLabelFromContent = (content) => {
    const { children = [] } = content || {};
    const titleChild = children.find((c) => c.key === "title") || {};
    return titleChild.value;
  };

  const getCardLabelFromComponent = (card) => card && card.props && card.props.title;

  const bgColorCardContextValue = useCallback(
    (index) => ({
      desktopStyleOverride: {
        root: {
          backgroundColor: theme.palette.primary.tones[index],
          "a&:hover": {
            backgroundColor: theme.palette.primary.tones[index],
          },
        },
      },
    }),
    [theme]
  );

  const renderCardOneToThree = (display) => (
    <>
      {cardOne && (
        <Grid item xs={6} sx={{ display }} role="listitem">
          <CardContext.Provider value={bgColorCardContextValue(allProcesses ? 0 : 1)}>{cardOne}</CardContext.Provider>
        </Grid>
      )}
      {cardTwo && (
        <Grid item xs={6} sx={{ display }} role="listitem">
          <CardContext.Provider value={bgColorCardContextValue(allProcesses ? 1 : 2)}>{cardTwo}</CardContext.Provider>
        </Grid>
      )}
      {cardThree && (
        <Grid item xs={6} lg={12} sx={{ display }} role="listitem">
          <CardContext.Provider value={bgColorCardContextValue(allProcesses ? 2 : 0)}>{cardThree}</CardContext.Provider>
        </Grid>
      )}
    </>
  );

  const groupContextValue = useMemo(
    () => ({
      ...defaultGroupContext,
      buttonLabel: "Gérer des groupes de cards",
      title: "Gérer des groupes de cards",
      subTitle: "Ces groupes sont utilisés dans le résumé de page lorsque celle-ci est ajoutée à la page d'accueil",
      types: elementsTypes.CARD,
      getLabelFromComponent: getCardLabelFromComponent,
      getLabelFromContent: getCardLabelFromContent,
    }),
    []
  );

  const cardZeroContextValue = useMemo(
    () => ({
      desktopStyleKey: allProcesses ? styleKeys.BIG : styleKeys.VERTICAL_BIG,
      mobileStyleKey: allProcesses ? styleKeys.LITTLE_COLOR : styleKeys.LITTLE_LIGHT,
    }),
    [allProcesses]
  );

  const cardContextValue = useMemo(
    () => ({
      Wrapper: Grid,
      wrapperProps: {
        item: true,
        xs: 6,
        md: 4,
        role: "listitem",
      },
    }),
    []
  );

  const otherCardContextValue = useMemo(
    () => ({ ...cardContextValue, camaieuStart: allProcesses ? 1 : 2 }),
    [cardContextValue, allProcesses]
  );

  const otherProcessesCardContextValue = useMemo(
    () => ({ ...cardContextValue, ...bgColorCardContextValue(0) }),
    [bgColorCardContextValue, cardContextValue]
  );

  return (
    <>
      <Wrapper>
        <GroupContext.Provider value={groupContextValue}>
          {React.isValidElement(cardsGroups) && cardsGroups}
        </GroupContext.Provider>
        <Block>
          <PageTitle>{title}</PageTitle>
        </Block>
      </Wrapper>
      <Wrapper>
        <Block>
          <Grid container spacing={2} role="list">
            {cardZero && (
              <Grid role="listitem" item xs={6} lg={4}>
                <CardContext.Provider value={cardZeroContextValue}>{cardZero}</CardContext.Provider>
              </Grid>
            )}
            {renderCardOneToThree({ lg: "none" })}
            <Grid item xs={8} container spacing={2}>
              {renderCardOneToThree({ xs: "none", lg: "block" })}
            </Grid>
            {cardFour && (
              <Grid item xs={6} lg={8} role="listitem">
                <CardContext.Provider value={bgColorCardContextValue(allProcesses ? 0 : 1)}>
                  {cardFour}
                </CardContext.Provider>
              </Grid>
            )}
            <CardContext.Provider value={otherCardContextValue}>{otherCards}</CardContext.Provider>
          </Grid>
        </Block>
      </Wrapper>
      {otherProcesses && (
        <Block>
          <Wrapper>
            <Box component="h2" mt={1} mb={3}>
              {subtitle}
            </Box>
          </Wrapper>
          <Wrapper>
            <Grid container alignItems="stretch" spacing={2} role="list">
              <CardContext.Provider value={otherProcessesCardContextValue}>{otherProcesses}</CardContext.Provider>
            </Grid>
          </Wrapper>
        </Block>
      )}
    </>
  );
};

Processes.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Processes;
