import Box from "@mui/material/Box";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import Separator from "components/templatesComponents/Separator";
import PropTypes from "prop-types";
import React from "react";

const commonBackgroundStyled = (bgcolor, right) => ({
  position: "relative",
  "&:before": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: right ? "unset" : 0,
    right: 0,
    height: { xs: "100%", lg: "300px" },
    width: right ? "50%" : "100%",
    bgcolor,
    zIndex: -1,
  },
});

const wrapperStyled = {
  zIndex: 1,
  position: "relative",
};

const SubTemplateWrapper = (props) => {
  const { children = null, title, backgroundColor = "none", right = false } = props;

  return (
    <div>
      <Separator />
      {right ? (
        <Wrapper
          wrapperProps={{ sx: wrapperStyled }}
          leftProps={{ sx: commonBackgroundStyled(backgroundColor, right) }}
          rightProps={{ sx: { ...commonBackgroundStyled(backgroundColor) } }}
        >
          <Box sx={{ ...commonBackgroundStyled(backgroundColor) }}>
            <Block>
              <Box component="h2" className="fr-h1" sx={{ mb: 1, mt: { lg: 8 } }}>
                {title}
              </Box>
            </Block>
            {children}
          </Box>
        </Wrapper>
      ) : (
        <Wrapper
          leftProps={{ sx: { ...commonBackgroundStyled(backgroundColor) } }}
          wrapperProps={{ sx: wrapperStyled }}
        >
          <Box sx={{ ...commonBackgroundStyled(backgroundColor) }}>
            <Block>
              <Box component="h2" className="fr-h1" sx={{ mb: 1, mt: { lg: 8 } }}>
                {title}
              </Box>
            </Block>
            {children}
          </Box>
        </Wrapper>
      )}
    </div>
  );
};

SubTemplateWrapper.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ xs: PropTypes.string, lg: PropTypes.string }),
  ]),
  title: PropTypes.string.isRequired,
  right: PropTypes.bool,
};

export default SubTemplateWrapper;
