import Grid from "@mui/material/Grid";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React from "react";

const Glossary = (props) => {
  const { page } = props;
  const { title, shortDescription, contents } = page;
  const { glossary } = contents;

  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>
      {shortDescription && (
        <Block>
          <Grid container>
            <Grid item xs={12} lg={8}>
              <p>{shortDescription}</p>
            </Grid>
          </Grid>
        </Block>
      )}
      {glossary}
    </Wrapper>
  );
};

Glossary.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Glossary;
