import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LanguageContext from "components/LanguageContext";
import cardStyles, { styleKeys } from "components/templatesComponents/card/cardStyles";
import CardContext from "components/templatesComponents/CardContext";
import Icon from "components/templatesComponents/Icon";
import { ImageContainer } from "components/templatesComponents/Image";
import Link from "components/templatesComponents/Link";
import Text from "components/templatesComponents/Text";
import merge from "lodash.merge";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import t from "utils/locales/translation.json";

const Card = (props) => {
  const { link = {}, page = null, icon = null, index, subtitle = "" } = props;

  let { title = "", description = "", linkLabel = "" } = props;

  title = (page && page.title) || title;
  description = (page && page.shortDescription) || description;

  const {
    Wrapper = Fragment,
    wrapperProps = {},
    mobileStyleKey,
    desktopStyleKey,
    styleOverride,
    desktopStyleOverride,
    mobileStyleOverride,
    camaieuStart,
  } = useContext(CardContext);

  const { language } = useContext(LanguageContext);

  if (!linkLabel) {
    linkLabel = t[language].components.default_linkLabel_card;
  }

  const image = page && page.image;

  if (page) {
    link.page = page;
  }

  const { id: linkId, ...linkProps } = link;

  const mobileStyle = cardStyles[mobileStyleKey] || cardStyles[styleKeys.LITTLE_LIGHT];
  const desktopStyle = cardStyles[desktopStyleKey] || cardStyles[styleKeys.LIGHT_BIG];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const styledProps = {
    mobileStyle,
    desktopStyle,
    styleOverride,
    mobileStyleOverride,
    desktopStyleOverride,
    index,
    camaieuStart,
  };

  const styledCard = (key) => ({
    [theme.breakpoints.down("lg")]: {
      ...merge(mobileStyle.style(theme, styledProps), styleOverride, mobileStyleOverride)[key],
    },
    [theme.breakpoints.up("lg")]: {
      ...merge(desktopStyle.style(theme, styledProps), styleOverride, desktopStyleOverride)[key],
    },
  });

  const displayImage = !!image && ((isMobile && mobileStyle.imageRatio) || (!isMobile && desktopStyle.imageRatio));

  return (
    <Wrapper {...wrapperProps}>
      <Link
        {...linkProps}
        title={linkProps.title || title || null}
        sx={{
          height: "100%",
          "&:hover": {
            color: "initial",
          },
          ...styledCard("root"),
        }}
        tabIndex={0}
      >
        {displayImage && (
          <Box sx={{ display: "none", ...styledCard("image") }}>
            <ImageContainer ratio={{ xs: mobileStyle.imageRatio, lg: desktopStyle.imageRatio }}>{image}</ImageContainer>
          </Box>
        )}
        <Box sx={styledCard("content")}>
          {icon && <Icon {...icon} sx={styledCard("icon")} title={`icone-${title}`} />}
          {title && (
            <Box
              component="h3"
              sx={{ whiteSpace: "normal", wordBreak: "break-word", mb: 1, ...styledCard("title") }}
              className={isMobile ? mobileStyle.titleVariant : desktopStyle.titleVariant}
            >
              {title}
            </Box>
          )}
          {subtitle && (
            <Box component="p" className="fr-text--md" mb={1.5} role="presentation">
              {subtitle}
            </Box>
          )}
          {description && (
            <Text sx={{ ...styledCard("description"), color: "initial" }} tabIndex={-1}>
              {description}
            </Text>
          )}
        </Box>

        <Box sx={styledCard("arrowContainer")}>
          <MuiLink component="span" sx={styledCard("linkLabel")}>
            {linkLabel}
          </MuiLink>
          <Icon
            icon="long-arrow-right"
            iconDSFR="arrow-right-line"
            sx={{
              fontSize: 24,
              [theme.breakpoints.up("lg")]: {
                fontSize: 32,
              },
              ...styledCard("arrow"),
            }}
            title={title}
          />
        </Box>
      </Link>
    </Wrapper>
  );
};

Card.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  link: PropTypes.shape(),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  linkLabel: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default Card;
