const defaultHorizontalStyle = {
  root: {
    display: "flex",
    flex: "1 1 auto",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
  },
};

const defaultVerticalStyle = {
  root: {
    display: "flex",
    flex: "1 1 0px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    border: "1px solid",
    borderColor: "componentColors.30",
  },
  arrowContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

const getCamaieu = ({ camaieu, camaieuStart, index, defaultColor }) => {
  if (camaieuStart === undefined) {
    return defaultColor;
  }
  return camaieu[(index + camaieuStart) % camaieu.length];
};

const styles = {
  LIGHT_BIG: {
    key: "LIGHT_BIG",
    titleVariant: "fr-h3",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "white",
        "a&:hover": {
          bgcolor: "secondary.20",
        },
      },
      content: {
        px: 3,
        pt: 4,
        pb: 2,
      },
      icon: { display: "none" },
      title: {},
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        bgcolor: "secondary.main",
        color: "white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_LIGHT: {
    key: "LITTLE_LIGHT",
    titleVariant: "fr-h4",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "white",
      },
      content: {
        p: 2,
      },
      icon: { display: "none" },
      title: { mb: 0.5 },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 1,
        color: "secondary.main",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_COLOR: {
    key: "LITTLE_COLOR",
    titleVariant: "fr-h4",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "secondary.30",
        "a&:hover": {
          bgcolor: "secondary.30",
        },
      },
      content: {
        p: 2,
      },
      icon: { display: "none" },
      title: { mb: 0.5 },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 1,
        color: "componentColors.70",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_COLOR_PRIMARY: {
    key: "LITTLE_COLOR_PRIMARY",
    titleVariant: "fr-h4",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "primary.main",
        "a&:hover": {
          bgcolor: "primary.main",
        },
      },
      content: {
        p: 2,
      },
      icon: { display: "none" },
      title: {
        mb: 0.5,
        color: "common.white",
      },
      description: {
        color: "common.white",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 1,
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LITTLE_LIGHT_PRIMARY: {
    key: "LITTLE_LIGHT_PRIMARY",
    titleVariant: "fr-h4",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "common.white",
      },
      content: {
        p: 2,
      },
      icon: { display: "none" },
      title: {
        mb: 0.5,
      },
      description: {
        color: "componentColors.70",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 1,
        color: "common.white",
        bgcolor: "primary.main",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LIGHT_BIG_PRIMARY_IMAGE: {
    key: "LIGHT_BIG_PRIMARY_IMAGE",
    titleVariant: "fr-h2",
    imageRatio: "80",
    style: () => ({
      root: {
        flex: "1 1 0px",
        display: "flex",
        mt: 2,
      },
      image: {
        display: "block",
        flex: 2,
      },
      content: {
        p: 4,
        flex: 5,
        bgcolor: "primary.10",
      },
      icon: { display: "none" },
      title: {
        mb: 0.5,
      },
      description: {
        color: "componentColors.70",
      },
      arrowContainer: {
        display: "flex",
        alignItems: "flex-end",
        bgcolor: "primary.10",
      },
      arrow: {
        px: 2.25,
        py: 2,
        bgcolor: "primary.main",
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  LIGHT_PRIMARY_IMAGE: {
    key: "LIGHT_PRIMARY_IMAGE",
    titleVariant: "fr-h4",
    imageRatio: "100",
    style: () => ({
      root: {
        flex: "1 1 0px",
        display: "flex",
        mt: 2,
        bgcolor: "primary.10",
        "a&:hover": {
          bgcolor: "primary.10",
        },
      },
      image: {
        display: "flex",
        alignItems: "center",
        flex: 2,
      },
      content: {
        px: 2,
        py: 4,
        flex: 5,
      },
      icon: { display: "none" },
      title: {
        mb: 0.5,
      },
      description: {
        color: "componentColors.70",
        display: "none",
      },
      arrowContainer: {
        display: "flex",
        alignItems: "flex-end",
        mt: 2,
        bgcolor: "primary.10",
      },
      arrow: {
        p: 1,
        bgcolor: "primary.main",
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  BIG: {
    key: "BIG",
    titleVariant: "fr-h3",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "secondary.30",
        border: "none",
        "a&:hover": {
          bgcolor: "secondary.40",
        },
      },
      content: {
        px: 3,
        py: 2,
      },
      icon: { display: "none" },
      title: {},
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        bgcolor: "secondary.main",
        color: "white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  WITH_ICON_BIG: {
    key: "WITH_ICON_BIG",
    titleVariant: "fr-h5",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "white",
        borderTop: "8px solid",
        borderTopColor: "secondary.20",
        boxSizing: "border-box",
        "a&:hover": {
          bgcolor: "secondary.20",
          borderTopColor: "secondary.40",
        },
      },
      content: {
        px: 3,
        pt: 4,
        pb: 2,
      },
      icon: {
        color: "secondary.main",
        fontSize: "2rem",
      },
      title: {
        mt: 1,
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        bgcolor: "secondary.main",
        color: "componentColors.70",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  WITH_ICON_LITTLE: {
    key: "WITH_ICON_LITTLE",
    titleVariant: "fr-text--md",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "white",
        borderTop: "4px solid",
        borderTopColor: "secondary.40",
        "a&:hover": {
          bgcolor: "secondary.20",
        },
      },
      content: {
        pt: 4,
        pb: 2,
        pl: 2,
        pr: 0,
        width: "180px",
        height: { lg: "196px" },
        display: "flex",
        flexDirection: "column",
        boxSizing: "content-box",
        overflow: "auto",
      },
      icon: {
        color: "secondary.main",
        fontSize: "1.5rem",
      },
      title: {
        mt: 1,
        pr: 2,
        overflow: "auto",
        height: "100%",
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: 2.5,
        },
        "&::-webkit-scrollbar-thumb": {
          bgcolor: "componentColors.60",
          borderRadius: 2.5,
        },
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        pb: 2,
        pl: 2,
      },
      arrow: {
        display: "none",
      },
      linkLabel: {
        fontWeight: 600,
        textDecoration: "none",
      },
    }),
  },
  HORIZONTAL: {
    key: "HORIZONTAL",
    titleVariant: "fr-text--md",
    style: () => ({
      root: {
        ...defaultHorizontalStyle.root,
        bgcolor: "common.white",
        border: "1px solid",
        borderColor: "componentColors.30",
        "a&:hover": {
          borderColor: "secondary.main",
          bgcolor: "common.white",
        },
      },
      content: {
        py: 1,
        pl: 2,
        pr: 1,
      },
      icon: {
        display: "none",
      },
      title: {
        color: "componentColors.70",
        mb: 0,
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        display: "flex",
        alignItems: "center",
      },
      arrow: {
        p: 2,
        color: "secondary.main",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  HORIZONTAL_COLOR: {
    key: "HORIZONTAL_COLOR",
    titleVariant: "fr-text--md",
    style: () => ({
      root: {
        ...defaultHorizontalStyle.root,
        bgcolor: "secondary.20",
        border: "1px solid",
        borderColor: "secondary.main",
        boxSizing: "border-box",
        height: "64px",
        "a&:hover": {
          bgcolor: "secondary.20",
        },
      },
      content: {
        py: 1,
        pl: 2,
        pr: 1,
      },
      icon: { display: "none" },
      title: {
        color: "componentColors.70",
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultHorizontalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        color: "componentColors.70",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  HORIZONTAL_BIG: {
    key: "HORIZONTAL_BIG",
    titleVariant: "fr-text--md",
    style: (theme, props) => ({
      root: {
        ...defaultHorizontalStyle.root,
        backgroundColor: getCamaieu({
          camaieu: theme.palette.primary.tones,
          camaieuStart: props.camaieuStart,
          index: props.index,
          defaultColor: theme.palette.primary.main,
        }),
        minHeight: "120px",
        "a&:hover": {
          backgroundColor: getCamaieu({
            camaieu: theme.palette.primary.tones,
            camaieuStart: props.camaieuStart,
            index: props.index,
            defaultColor: theme.palette.primary.main,
          }),
        },
      },
      content: {
        py: 2,
        pl: 3,
        pr: 2,
        flex: "1 0",
      },
      icon: { display: "none" },
      title: {
        color: "common.white",
        fontWeight: 400,
        fontSize: "1rem",
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultHorizontalStyle.arrowContainer,
      },
      arrow: {
        p: 2.5,
        color: "common.white",
        "&:before": {
          "--icon-size": "2rem",
        },
      },
      linkLabel: {
        display: "none",
      },
    }),
  },

  VERTICAL_BIG_SEARCH_RESULT: {
    key: "VERTICAL_BIG_SEARCH_RESULT",
    titleVariant: "fr-h3",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        bgcolor: "secondary.10",
        border: "none",
        "a&:hover": {
          bgcolor: "secondary.20",
        },
      },
      content: {
        pt: 3,
        pb: 1,
        pl: 4,
        pr: 1,
      },
      icon: { display: "none" },
      title: {
        mb: 1,
      },
      subtitle: {
        mb: 2,
      },
      description: {},
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
        display: "flex",
        justifyContent: "space-between",
      },
      arrow: {
        p: 2,
        bgcolor: "secondary.main",
        color: "common.white",
      },
      linkLabel: {
        pl: 4,
        fontWeight: 600,
      },
    }),
  },
  VERTICAL_BIG_ONE_LINE: {
    key: "VERTICAL_BIG_ONE_LINE",
    titleVariant: "fr-h5",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        border: "none",
        bgcolor: "primary.main",
        "a&:hover": {
          bgcolor: "primary.main",
        },
      },
      content: {
        py: 2,
        pl: 3,
        pr: 2,
      },
      icon: { display: "none" },
      title: {
        color: "common.white",
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  VERTICAL_BIG: {
    key: "VERTICAL_BIG",
    titleVariant: "fr-h6",
    style: (theme, props) => ({
      root: {
        ...defaultVerticalStyle.root,
        border: "none",
        backgroundColor: getCamaieu({
          camaieu: theme.palette.primary.tones,
          camaieuStart: props.camaieuStart,
          index: props.index,
          defaultColor: theme.palette.primary.main,
        }),
        "a&:hover": {
          backgroundColor: getCamaieu({
            camaieu: theme.palette.primary.tones,
            camaieuStart: props.camaieuStart,
            index: props.index,
            defaultColor: theme.palette.primary.main,
          }),
        },
      },
      content: {
        py: 2,
        pl: 3,
        pr: 2,
      },
      icon: { display: "none" },
      title: {
        color: "common.white",
        mt: 1,
      },
      description: {
        color: "#d3daf8",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
  VERTICAL_BIG_PROCESS_SITES: {
    key: "VERTICAL_BIG_PROCESS_SITES",
    titleVariant: "fr-text--lg",
    style: () => ({
      root: {
        ...defaultVerticalStyle.root,
        border: "none",
        bgcolor: "primary.main",
        "a&:hover": {
          bgcolor: "primary.main",
        },
      },
      content: {
        py: 2,
        pl: 3,
        pr: 2,
      },
      icon: {
        fontSize: "2rem",
        color: "common.white",
      },
      title: {
        color: "common.white",
      },
      description: {
        display: "none",
      },
      arrowContainer: {
        ...defaultVerticalStyle.arrowContainer,
      },
      arrow: {
        p: 2,
        color: "common.white",
      },
      linkLabel: {
        display: "none",
      },
    }),
  },
};

export const styleKeys = Object.keys(styles).reduce((output, k) => ({ ...output, [k]: styles[k].key }), {});

export default styles;
