import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import React from "react";

const skipStyled = {
  left: "-999px",
  position: "absolute",
  top: "auto",
  overflow: "hidden",
  zIndex: "-999",
  "&:focus": {
    bgcolor: "primary.10",
    border: "1px solid",
    borderColor: "primary.light",
    color: "primary.main",
    left: 4,
    top: 4,
    overflow: "auto",
    fontWeight: 600,
    p: 3,
    textAlign: "center",
    zIndex: "9999",
  },
};

const SkipTo = (props) => {
  const { link, children = null } = props;

  return (
    <Link sx={skipStyled} href={link} tabIndex="1">
      {children}
    </Link>
  );
};

SkipTo.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default SkipTo;
