import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

export const TimelineStep = (props) => {
  const { id, title = null, content = null } = props;
  return (
    <Box
      sx={(theme) => ({
        ml: 1,
        position: "relative",
        pl: 3,
        pb: 4,
        borderLeft: `4px solid transparent`,
        "&:not(:last-child)": {
          borderLeftColor: "secondary.70",
        },
        ...theme.components?.Timeline?.step,
      })}
      key={id}
      role="listitem"
    >
      <Box
        component="span"
        sx={(theme) => ({
          position: "absolute",
          left: -12,
          top: -1,
          display: "block",
          height: "20px",
          width: "20px",
          bgcolor: "secondary.70",
          color: "white",
          borderRadius: "50%",
          ...theme.components?.Timeline?.bubble,
        })}
      />
      <div className="fr-text--bold">{title}</div>
      {content}
    </Box>
  );
};

TimelineStep.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
};

const Timeline = (props) => {
  const { steps = null, ...others } = props;

  return (
    <div {...others} role="list">
      {steps}
    </div>
  );
};

Timeline.propTypes = {
  steps: PropTypes.node,
};

export default Timeline;
