// A TESTER
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const sideStyled = { flex: 1 };
const wrapperStyled = {
  "@media screen ": {
    display: "flex",
  },
};

const Wrapper = (props) => {
  const {
    children = null,
    sx = {},
    wrapperProps = {},
    leftProps = {},
    rightProps = {},
    breakpoint = "lg",
    ...others
  } = props;
  const { sx: leftClass, ...left } = leftProps;
  const { sx: rightClass, ...right } = rightProps;
  const { sx: wrapperClass } = wrapperProps;

  return (
    <Box sx={{ ...wrapperStyled, ...wrapperClass }}>
      <Box sx={{ ...sideStyled, ...leftClass }} {...left} />
      <Box
        sx={(theme) => ({ maxWidth: `${theme.breakpoints.values[breakpoint]}px`, width: "100%", ...sx })}
        {...others}
      >
        {children}
      </Box>
      <Box sx={{ ...sideStyled, ...rightClass }} {...right} />
    </Box>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  leftProps: PropTypes.shape(),
  wrapperProps: PropTypes.shape(),
  rightProps: PropTypes.shape(),
  breakpoint: PropTypes.string,
};

export default Wrapper;
