import { Grid } from "@mui/material";
import MuiButton from "@mui/material/Button";
import LanguageContext from "components/LanguageContext";
import Icon from "components/templatesComponents/Icon";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import t from "utils/locales/translation.json";

export const componentClassName = "Da-Button";

const iconStyled = (seeAll) => ({
  pl: seeAll ? 3 : 1,
  display: "flex",
  alignItems: "center",
  fontSize: "0.875rem",
});

const Button = (props) => {
  // Voir pour un meilleur nom que seeAll
  const {
    outlined = false,
    external = false,
    fullWidth = false,
    primary = false,
    seeAll = false,
    icon: constIcon,
    iconPosition = "",
    size = "md",
    disabled = false,
    children = null,
    url = "",
    page = null,
    sx = {},
    ...others
  } = props;
  let { icon = null } = props;
  if (typeof icon === "string") {
    icon = { icon };
  }

  const { language } = useContext(LanguageContext);

  const displayFontIcon = !external && icon?.icon && !icon?.iconDSFR;

  const startIcon = displayFontIcon && iconPosition === "left" && (
    <Icon icon={icon?.icon} title={t[language].components.button_icon_title} sx={iconStyled(seeAll)} />
  );

  const endIcon = displayFontIcon && iconPosition === "right" && (
    <Icon icon={icon?.icon} title={t[language].components.button_icon_title} sx={iconStyled(seeAll)} />
  );

  const dynamicProps = {
    color: primary ? "primary" : "secondary",
    variant: outlined ? "outlined" : "contained",
    disabled,
    fullWidth,
    startIcon,
    endIcon,
    target: external ? "_blank" : "_self",
    rel: external ? "noopener noreferrer" : undefined,
  };

  const outlinedStyled = (theme) =>
    outlined
      ? {}
      : {
          bgcolor: primary ? "primary.main" : "secondary.main",
          color: primary
            ? theme.palette.getContrastText(theme.palette.primary.main)
            : theme.palette.getContrastText(theme.palette.secondary.main),
          "a&:hover, button&:hover": {
            bgcolor: primary ? "primary.dark" : "secondary.dark",
          },
        };

  return (
    <MuiButton
      className={`fr-btn fr-btn--${size}${outlined ? " fr-btn--secondary" : ""}${
        iconPosition ? ` fr-btn--icon-${iconPosition}` : ""
      }${!external && icon?.iconDSFR ? ` fr-icon-${icon?.iconDSFR}` : ""} ${componentClassName}`}
      sx={(theme) => ({ ...outlinedStyled(theme), ...sx, ...(fullWidth ? { width: "100%" } : {}) })}
      url={url}
      page={page}
      aria-hidden={disabled}
      aria-label={
        typeof children === "string" && children !== ""
          ? children
          : `${t[language].components.link_to} ${url || page?.title}`
      }
      {...dynamicProps}
      {...others}
    >
      {children && (
        <Grid
          container
          component="span"
          justifyContent={seeAll ? "space-between" : "center"}
          alignItems="center"
          wrap="nowrap"
          sx={{
            flex: "1 1 auto",
            textAlign: "center",
            py: seeAll ? 2 : undefined,
            px: seeAll ? 0 : undefined,
            // Même transition que le bouton de base MUI sur cet élément ça va p-e changer si on enlève les transitions au hover
            transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {children}
          {seeAll && (
            <Icon
              icon="long-arrow-right"
              iconDSFR="arrow-right-line"
              title={t[language].components.button_icon_title}
              sx={iconStyled(seeAll)}
            />
          )}
        </Grid>
      )}
    </MuiButton>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  external: PropTypes.bool,
  outlined: PropTypes.bool,
  fullWidth: PropTypes.bool,
  primary: PropTypes.bool,
  seeAll: PropTypes.bool,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  url: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.shape(), PropTypes.node]),
  sx: PropTypes.shape(),
};

export default Button;
