import Grid from "@mui/material/Grid";
import DSFRListContext from "components/DSFRListContext";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const DSFRSpecificList = (props) => {
  let { cards = [] } = props;

  if (cards && !Array.isArray(cards)) {
    cards = [cards];
  }

  let lg = 3;
  const size = React.Children.count(cards);
  if (size <= 5) {
    lg = true;
  } else if (size === 6) {
    lg = 4;
  }

  const DSFRListContextValue = useMemo(() => ({ xs: 12, sm: 6, lg }), [lg]);

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <DSFRListContext.Provider value={DSFRListContextValue}>
      <Grid container spacing={{ xs: 3, md: 1 }} sx={{ "&&": { mt: 0 } }}>
        {cards}
      </Grid>
    </DSFRListContext.Provider>
  );
};

DSFRSpecificList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape()),
};

export default DSFRSpecificList;
