import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import HelpForm from "components/HelpForm";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import CardContext from "components/templatesComponents/CardContext";
import {
  cardImmatContextValue,
  cardPasseportContextValue,
  cardPermisContextValue,
} from "components/templatesComponents/CardContextValue";
import { ImageContainer } from "components/templatesComponents/Image";
import PropTypes from "prop-types";
import React from "react";

const displayDesktopStyled = { display: { xs: "none", lg: "block" }, width: { lg: "100%" } };

const siteLinksContainerStyled = {
  flexDirection: { xs: "column", lg: "row" },
  width: { xs: "100%", lg: "calc(100% + 16px)" },
  m: { xs: 0, lg: -1 },
  mb: { xs: 3, lg: 10 },
  "& > div": {
    px: { lg: 1 },
    py: { xs: 0.5, lg: 1 },
  },
};

const Contact = (props) => {
  const { page = {} } = props;

  const { image, contents } = page;

  const { contentTitle, subtitle, cardImmat, cardPermis, cardPasseport } = contents;

  const theme = useTheme();

  return (
    <>
      <Wrapper>
        <Grid container justifyContent="space-between" sx={{ flexDirection: { xs: "column", lg: "row-reverse" } }}>
          {image && (
            <Grid
              item
              lg={5}
              container
              alignItems="center"
              justifyContent="center"
              sx={{ position: "relative", textAlign: { xs: "center", lg: "start" } }}
            >
              <Grid item sx={{ width: "100%", display: { lg: "none" } }}>
                <ImageContainer ratio={46}>{image}</ImageContainer>
              </Grid>

              <Grid item sx={displayDesktopStyled}>
                <ImageContainer ratio={89}>{image}</ImageContainer>
              </Grid>

              <Grid item xs={10} sx={{ position: "absolute", display: { lg: "none" } }}>
                <Box component="h1" sx={{ color: "white", my: 3 }}>
                  {contentTitle}
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            lg={image ? 6 : 12}
            container
            sx={{ flexDirection: { lg: "column" }, justifyContent: { lg: "center" } }}
          >
            <Grid item sx={displayDesktopStyled}>
              <Block>
                <Box component="h1" sx={{ mb: 3 }}>
                  {contentTitle}
                </Box>
              </Block>
            </Grid>
            <Grid item sx={{ mx: 2, mt: 5 }}>
              <h2>{subtitle}</h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={siteLinksContainerStyled}>
          <Grid item md>
            <CardContext.Provider value={cardImmatContextValue(theme)}>{cardImmat}</CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider value={cardPermisContextValue(theme)}>{cardPermis}</CardContext.Provider>
          </Grid>
          <Grid item md>
            <CardContext.Provider value={cardPasseportContextValue(theme)}>{cardPasseport}</CardContext.Provider>
          </Grid>
        </Grid>
      </Wrapper>
      <HelpForm page={page} />
    </>
  );
};

Contact.propTypes = {
  page: PropTypes.shape(),
};

export default Contact;
