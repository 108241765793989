import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import LanguageContext from "components/LanguageContext";
import MessageContext from "components/MessageContext";
import Autosuggest from "components/templatesComponents/Autosuggest";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PageService from "services/PageService";
import { eventKey } from "utils/commonUtils";
import t from "utils/locales/translation.json";
import { createQueryParams } from "utils/urlUtils";

const autoSuggestClasses = (theme, isMobile = true) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
    height: 40,
  },
  suggestionsContainer: {
    position: "absolute",
    left: isMobile ? 12 : "auto",
    right: isMobile ? 16 : 0,
    top: 40,
    width: isMobile ? "auto" : "22.25rem",
    textAlign: "initial",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.componentColors[30]}`,
    borderBottom: "none",
    borderTop: "none",
    zIndex: 100,
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: "0 !important",
    margin: 0,
    borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
  },
});

const SearchBar = (props) => {
  const { onSearch = () => null } = props;

  const { pathname, search } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const { displayError } = useContext(MessageContext);
  const { language } = useContext(LanguageContext);

  const goToSearchPage = () => {
    if (value) {
      onSearch();
      history.push({
        pathname: `/rechercher`,
        search: `?${createQueryParams({ q: value })}`,
        state: { prevPath: pathname + search },
      });
    }
  };

  const handleIconClick = () => {
    goToSearchPage();
  };

  const handleKeyDown = (e) => {
    if (e.key === eventKey) {
      goToSearchPage();
    }
  };

  const getSuggestions = async (newValue) => {
    try {
      const sugg = await PageService.search({ query: newValue, lang: language, from: 0, size: 5 });
      const { hits } = sugg || {};
      const { hits: results } = hits || {};
      return (results && Array.isArray(results) && results.map((res) => res._source)) || [];
    } catch (e) {
      displayError(
        "Le service de recherche est actuellement indisponible. Veuillez nous excuser pour la gêne occasionnée."
      );
      return [];
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => {
    let breadcrumb = suggestion.breadcrumb.map((b) => b.title);
    if (breadcrumb && breadcrumb.length > 3) {
      breadcrumb = [breadcrumb[0], breadcrumb[1], "...", breadcrumb[breadcrumb.length - 1]];
    }
    const subtitle = breadcrumb.join(` / `);
    return (
      <Link page={suggestion} sx={{ color: "rgba(0, 0, 0, 0.87)", "&:hover": { color: "primary.main" } }}>
        <Box sx={{ px: 2, py: 1.5 }}>
          <b>{suggestion.title}</b>
          <p className="fr-text--xs">{subtitle}</p>
        </Box>
      </Link>
    );
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = () => {
    onSearch();
    setValue("");
  };

  const renderInputComponent = (inputProps) => (
    <Box
      aria-expanded="false"
      className="fr-container fr-container-lg--fluid"
      sx={{
        display: { xs: "block", lg: "flex" },
        justifyContent: "flex-end",
        maxWidth: { lg: "22rem" },
        mr: { lg: 0 },
        "& div": {
          width: { lg: "22.25rem" },
        },
      }}
    >
      <Box
        className="fr-search-bar"
        id="search"
        role="search"
        sx={{ position: "absolute", left: { xs: "16px", lg: "auto" }, right: { xs: "16px", lg: "auto" } }}
      >
        <label className="fr-label" htmlFor="search-input">
          {t[language].common.search.label}
        </label>
        <input
          className="fr-input"
          type="search"
          id="search-input"
          name="search-input"
          title={t[language].common.search.input_title}
          placeholder={t[language].common.search.placeholder}
          {...inputProps}
        />
        <button
          type="button"
          className="fr-btn"
          title={t[language].common.search.button_title}
          onClick={handleIconClick}
        >
          {t[language].common.search.placeholder}
        </button>
      </Box>
    </Box>
  );

  const inputProps = {
    value,
    onChange,
    onKeyDown: handleKeyDown,
  };

  // Accessibilité: remove un role="listbox" non pertinent
  if (typeof document !== "undefined") {
    const suggestContainer = document.getElementById("react-autowhatever-1");
    if (suggestContainer) {
      suggestContainer.removeAttribute("role");
    }
  }

  return (
    <Autosuggest
      getSuggestions={getSuggestions}
      debounce={300}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      classes={autoSuggestClasses(theme, isMobile)}
      noResult={
        <div>
          <b>
            {t[language].common.search.no_result_message} : &laquo; {value} &raquo;
          </b>
        </div>
      }
    />
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchBar;
