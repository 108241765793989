import HelpDetail from "components/templates/helpDetail/HelpDetail";
import elementsTypes from "utils/elementsTypes";

export default {
  key: "HELP_DETAIL",
  label: "Détail aide",
  Component: HelpDetail,
  initialContents: [
    {
      key: "questions",
      type: elementsTypes.ACCORDION,
      value: "",
      children: [],
    },
    {
      key: "cta",
      value: "",
      children: [
        {
          key: "title",
          type: elementsTypes.EDITABLE_INPUT,
          value: "Pour immatriculer mon véhicule",
        },
        {
          key: "button",
          type: elementsTypes.BUTTON,
          value: "",
          children: [
            {
              key: "text",
              value: "",
            },
            {
              key: "link",
              type: elementsTypes.LINK,
              value: "",
            },
            {
              type: elementsTypes.CHECKBOX,
              key: "external",
              value: "true",
            },
          ],
        },
      ],
    },
    {
      key: "feedbackTitle",
      type: elementsTypes.EDITABLE_INPUT,
      value: "Avez-vous trouver votre réponse ?",
    },
  ],
};
