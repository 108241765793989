import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import PropTypes from "prop-types";
import React from "react";

const HelpDetails = (props) => {
  const { page = {} } = props;
  const { title, contents } = page;
  const { questions, cta } = contents;

  return (
    <Wrapper>
      <Block>
        <PageTitle>{title}</PageTitle>
      </Block>

      {/* questions / answers */}
      <Block>{questions}</Block>

      {/* button cta */}
      <Block sx={{ textAlign: "center" }}>
        <p>{cta.title}</p>
        {cta.button}
      </Block>
    </Wrapper>
  );
};

HelpDetails.propTypes = {
  page: PropTypes.shape(),
};

export default HelpDetails;
