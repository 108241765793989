import { createTheme } from "@mui/material/styles";
import { light } from "@mui/styles/";
import merge from "lodash.merge";
import defaultTheme from "./defaultTheme";

const defaultMuiTheme = createTheme(defaultTheme);

const colors = {
  10: "#EFF4FD",
  20: "var(--background-alt-blue-france)",
  30: "#000091",
  40: "#1212ff",
  50: "#1B3194",
  60: "#0A34ED",
  70: "#0F1B51",
};

const componentColors = {
  10: "#F1F1F1",
  20: "#F5F5F5",
  30: "#E2E2E2",
  40: "#E8E7E7",
  50: "#EFEFEF",
  60: "#8B8A8B",
  70: "#353535",
};

const getContrastText = (background) => {
  const MuiGetContrastText = defaultMuiTheme.palette.getContrastText;
  const contrast = MuiGetContrastText(background);
  if (contrast === light?.text.primary) {
    return componentColors[70];
  }
  return contrast;
};

const palette = {
  primary: {
    light: colors[20],
    main: colors[30],
    dark: colors[40],
    hover: colors[60],
    tones: [colors[30], colors[40], colors[50]],
    ...colors,
  },
  secondary: {
    light: colors[20],
    main: colors[30],
    dark: colors[40],
    hover: colors[60],
    ...colors,
  },
  componentColors: {
    ...componentColors,
  },
  getContrastText,
};

export const theme = merge({}, defaultTheme, {
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  text: {
    primary: colors[60],
    secondary: palette.componentColors[70],
  },
  header: {
    background: {
      color: colors[50],
    },
    current: {
      background: {
        color: colors[30],
      },
    },
    border: {
      color: "none",
    },
  },
  navigation: {
    background: {
      color: "#fff",
    },
    border: {
      color: componentColors[30],
    },
    current: {
      color: palette.primary.main,
      hover: palette.primary[20],
    },
    iconMenuMobile: {
      color: "#fff",
    },
    topNav: {
      background: palette.primary[50],
      backgroundLogo: "#fff",
    },
    titleMobile: {
      fontSize: "1.1875rem",
    },
  },
  logoContainer: {
    margin: {
      marginLeft: defaultMuiTheme.spacing(-2.5),
    },
  },

  footer: {
    background: {
      backgroundColor: colors[40],
      backgroundColorTitleSocialMobile: colors[50],
    },
  },
  components: {
    searchWrapper: {
      background: componentColors[20],
    },
    Timeline: {
      bubble: {
        backgroundColor: colors[70],
      },
    },
    Button: {
      login: {
        backgroundColor: colors[20],
        hover: colors[20],
      },
    },
    ExpansionPanel: {
      ExpandIcon: {
        backgroundColor: colors[20],
        expanded: "#01B48C",
      },
    },
  },
  templates: {
    Article: {
      icon: {
        color: "#f582c1",
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        fontSize: "0.79rem",
        margin: defaultMuiTheme.spacing(2, 1),
        [defaultMuiTheme.breakpoints.up("lg")]: {
          margin: defaultMuiTheme.spacing(2.5, 0, 3),
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      containedPrimary: {
        "&:hover": {
          backgroundColor: palette.primary.hover,
        },
      },
    },
  },
  MuiExpansionPanelSummary: {
    styleOverrides: {
      root: {
        backgroundColor: palette.primary.main,
        color: "white",
        "&$focused": {
          backgroundColor: palette.primary.main,
        },
        "&$expanded": {
          backgroundColor: palette.primary.dark,
        },
      },
      content: {
        marginLeft: defaultMuiTheme.spacing(2),
        margin: defaultMuiTheme.spacing(1.5, 2),
        "&$expanded": {
          margin: defaultMuiTheme.spacing(1.5, 2),
          marginLeft: defaultMuiTheme.spacing(2),
        },
        [defaultMuiTheme.breakpoints.up("lg")]: {
          margin: defaultMuiTheme.spacing(1.5, 4),
          "&$expanded": {
            margin: defaultMuiTheme.spacing(1.5, 4),
          },
        },
      },
      expandIcon: {
        fontSize: "1.06rem",
        padding: defaultMuiTheme.spacing(3, 2),
        color: "white",
        [defaultMuiTheme.breakpoints.up("lg")]: {
          fontSize: "1.5rem",
          padding: defaultMuiTheme.spacing(3),
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: "pointer",
        fontSize: "0.875rem",
        color: palette.primary.main,
      },
    },
  },
});

export default createTheme(theme);
