import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Block from "components/templatesComponents/Block";
import Link from "components/templatesComponents/Link";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import wrappable from "utils/wrappable";

const GlossaryItem = wrappable((props) => {
  const { word, description } = props;

  return (
    <Block>
      <Box component="h3" m={0}>
        {word}
      </Box>
      <Box mt={0.5}>{description}</Box>
    </Block>
  );
});

const Glossary = (props) => {
  const { location } = props;
  let { items = [] } = props;
  const [lettersTop, setLettersTop] = useState(null);
  const { hash } = location;

  if (items && !Array.isArray(items)) {
    items = [items];
  }

  const filteredItems = items.filter((item) => item && item.word);

  filteredItems.sort((a, b) => a.word.localeCompare(b.word));

  const groupedItems = useMemo(() => {
    const initialGroupedItems = {};
    filteredItems.forEach((item) => {
      const { word } = item;
      const firstLetter = word.toUpperCase().split("")[0];
      initialGroupedItems[firstLetter] = initialGroupedItems[firstLetter] || [];
      initialGroupedItems[firstLetter].push(item);
    });
    return initialGroupedItems;
  }, [filteredItems]);

  const lettersRef = createRef();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isMobile) {
      setLettersTop((window.innerHeight - lettersRef.current.getBoundingClientRect().height) / 2);
    }
  }, [lettersRef, isMobile]);

  const letters = useMemo(() => {
    // Si trop de lettres, alors on remplace l'excedent par "..." au milieu de la liste
    // qui pointera vers la première lettre retirée
    const allLetters = Object.keys(groupedItems).map((item) => ({ letter: item, hash: item }));
    if (isMobile) {
      const maxLetters = 15;
      const nbLetters = Object.keys(groupedItems).length;
      if (nbLetters > maxLetters) {
        const nbSubstract = nbLetters - maxLetters;
        const indexStartSubstract = Math.round(maxLetters / 2);
        allLetters.splice(indexStartSubstract, nbSubstract, {
          letter: "...",
          hash: allLetters[indexStartSubstract].hash,
        });
      }
    }
    return allLetters;
  }, [groupedItems, isMobile]);

  return (
    <>
      <Box
        ref={lettersRef}
        sx={{
          display: { xs: lettersTop ? "flex" : "none", lg: "flex" },
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "center", lg: "flex-start" },
          fontSize: { xs: "1.05rem", lg: "1.25rem" },
          fontWeight: { xs: 400, lg: 600 },
          position: { xs: "fixed", lg: "-webkit-sticky" },
          bgcolor: { xs: "primary.50", lg: "white" },
          top: { xs: lettersTop || "initial", lg: 0 },
          mb: { xs: 0, lg: 1 },
          py: { xs: 1, lg: 2 },
          right: { xs: 0, lg: "unset" },
        }}
      >
        {letters.map((item) => (
          <Link
            key={item.letter}
            smooth
            url={`${location.pathname}#${item.hash}`}
            sx={{
              textTransform: "uppercase",
              flex: 1,
              maxWidth: "50px",
              cursor: "pointer",
              color: { xs: "common.white", lg: hash === `#${item.hash}` ? "secondary.main" : "inherit" },
              lineHeight: { lg: "1.75rem" },
              fontSize: { lg: hash === `#${item.hash}` ? "1.75rem" : "inherit" },
              px: { xs: 0.5, lg: 0 },
              py: { xs: 0.25, lg: 0 },
              "&:hover": {
                color: "secondary.60",
              },
            }}
          >
            {item.letter}
          </Link>
        ))}
      </Box>
      <Box pt={{ xs: 1, lg: 0 }}>
        {Object.keys(groupedItems).map((letter) => (
          <Block sx={{ pt: { xs: 1, lg: 0 } }} id={letter} key={letter}>
            <Box
              sx={{
                color: "primary[70]",
                pb: 2,
                borderBottom: "1px solid",
                borderColor: { xs: "componentColors.40", lg: "secondary.30" },
                "& h2": {
                  m: 0,
                  px: { xs: 2, lg: 0 },
                },
              }}
            >
              <h2>{letter}</h2>
            </Box>
            {groupedItems[letter].map((item) => (
              <GlossaryItem key={item.id} {...item} />
            ))}
          </Block>
        ))}
      </Box>
    </>
  );
};

Glossary.propTypes = {
  location: PropTypes.shape().isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
};

export default withRouter(Glossary);
