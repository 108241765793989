import Box from "@mui/material/Box";
import React from "react";

export default (Component) => {
  const SVGIconComponent = (props) => {
    return (
      <Box
        sx={{
          display: "inline-block",
          textAlign: "center",
          height: "1em",
          width: "1em",
          "&> svg": {
            verticalAlign: "middle",
          },
        }}
      >
        <Component {...props} />
      </Box>
    );
  };

  return SVGIconComponent;
};
