import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CardDsfrContext from "components/CardDsfrContext";
import DSFRListContext from "components/DSFRListContext";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import useAxiosCache from "hooks/axiosCache";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import SiteService from "services/SiteService";

const processesBackgroundStyled = {
  pt: { xs: 2, lg: 6 },
  pb: 4,
  mb: 2,
  minHeight: { xs: "268px", md: "300px" },
  width: "100%",
  bgcolor: "secondary.light",
};

const ProcessesDsfr = (props) => {
  const { page: pageVersion = {} } = props;

  const { contents, page } = pageVersion;

  const { dynamicList, contentTitle, subTitle, dsfrCards, showLogo } = contents;
  const { siteId } = page;

  const [{ data: allSites = [] }] = useAxiosCache(SiteService.getConfig("getAllSites"));

  const findSiteById = useMemo(() => allSites?.find((site) => site.id === siteId), [allSites, siteId]);

  const { name: siteNameById } = findSiteById || {};

  const cardDsfrContextValue = useMemo(() => ({ hasGreyBackground: true }), []);
  const dsfrListContextValue = useMemo(() => ({ xs: 12, sm: 6, lg: 4 }), []);

  const bo = useMemo(() => typeof showLogo === "object", [showLogo]);

  return (
    <>
      <Wrapper wrapperProps={{ sx: processesBackgroundStyled }}>
        <Stack sx={{ "& *": { textAlign: "left" } }}>
          <Block>
            <Stack direction={{ lg: "row" }} alignItems="flex-start" gap={2}>
              {bo || showLogo ? (
                <Box
                  component="img"
                  src={`/logo-${siteNameById}.svg`}
                  alt=""
                  sx={{
                    ...(bo ? { opacity: showLogo.props?.content?.value === "true" ? 1 : 0.3 } : {}),
                    width: "95px",
                  }}
                />
              ) : null}
              <Stack flexGrow={1}>
                <h1>{contentTitle}</h1>
                <p className="fr-text--xl">{subTitle}</p>
                <Stack direction={{ lg: "row" }} gap={2} sx={{ "& a": { width: { xs: "100%", lg: "auto" } } }}>
                  {dynamicList}
                </Stack>
              </Stack>
            </Stack>
          </Block>
          {bo ? <Box>{showLogo}</Box> : null}
        </Stack>
      </Wrapper>
      <DSFRListContext.Provider value={dsfrListContextValue}>
        <CardDsfrContext.Provider value={cardDsfrContextValue}>
          <Wrapper sx={bo ? {} : { "& p": { mt: { xs: 3.5, lg: 4.75 }, mb: { xs: -5, lg: -3 } } }}>{dsfrCards}</Wrapper>
        </CardDsfrContext.Provider>
      </DSFRListContext.Provider>
    </>
  );
};

ProcessesDsfr.propTypes = {
  page: PropTypes.shape(),
};

export default ProcessesDsfr;
