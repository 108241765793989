import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

const HoneyPot = (props) => {
  const { name = null } = props;

  if (name === null) return null;

  return (
    <Box sx={{ display: "none" }}>
      <input type="text" name={name} id={name} value="" />
    </Box>
  );
};

HoneyPot.propTypes = {
  name: PropTypes.string,
};

export default HoneyPot;
